import userTypes from "./user-types";


const INTIAL_STATE = {
    
};

const userReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case userTypes.SET_USER:
            return{
                ...action.payload
            };
        case userTypes.CHARGE_USER:
            return{
                ...state,
                ...action.payload
            };
        case userTypes.CLEAR_USER:
            return{
            };
        default:
            return state;
    }
};

export default userReducer;