import * as React from 'react';
import {Grid, Typography, IconButton, Box,TextField, Button, Select, MenuItem, Alert, Checkbox, FormControlLabel, List, ListItemText, FormControl, RadioGroup, Radio} from '@mui/material';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {setStudy} from '../redux/study/study-action';
import Modal from '@mui/material/Modal';
import { ArrowBack, OpenWith} from '@mui/icons-material/';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaPlusSquare } from "react-icons/fa";
import { HexColorPicker } from "react-colorful";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 4,
    p: 4,
    paddingBottom:"5px"
  };


function UpdateStudy({hideLoader, user, study, setStudy}) {

  const [color, setColor] = React.useState("#aabbcc");
  const [openEdit, setOpenEdit] = React.useState(false);
  const index = React.useRef(0)
  const [defTargetSelected, setDefTargetSelected] = React.useState("")
  const selectedstudy = study? study : {};
  const selectedCilbles = [];
  const [newStudy, setnewStudy] = React.useState({...selectedstudy, experts: []});
  const [experts, setExperts] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [patientList, setpatientList] = React.useState([]);
  const [targets, setTargets] = React.useState([]);
  const [target, setTarget] = React.useState([]);
  const [expertName, setExpertName] = React.useState( []);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [finalExperts, setFinalExperts] =  React.useState( []);
  const [finalPatients, setFinalPatients] =  React.useState( []);
  let [DBtargets, setDBtargets] =  React.useState( []);
  const [type, setType] =  React.useState(selectedstudy._type);
  const [notes, setNotes] = React.useState([]);
  const [meshes, setMeshes] = React.useState([]);
  const [note, setNote] = React.useState([]);
  const [openNotesModalEdit, setOpenNotesModalEdit] = React.useState(false);
  const [openMeshesModal, setOpenMeshesModal] = React.useState(false);
  const [openMeshesModalEdit, setOpenMeshesModalEdit] = React.useState(false);
  const [mesh, setMesh] = React.useState([]);
  const [listSeq, setListSeq] = React.useState(false)
  const [openNotesModal, setOpenNotesModal] = React.useState(false);
  const [treeView, setTreeView] = React.useState(false);
  const [allExperts, setAllExperts] = React.useState([]);
  const [dbNotes, setDbNotes] = React.useState([]);
  const [dbMeshes, setDbMeshes] = React.useState([]);
  const [deletedMeshes, setDeletedMeshes] = React.useState([])
  const [deletedTargets, setDeletedTargets] = React.useState([])
  const [deletedNotes, setDeletedNotes] = React.useState([])
  const [loading, setLoading]= React.useState(false)

  
  
  
  const [open, setOpen] = React.useState(false);
  const handleClose = () =>{ 
    
    setOpen(false); 
    setOpenEdit(false);
    setOpenNotesModal(false);
    setOpenNotesModalEdit(false);
    setOpenMeshesModal(false);
    setOpenMeshesModalEdit(false);
    setTreeView(false)}

  

  const validation = () => {
    if (newStudy.name && targets.length>0 ) {
      return true;
    }
    else {
      setErrorMessage('Veuillez renseigner toutes les informations nécessaires!')
      return false;
    }
  }

  const handleChangeMultiple = (event, select) => {


    var cibleObjects = []
    const { options } = event.target;
    const experts_ = [];
    const patients_ = [];
    var patients__ = [];
    
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        if (select === "expert") {
        
          patients__ = patients.map(p=>
           
            {
              if(patientList.includes(p.identifiant)){
              const ex =  experts.find(expert=>
                expert.id.toString() === options[i].value.toString());
              
              if(ex){
                const patient_ = ex.patients.find(pat =>
                  pat.identifiant === p.identifiant)?ex.patients.find(pat =>
                    pat.identifiant === p.identifiant):{...p, patientId:p, viewMode:"Plans",suppMesh: meshes.map(mesh_=>({meshSupp:{...mesh_}, showMesh:mesh_.showMesh? mesh_.showMesh:false}))};
                if(patientList.includes(patient_.identifiant)){
                  return patient_;
                }
              }
              else {
                const patient_ = {...p, patientId:p,viewMode:"Plans", suppMesh: meshes.map(mesh_=>({meshSupp:{...mesh_}, showMesh:mesh_.showMesh?mesh_.showMesh:false,}))};
                if(patientList.includes(patient_.identifiant)){
                  return patient_;
                }
                 
              }
            }

          }
          
          );

          experts_.push({id:options[i].value, patients:patients__.filter(p=>p)});
        }
        if (select === "patient") {
          patients_.push( options[i].value);
        }
        cibleObjects.push(targets[options[i].value]);
      }
    }
    if (select === "expert") {
      setExpertName(experts_.map(exp=>exp.id));
      setnewStudy({ ...newStudy, experts: experts_ });
    }
    if (select === "patient") {
      setpatientList(patients_);
      var expertsClone = [...newStudy.experts]; //patients_.includes(patient.identifiant)
      const getExpPatients = (exp) =>{
        
          const ex = newStudy.experts.find((expert)=> expert.id.toString() === exp.id.toString());
          var meshCopy = null;
          return patients_.map((patient,i) => 
            {
              const p_= ex.patients.find(p=> p.identifiant === patient)
              if(p_){
                !meshCopy? meshCopy = p_.suppMesh : void(0);
                return p_;
              }
              else{
                const patient_ = patients.find(p=>p.identifiant === patient);
                return { patientId: patient_, ...patient_, index: i,viewMode:"Plans", suppMesh: meshes.map(mesh_=>({meshSupp:{...mesh_}, showMesh:mesh_.showMesh?mesh_.showMesh:false,} ))}
              }

            }
           )
       .sort((a,b) => a.index - b.index)
        
      }
      var expertsFinal =  expertsClone.map((exp)=>
        ({...exp, patients:getExpPatients(exp)
          })
      );
      setnewStudy({ ...newStudy, patients: patients_ , experts:expertsFinal});
    }


  };

  const handleViewModeChange = (event, exp, pat) => {
    const value = event.target.value;
    var studyClone = {...newStudy};
    var expertClone = {...exp};
    var patient = expertClone.patients.find(pat_=>pat_.identifiant === pat.identifiant);
    patient.viewMode = value;
    studyClone.experts.find(e=>e.id === expertClone.id).viewMode = value;
    setnewStudy(studyClone)
  }


    React.useEffect( () => {
      const {access, is_staff} = user || {};
      !access&&!is_staff?navigate("/admin/login"):void(0);

      var all_experts;
      setNotes(newStudy.notesChoices);
      setDbNotes([...newStudy.notesChoices])
      setMeshes(newStudy.meshes_supp);
      setListSeq(newStudy.listSeq);
      const cibles_selected = newStudy.targets.map((target) => target.name+"");
      setDBtargets([...newStudy.targets]);
      setTargets(newStudy.targets.sort((a,b)=> a.index - b.index));

      setDbMeshes([...newStudy.meshes_supp])

        $('.scroll').mCustomScrollbar({
            scrollButtons:{enable:true},
            keyboard:{scrollType:"stepless"},
            mouseWheel:{scrollAmount:150},
            theme:"rounded-dark",
            autoExpandScrollbar:true,
          });
          async function fetchData() {
            setLoading(true)
            await axios.get(process.env.REACT_APP_API +"/api/experts/", 
            {
              headers: {
                Authorization: `Bearer ${access}`
              }
            },).then(
            (response) => {
              all_experts = response.data;
              setAllExperts(all_experts.map(exp=>({...exp, ...exp.user})))
                
            }
        ).catch((error)=>{console.log(error);})
        //var experts__;

        await axios.post(process.env.REACT_APP_API +"/api/experts-study/",{study: study.uid},
        {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        }, ).then(
            async (response) => {
             const experts_selected = response.data.map(exp => exp.user.id);
             setExpertName(experts_selected);
             setFinalExperts(experts_selected);

             const updatedExperts = await Promise.all(experts_selected.map(async (exp)=>{
              const exp_  = all_experts.find(expert => expert.user.id.toString() === exp.toString());
              const username = exp_?exp_.user.username:undefined;
              const response = await axios.post(process.env.REACT_APP_API +"/api/patients-study-expert/",{study: study.uid, username:username},
              {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie( 'csrftoken')
        }
      }, );

              const patients = response.data;
              return {...exp_,...exp_.user, patients: patients.map((p)=>({...p.patientId, ...p})).sort((a,b)=>a.index-b.index)};
             }));
             setExperts(updatedExperts);
             var studyClone = {...newStudy};
             studyClone.experts = updatedExperts;
             setnewStudy(studyClone)

            }
        ).catch((error)=>{console.log(error);});
        
        await axios.get(process.env.REACT_APP_API +"/api/patients/",       {
          headers: {
            Authorization: `Bearer ${access}`
          }
        },).then(
            (response) => {
                setPatients(response.data.sort((a,b)=> a.index - b.index))
            }
        ).catch((error)=>{console.log(error);})

        await axios.post(process.env.REACT_APP_API +"/api/study-patients/", {study: study.uid},      {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        },).then(
            (response) => {
              const patients_selected = response.data.sort((a,b)=> a.index - b.index).map((patient) => patient.identifiant);
              setpatientList(patients_selected);
              setFinalPatients(patients_selected);
            }
        ).catch((error)=>{console.log(error);})

    
          setLoading(false)

          //setFinalCibles(cibles_selected);
        
          }
          fetchData();

      hideLoader();
      }, []);

      const handleOnDragEnd = (result) => {
        if(result.destination){

        
        const expertId = result.source.droppableId;
        var studyClone = {...newStudy};
        var expertIndex = studyClone.experts.findIndex((exp)=> exp.id.toString() === expertId.toString());
        
        const items = Array.from(studyClone.experts[expertIndex].patients);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        studyClone.experts[expertIndex] = {...studyClone.experts[expertIndex], patients: items.map((item,i)=>({...item, index:i}))}
        setnewStudy(studyClone);
    
      }
    }
      const delay = ms => new Promise(res => setTimeout(res, ms));

    const submitHandle = async() =>{
      if(validation())
        {
        const {access, is_staff} = user || {};

        var addedPatients = [];
        var removedPatients = [];
        var removedExperts = [];
        var addedExperts = [];
        var addedTargets = [];
        var addedNotes = [];

       

        

        finalPatients.forEach(newObj => {
          const found = patientList.find(prevObj => prevObj === newObj);
          if(!found) {
              removedPatients.push(newObj)
              console.log(`removed`, newObj);
          }
        });

        patientList.forEach(newObj => {
          const found = finalPatients.find(prevObj => prevObj.toString() === newObj.toString());
          if(!found) {
              addedPatients.push(newObj)
              console.log(`added`, newObj);
          }
        });
       setFinalPatients(patientList)
        finalExperts.forEach(newObj => {
          const found = expertName.find(prevObj => prevObj.toString() === newObj.toString());
          if(!found) {
              removedExperts.push(newObj)
              console.log(`removed`, newObj);
          }
        });

        expertName.forEach(newObj => {
          const found = finalExperts.find(prevObj => prevObj.toString() === newObj.toString());
          if(!found) {
              addedExperts.push(newObj)
              console.log(`added`, newObj);
          }
        });

        setFinalExperts(expertName)

        targets.forEach(preObj => {
          const found = DBtargets.find(newObj => newObj.name === preObj.name);
          if(!found) {
            addedTargets.push(preObj)
              console.log(`added`, preObj);
          }
      });
      
      setDBtargets(targets)

      notes.forEach(preObj => {
        const found = dbNotes.find(newObj => newObj.label === preObj.label);
        if(!found) {
          addedNotes.push(preObj)
            console.log(`added`, preObj);
        }
    });
    
    setDbNotes(notes);


    

      const studyDB = {
        ...newStudy,
        addedPatients: addedPatients,
        removedPatients: removedPatients,
        addedExperts: addedExperts,
        removedExperts: removedExperts,
        removedTargets: deletedTargets,
        addedTargets: addedTargets,
        addedNotes: addedNotes,
        removedNotes: deletedNotes,
        removedMeshes: deletedMeshes,
        targets: targets,
        listSeq: listSeq,
        meshes: meshes
    }

        axios.post(process.env.REACT_APP_API +"/api/update-study/", studyDB,{
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        },).then(
            (response) => {
              setSuccessMessage("Etude modifié avec succes!")
              setErrorMessage('')
              //setnewStudy(response.data);
              setStudy(response.data)
            }
        ).catch((error)=>{setErrorMessage("Un expert avec le même username existe déja");})
        await delay(3000);
        setSuccessMessage("")

    }
  }

  const typeSelectHandleChange = (e) => {
    const newValue = e.target.value
    setType(newValue);
    setnewStudy({...newStudy, _type: newValue});

  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // Change styles based on drag state
    background: isDragging ? 'lightblue' : 'white',
    ...draggableStyle,
    marginLeft: isDragging ? "-60vh" : void (0)
  });

  // Styling the droppable container
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: 16,
  });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setnewStudy({...newStudy, [name]: value});
    }

    const handleTarget = (event, type) => {
      event.stopPropagation();
      const { name, value } = event.target;
  
      if (type === "note") {
        setNote({ ...note, [name]: value });
  
      }
      if (type === "target") {
        setTarget({ ...target, [name]: value });
      }
      if (type === "mesh") {
        setMesh({ ...mesh, [name]: value });
      }
  
    }

  return (
      
    <div style={{backgroundColor: "#0e2852", height:"100vh" }}>
    <Navbar isConnected/>
     <Grid container  style={{marginTop:"7%"}}>
     <Grid item xs={1.25} />
         <Grid item xs={2} style={{marginTop:"10px", paddingRight:"20px",  paddingLeft:"20px"}}>
        <Box style={{marginLeft:"40%"}}>
        <IconButton sx={{ color: '#FFFF'}} onClick={()=>navigate("/admin/studies")}  >
        <ArrowBack fontSize="large"/>
        </IconButton>
        </Box>
        </Grid>
        <Grid item xs={6} >
        <Box style={{overflowY: "scroll", backgroundColor:"white", borderRadius:"15px",
     paddingTop:"50px", paddingLeft:"10vh", maxHeight:"55vh", paddingBottom:"20px"}} /*className='scroll'*/>
         <Grid className="formLine" item container xs={12} style={{marginBottom:"25px"}}>
          <Grid item xs={4}>
           <Typography className="formText">Nom d'étude:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField size='small'  style={{width:'35vh'}} value={newStudy.name||''}
          name="name" onChange={(e) => {handleChange(e)} }/>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12}  style={{marginBottom:"3vh"}}>
          <Grid item xs={4}>
           <Typography className="formText">Type:</Typography>
          </Grid>
          <Grid item xs={8}>
          <Select size='small'  style={{width:'35vh'}} value={type}
          name="type" onChange={(e) => {typeSelectHandleChange(e)} }>
              <MenuItem key={0} value={"Pointage"}>Pointage</MenuItem>
              <MenuItem key={1} value={"Evaluation"}>Evaluation</MenuItem>
          </Select>
          </Grid>
        </Grid>
        {
              type === 'Evaluation' ?
                <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
                  <Grid item xs={4}>
                    <Typography className="formText">Notes:</Typography>
                  </Grid>
                  <Grid item container xs={8} direction={"column"}>
                    {notes.map((note_, i) => {
                      return (
                        <Grid key={i} item container xs={1}>
                           <Box sx={{ height: "6vh", border: '0.01vh solid #CECECE', marginBottom: '0.8vh', borderRadius: "0.5vh", display: "flex", width: "80%" }}>
                          <Typography style={{ marginLeft: "1vh", position: "relative", marginTop: '1.5%', width: "80%", fontSize:"1.5vh" }}>{note_.value + (note_.label ? ": " + (
                          note_.label.length < 70 ? note_.label :
                          note_.label.substring(0, 70) + "..."
                        ) : "")}</Typography>
                            <Button
                              onClick={() => {
                                setNote(note_);
                                setOpenNotesModalEdit(true);
                              }}
                              width="2px" style={{ maxWidht: "10px" }} ><FaEdit /> </Button>
                            <Button
                              onClick={() => {
                                setNotes(notes.filter((note) => note.label !== note_.label));
                                const deleted = dbNotes.find(dbNote => dbNote.uid === note_.uid);
                                    if(deleted !== -1) {
                                      const dbNotes_ = [...deletedNotes, deleted]
                                      setDeletedNotes(dbNotes_.map(m=>m))
                                    }
                              }}
                              style={{ maxWidht: "10px" }}><FaTrash style={{ minWidht: "10px" }} />
                            </Button>
                            <Modal

                              open={openNotesModalEdit}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Grid className="formLine" item container style={{ marginBottom: "25px", width: "30vh" }} >
                                  <Grid item container xs={4}>
                                    <Typography className="formText">Description de note:</Typography>
                                  </Grid>
                                  <Grid item container xs={8}>
                                    <TextField size='small' style={{ width: '35vh' }} value={note.label || ''}
                                      name="label" onChange={(e) => {
                                        const { name, value } = e.target;
                                        setNote({ ...note, [name]: value });
                                      }} />
                                  </Grid>
                                </Grid>
                                <Grid className="formLine" item container xs={12} style={{ width: "30vh" }}>
                                  <Grid item xs={4}>

                                    <Typography className="formText">Valeur:</Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <TextField type='number' InputLabelProps={{
                                      shrink: true,
                                    }} size='small' style={{ width: '35vh' }} value={note.value || ''}
                                      name="value" onChange={(e) => {
                                        e.stopPropagation();
                                        const { name, value } = e.target;
                                        setNote({ ...note, [name]: value });
                                        //// const _targets = targets;
                                        //  const index = _targets.findIndex((tar)=> tar.index = target.index);
                                        //  _targets[index] = {...target, [name]: value};
                                        //    setTargets(_targets)
                                      }} />
                                  </Grid>
                                </Grid>
                                <Grid item container>
                                  <Button onClick={(e) => {
                                    // let exist = targets.find((tar)=>tar.name === target.name);
                                    let newNotes = notes;
                                    const index = newNotes.findIndex((note_) => note_.index === note.index);
                                    newNotes[index] = note;
                                    setNotes(newNotes);
                                    


                                    //  }
                                    //  else{
                                    //     alert("Choisir cible précedente ")
                                    //     }
                                  }}
                                    variant="contained" style={{ float: "right", marginTop: "20px" }}>Modifier la note</Button>

                                </Grid>
                              </Box>
                            </Modal>
                          </Box>
                        </Grid>


                      );
                    })}

                    <Grid item container xs={1}>
                      <Button onClick={() => { setNote({}); setOpenNotesModal(true) }}><FaPlusSquare size={"3.2vh"} /></Button>
                    </Grid>
                  </Grid>
                  <Modal
                    open={openNotesModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                        <Grid item container xs={4}>
                          <Typography className="formText">Description de note:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField size='small' style={{ width: '35vh' }} value={note.label || ''}
                            name="label" onChange={(e) => { handleTarget(e, "note") }} />
                        </Grid>
                      </Grid>
                      <Grid className="formLine" item container style={{ width: "35vh" }}>
                        <Grid item xs={4}>

                          <Typography className="formText">Valeur:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField type='number' InputLabelProps={{
                            shrink: true,
                          }} size='small' style={{ width: '35vh' }} value={note.value || ''}
                            name="value" onChange={(e) => { handleTarget(e, "note") }} />
                        </Grid>
                      </Grid>
                      <Grid item container  >
                        <Grid item xs={5} />
                        <Grid item container xs={7}>

                          <Button onClick={() => {
                            if (note.label) {
                              const _notes = notes;
                              _notes.push({ ...note, index: _notes.length });
                              setNotes(_notes)
                              setNote({})
                            }
                          }}
                            variant="contained" style={{ float: "right", marginTop: "20px" }}>Ajouter la note</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>

                </Grid> : void (0)
            }
        <Grid className="formLine" item container xs={12} style={{marginBottom:"25px"}}>
          <Grid item xs={4}>
           <Typography className="formText">Description:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField multiline size='small'  style={{width:'35vh'}} value={newStudy.description||''}
          name="description" onChange={(e) => {handleChange(e)} }/>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Patients:</Typography>
          </Grid>
          <Grid item xs={8}>
          <Select
          multiple
          native
          value={patientList}
          style={{width:"35vh"}}
          // @ts-ignore Typings are not considering `native`
          onChange={(e) => handleChangeMultiple(e,"patient")}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
          {patients?patients.map((patient) => (
            <option key={patient.identifiant} value={patient.identifiant}>
              {patient.identifiant}
            </option>
          )) : null}
        </Select>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} style={{marginBottom:"25px"}}>
          <Grid item xs={4}>
           <Typography className="formText">Cibles:</Typography>
          </Grid>
          <Grid item xs={8}>
          {targets.map((target_, i)=>{
              return(
                <Box key={i} sx={{height:"6vh", border:'0.01vh solid #CECECE', marginBottom:'0.8vh', borderRadius:"0.5vh", display:"flex", width:"80%"}}>
                  <Typography style={{marginLeft:"1vh", position:"relative", marginTop:'1.5%', width:"80%", fontSize:"1.4vh" }}>{target_.name+ (target_.description? ": "+ (
                    target_.description.length < 50 ? target_.description :
                    target_.description.substring(0, 50) + "..."
                  ):"")}</Typography>
                  <Button
                  onClick={()=>{
                      index.current = i;
                      setTarget(target_);
                      const newDefTarget = targets.find((tar)=> tar.name === target_.defTarget)?targets.find((tar)=> tar.name === target_.defTarget).index:"";
                      setDefTargetSelected(
                        newDefTarget
                      );
                      setOpenEdit(true);

                      //targets.find((trgt)=> trgt.name === target.defTarget)?targets.find((trgt)=> trgt.name === target.defTarget).index : ""
                     
                  }}
                  width="2px" style={{maxWidht:"10px"}} ><FaEdit/> </Button>
                  <Button
                  onClick={()=>{
                    setTargets(targets.filter((target)=>target.name !== target_.name));
                    const deleted = DBtargets.find(dbTarget => dbTarget.uid === target_.uid);
                    if(deleted !== -1) {
                      const dbTarget_ = [...deletedTargets, deleted]
                      setDeletedTargets(dbTarget_.map(m=>m))
                    }
                  }}
                  style={{maxWidht:"10px"}}><FaTrash style={{minWidht:"10px"}}/>
                  </Button>
                  <Modal
          
          open={openEdit}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Grid className="formLine" item container xs={12} style={{marginBottom:"25px",width:"30vh"}} >
            <Grid item xs={4}>
             <Typography className="formText">Nom de cible:</Typography>
            </Grid>
            <Grid item xs={8}>
            <TextField size='small'  style={{width:'35vh'}} value={target.name||''}
            name="name" onChange={(e) => {
              const {name, value} = e.target;
              setTarget({...target, [name]: value});
            } }/>
            </Grid>
          </Grid>
          <Grid className="formLine" item container xs={12} style={{width:"30vh"}}>
            <Grid item xs={4}>   
  
             <Typography className="formText">Description:</Typography>
            </Grid>
            <Grid item xs={8}>
            <TextField multiline size='small'  style={{width:'35vh'}} value={target.description||''}
            name="description" onChange={(e) => {
              e.stopPropagation();
              const {name, value} = e.target;
              setTarget({...target, [name]: value});
            } }/>
            </Grid>
          </Grid>
          <Grid className="formLine" item container xs={12} style={{width:"30vh"}}>
            <Grid item xs={4}>
             <Typography className="formText" >Cible par défaut:</Typography>
            </Grid>
            <Grid item xs={8}>
            <Select
            name="defTarget"
            style={{width:"35vh", height:"40px"}}
            value={defTargetSelected }
            // @ts-ignore Typings are not considering `native`
            onChange={(e) => {
                const {name, value} = e.target;
                const _targets = targets.sort((a,b)=> a.index - b.index);
                const newDefTarget = value
                setDefTargetSelected(newDefTarget);
                const index = _targets.findIndex((tar)=> tar.index === target.index);
                const newTarget = _targets.find((tar)=> tar.index === value);
                setTarget({...target,[name]: newTarget?newTarget.name:""});
                setTargets(_targets)

            }}
            label="Native"
            inputProps={{
              id: 'select-multiple-native',
            }}
          >
              <MenuItem value=""  >
              <em>None</em>
            </MenuItem>
            {targets?targets.map((target,i) => (
            <MenuItem key={i} value={target.index}>{target.name}</MenuItem>
            )) : null}
          </Select>
          <Button onClick={(e)=> {
             // let exist = targets.find((tar)=>tar.name === target.name);
              let newTargets = targets//.sort((a,b)=> a.index - b.index);;
              const index = target.index;
              const targets_ = targets.filter((tar)=> tar.defTarget === targets[index].name);
              let indexes = [];
              targets_.forEach((tar)=>{
                indexes.push(targets.findIndex((targ)=> targ.index === tar.index));
              })
              const __index = targets.find((tar)=> target.defTarget === tar.name)?targets.find((tar)=> target.defTarget === tar.name).index:"";
              if(target.index > __index || __index === ""){
                newTargets[index] = target;
                if(indexes.length>0)
                {
                  indexes.forEach((id)=>{
                    newTargets[id].defTarget = target.name;
                  })
                }
                setTargets(newTargets);
              }
              else{
                  alert("Choisir cible précedente ")
                 }
          
          }}
           variant="contained" style ={{float: "right", marginTop: "20px"}}>Enregistrer les modifications</Button>
            </Grid>
          </Grid>
          </Box>
        </Modal>
                </Box>
                
                
              );
    })}
        
          
        <Button onClick={()=>{setTarget({}); setOpen(true)}}><FaPlusSquare size={"3.2vh"}/></Button>

          </Grid>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Grid className="formLine" item container xs={12} style={{marginBottom:"25px"}}>
          <Grid item xs={4}>
           <Typography className="formText">Nom de cible:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField size='small'  style={{width:'30vh'}} value={target.name||''}
          name="name" onChange={(e) => {handleTarget(e, 'target')} }/>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Description:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField multiline size='small'  style={{width:'30vh'}} value={target.description||''}
          name="description" onChange={(e) => {handleTarget(e, 'target')} }/>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} style={{marginBottom:"25px"}}>
          <Grid item xs={4}>
           <Typography className="formText">Cible par défaut:</Typography>
          </Grid>
          <Grid item xs={8}>
          <Select
          name="defTarget"
          style={{width:"30vh", height:"40px"}}
          value={target.name}
          // @ts-ignore Typings are not considering `native`
          onChange={(e) => {
              const {name, value} = e.target;
              setTarget({...target, [name]: value})}}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
            <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {targets?targets.map((target,i) => (
          <MenuItem key={i} value={target.name}>{target.name}</MenuItem>
          )) : null}
        </Select>
        <Button onClick={()=> {
            if(target.name ){
                const targets_ = [...targets.sort((a,b)=> a.index - b.index)];
                targets_.push({defTarget:"",...target, index: targets_.length});
                setTargets(targets_);
                setTarget({})
            }
        }}
         variant="contained" style ={{float: "right", marginTop: "20px"}}>Ajouter la cible</Button>
          </Grid>
        </Grid>
        </Box>
      </Modal>
          </Grid>
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Experts:</Typography>
          </Grid>
          <Grid item xs={8}>
        <Select
          multiple
          native
          style={{width:"35vh"}}
          value={expertName}
          // @ts-ignore Typings are not considering `native`
          onChange={(e) => handleChangeMultiple(e, "expert")}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
          {allExperts?allExperts.map((expert) => (
            <option key={expert.id} value={expert.id}>
              {expert.first_name +" "+ expert.last_name}
            </option>
          )) : null}
        </Select>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Meshes supplémentaires:</Typography>
              </Grid>
              <Grid item container xs={8} direction={"column"}>
                {meshes.map((mesh_, i) => {
                  return (
                    <Grid key={i} item container xs={2}>
                      <Box sx={{ height: "6.5vh", border: '0.01vh solid #CECECE', marginBottom: '0.8vh', borderRadius: "0.5vh", display: "flex", width: "80%" }}>
                        <Typography style={{ marginLeft: "1vh", position: "relative", marginTop: '1.5%', width: "80%", fontSize:"1.4vh" }}>{mesh_.fileName + (mesh_.sem ? ": " + (
                        mesh_.sem.length < 70 ? mesh_.sem :
                        mesh_.sem.substring(0, 70) + "..."
                      ) : "")}</Typography>
                        <div style={{ width: "3vh", backgroundColor: mesh_.color }}>

                        </div>
                        <IconButton
                          onClick={() => {
                            setMesh(mesh_);
                            setColor(mesh_.color)
                            setOpenMeshesModalEdit(true);
                          }}
                          style={{ maxWidht: "5px" }} ><FaEdit /> </IconButton>
                        <IconButton
                          onClick={() => {
                            const filtered = meshes.filter((mesh) => mesh.sem !== mesh_.sem)
                            setMeshes(filtered);
                            if(index !== -1){
                              var newStudy_ = {...newStudy}
                              var experts_ = [...newStudy_.experts]
                              var experts_  = experts_.map(ex =>
                                 ({...ex, patients: ex.patients.
                                map(p => ({...p, suppMesh: p.suppMesh.filter(m=> m.meshSupp.sem !== mesh_.sem)}))}));
                              newStudy_.experts = experts_;
                              setnewStudy(newStudy_)
                            }
                            const deleted = dbMeshes.find(dbmesh => dbmesh.uid === mesh_.uid);
                            if(deleted !== -1) {
                              const dbMeshes_ = [...deletedMeshes, deleted]
                              setDeletedMeshes(dbMeshes_.map(m=>m))
                            }
                           
                          }}
                          style={{ maxWidht: "10px" }}><FaTrash style={{ minWidht: "10px" }} />
                        </IconButton>
                        <Modal

                          open={openMeshesModalEdit}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <Grid className="formLine" item container style={{ marginBottom: "25px", width: "30vh" }} >
                              <Grid item container xs={4}>
                                <Typography className="formText">Sémantique:</Typography>
                              </Grid>
                              <Grid item container xs={8}>
                                <TextField size='small' style={{ width: '35vh' }} value={mesh.sem || ''}
                                  name="sem" onChange={(e) => {
                                    const { name, value } = e.target;
                                    setMesh({ ...mesh, [name]: value });
                                  }} />
                              </Grid>
                            </Grid>
                            <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                              <Grid item container xs={4}>
                                <Typography className="formText">Couleur:</Typography>
                                <div style={{ height: "8vh", width: "9vh", backgroundColor: color }}>

                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <HexColorPicker style={{ width: "35vh", height: "20vh" }} color={color} onChange={setColor} />

                              </Grid>
                            </Grid>
                            <Grid className="formLine" item container style={{ marginBottom: "25px", width: "30vh" }} >
                              <Grid item container xs={4}>
                                <Typography className="formText">Nom du fichier:</Typography>
                              </Grid>
                              <Grid item container xs={8}>
                                <TextField size='small' style={{ width: '35vh' }} value={mesh.fileName || ''}
                                  name="fileName" onChange={(e) => {
                                    const { name, value } = e.target;
                                    setMesh({ ...mesh, [name]: value });
                                  }} />
                              </Grid>
                            </Grid>
                            <Grid item container>
                              <Button onClick={(e) => {
                                // let exist = targets.find((tar)=>tar.name === target.name);
                                let newMeshes = [...meshes];
                                const index = newMeshes.findIndex((mesh_) => mesh_.uid === mesh.uid);
                                if(index !== -1){
                                  newMeshes[index] = { ...mesh, color: color };
                                  setMeshes(newMeshes);
                                  var newStudy_ = {...newStudy}
                                  var experts_ = [...newStudy_.experts]
                                  var experts_  = experts_.map(ex =>
                                     ({...ex, patients: ex.patients.
                                    map(p => ({...p, suppMesh: p.suppMesh
                                      .map(sm=>({...sm, meshSupp:newMeshes.find((m)=>m.uid===sm.meshSupp.uid)?newMeshes.find((m)=>m.uid===sm.meshSupp.uid):(sm.meshSupp+"ayeeeeeeeh")}))}))}));
                                  newStudy_.experts = experts_;
                                  setnewStudy(newStudy_)
                                }
                                else{
                                  newMeshes.push({ ...mesh, color: color });
                                  setMeshes(newMeshes);
                                  var newStudy_ = {...newStudy}
                                  var experts_ = [...newStudy_.experts]
                                  var experts_  = experts_.map(ex => ({...ex, patients: ex.patients.
                                  map(p => ({...p, suppMesh: [...p.suppMesh, {meshSupp:{...mesh, color:color}, showMesh: false} ]}))}));
                                  newStudy_.experts = experts_;
                                  setnewStudy(newStudy_)

                                }
                               

                                
                              }}
                                variant="contained" style={{ float: "right", marginTop: "20px" }}>Modifier le mesh</Button>

                            </Grid>
                          </Box>
                        </Modal>
                      </Box>
                    </Grid>


                  );
                })}

                <Grid item container xs={1}>
                  <Button onClick={() => { setMesh({}); setOpenMeshesModal(true) }}><FaPlusSquare size={"3.2vh"} /></Button>
                </Grid>
              </Grid>
              <Modal
                open={openMeshesModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>

                  <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                    <Grid item container xs={4}>
                      <Typography className="formText">Sémantique:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField size='small' style={{ width: '35vh' }} value={mesh.sem || ''}
                        name="sem" onChange={(e) => { handleTarget(e, "mesh") }} />
                    </Grid>
                  </Grid>
                  <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                    <Grid item container xs={4}>
                      <Typography className="formText">Couleur:</Typography>
                      <div style={{ height: "8vh", width: "9vh", backgroundColor: color }}>

                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <HexColorPicker style={{ width: "35vh", height: "20vh" }} color={color} onChange={setColor} />

                    </Grid>
                  </Grid>
                  <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                    <Grid item container xs={4}>
                      <Typography className="formText">Nom du fichier:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField size='small' style={{ width: '35vh' }} value={mesh.fileName || ''}
                        name="fileName" onChange={(e) => { handleTarget(e, "mesh") }} />
                    </Grid>
                  </Grid>

                  <Grid item container  >
                    <Grid item xs={5} />
                    <Grid item container xs={7}>

                      <Button onClick={() => {
                        if (mesh.sem) {
                          const _meshes = meshes;
                          _meshes.push({ ...mesh, index: _meshes.length, color: color });
                          setMeshes(_meshes);
                          var studyClone = {...newStudy};
                          var expertsClone =[...studyClone.experts];
                          expertsClone.map((expClone,i)=>
                          {
                            const newPatients = expClone.patients.map((patient)=>
                          ({...patient, suppMesh:_meshes.map(m => {return({meshSupp:m, showMesh: false})})})
                          );
                          expertsClone[i].patients = newPatients;
                        }
                          );
                          studyClone.experts = expertsClone;
                          setnewStudy(studyClone)
                          setMesh({})
                        }
                      }}
                        variant="contained" style={{ float: "right", marginTop: "20px" }}>Ajouter le mesh</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

            </Grid>
            <FormControlLabel key={"s1"} control={<Checkbox onChange={(event) => {
                                                setListSeq(!listSeq)
                                              }} checked={listSeq} />} label={<Typography style={{
                                                fonFamily: 'Zen Antique Soft',
                                                fontSize: '1.4vh'
                                              }}>Activer le parcours séquentiel pour la liste patients/cibles</Typography>}></FormControlLabel>
            <Grid item container>
              <Button onClick={() => {
                setTreeView(true);

              }}>MODIFIER L'ORDRE DES PATIENTS OU L'AFFICHAGE DES MESHES</Button>
            </Grid>
            <Modal
              open={treeView}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              
              {loading? <Typography> Chargement... </Typography>:(<Box sx={style}>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: "80vh", flexGrow: 1, maxWidth: "100%", overflowY: 'auto' }}
                >
                  
                  {newStudy.experts.sort((a,b)=> a.user.first_name.localeCompare(b.user.first_name)).map((exp, k) => {
                    const exp_ = allExperts.find(expert => expert.id.toString() 
                    === exp.id.toString());
                  
                    
                   
                    if(exp_){
                      const expert = exp_.user;
                      return (
                        <TreeItem key={k} nodeId={expert.id} label={expert.first_name + " " + expert.last_name}>
                          <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId={expert.id}>
                              {(provided, snapshot) =>
                              (
  
                                <List {...provided.droppableProps} ref={provided.innerRef} component="nav" style={getListStyle(snapshot.isDraggingOver)} >
  
                                  {(exp.patients.map((pat_, i) => {
                                    const pat = pat_;
                                    return (
                                      <Draggable key={pat.identifiant} draggableId={pat.identifiant} index={i}>
                                        {(provided, snapshot) => (
                                          <div>
                                            <hr/>
                                            <Grid key={pat.identifiant}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                              item container >
                                              <Grid  key={i+"i"} item container xs={2}>
  
                                                <ListItemText key={i} style={{ paddingLeft: '1vh' }} primary={<Typography style={{
                                                  fonFamily: 'Zen Antique Soft',
                                                  fontSize: '1.7vh'
                                                }}
                                                >  <OpenWith style={{ width: "1.9vh", marginRight: "1vh", position: "relative", top: "0.6vh" }} />{pat.identifiant} </Typography>}
                                                />
                                              </Grid>

                                              <Grid key={i+"j"} item container xs={9} >
                                                <Grid item container xs={2} style={{position:"relative",top:'25%'}}>
                                                <Typography variant='body1' style={{fontSize:"1.6vh", fontWeight:"bolder"}}>Vue:</Typography>
                                                </Grid>
                                                <Grid item container xs={10}>
                                                <FormControl >
                                              <RadioGroup
                                                row
                                                defaultValue=""
                                                onChange={(e)=>handleViewModeChange(e,exp, pat)}
                                                value={pat.viewMode}
                                              >
                                                <FormControlLabel  value="cerveau" control={<Radio />} label={<Typography style={{fontSize:"1.5vh"}}>Cerveau</Typography>} />
                                                <FormControlLabel value="peeling" control={<Radio />} label={<Typography style={{fontSize:"1.5vh"}}>Peeling</Typography>} />
                                              </RadioGroup>
                                            </FormControl>

                                                </Grid>
                                              </Grid>
                                              <Grid item container xs={12}>
                                                  <Typography style={{fontSize:"1.6vh", fontWeight:"bolder"}} variant='body1'>Meshes supplémentaire:</Typography>
                                                </Grid>
                                              { pat_.suppMesh? pat_.suppMesh.map((mesh, j) =>
                                              <Grid item container xs={6}>
                                                <FormControlLabel key={i+""+j} control={<Checkbox onChange={(event) => {
                                                  event.stopPropagation();
                                                  event.preventDefault();
                                                  setnewStudy(prevStudy => {
                                                    var studyClone = {...prevStudy};
                                                    var expertsClone = [...studyClone.experts];
                                                    var patientsClone = [...expertsClone[k].patients];

                                                    var finalPatients = patientsClone.map(patient_ => {
                                                      const patient = patient_.patientId;
                                                      if (patient.identifiant === pat.identifiant) {
                                                        const updatedMeshes = [...patient_.suppMesh];
                                                        updatedMeshes[j] = {
                                                          ...updatedMeshes[j],
                                                          showMesh: !updatedMeshes[j].showMesh,
                                                        };
                                                        return { ...patient_, suppMesh: updatedMeshes };
                                                      }
                                                      return patient_;
                                                    });

                                                    studyClone.experts[k].patients =  finalPatients;

                                                    return  studyClone;

                                                  
                                                  });

                                                }} checked={mesh.showMesh} />} label={<Typography style={{
                                                  fonFamily: 'Zen Antique Soft',
                                                  fontSize: '1.4vh'
                                                }}>{mesh.meshSupp.sem}</Typography>}></FormControlLabel>
                                                </Grid>
                                              ): void(0)}
  
                                            </Grid>
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Draggable>)
                                  }))}
                                  {provided.placeholder}
                                </List>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </TreeItem>)
                    
                    }


                  })}
                  
                </TreeView>
              </Box>)}
            </Modal>
        { errorMessage ?
          (<Alert severity="error" className="alert">{errorMessage}</Alert>) : null}
        { successMessage ?
          (<Alert severity="success" className="alert">{successMessage}</Alert>) : null} 
        
    </Box>
    <Button style= {{float: "right"}} variant="contained" onClick={()=>{submitHandle()}}>ENREGISTRER LES MODIFICATIONS</Button>
        </Grid>
        
      <Grid item xs={1}></Grid>
      </Grid>
      </div>
     
  );
}
const mapStateToProps = state => ({
  study: state.study.currentStudy,
  user: state.user,
    
  });


  
const mapDispatchToProps = dispatch => ({
  setStudy:  (target) => dispatch(setStudy(target)),
    
  });
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStudy);
