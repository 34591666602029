
import * as React from 'react';
import {Grid, Typography, ListItemButton,
  ListItemText, List, Box, TextField, IconButton} from '@mui/material/';
import {Search} from '@mui/icons-material/';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ResetStudy from '../admin/ResetStudy';

require('jquery-mousewheel');


const ListComponent = (props) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0); //ListItem index who is selected
    const [options, setOptions] = React.useState(props.elements); // Users filtred list (used in autocomplete search )


React.useEffect(()=>{
    $('.scroll').mCustomScrollbar({
        scrollButtons:{enable:true},
        keyboard:{scrollType:"stepless"},
        mouseWheel:{scrollAmount:150},
        theme:"rounded-dark",
        autoExpandScrollbar:true,
      });
      setOptions(props.elements);
},[props.elements])

const handleListItemClick = (event, index, user) => {
  setSelectedIndex(index);
  props.onSelection(user);//attenti
  
};



const onChangeHandler = (text, array) => {
  let matches = []
  matches = array.filter(item => {
  const regex = new RegExp(`${text}`, "gi");
  if(item.patient?item.patient.firstName.match(regex):false || item.patient?item.patient.lastName.match(regex):false)
  return item.patient.firstName.match(regex) || item.patient.lastName.match(regex)
  if(item.name?item.name.match(regex):false){
    return item.name.match(regex)
  }
  if(item.user?item.user.first_name.match(regex):false || item.user?item.user.last_name.match(regex):false)
  return item.user.first_name.match(regex) || item.user.last_name.match(regex)
  
  else {return null;}
  })
  setOptions(matches);
  }
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius:'4%', paddingTop:'25px' }}>
            <Grid container >
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={6}>
                 {props.children? props.children:null}
            </Grid>
            {props.search?(<Grid item xs={5}>
                <TextField size='small'
                onChange={e => onChangeHandler(e.target.value, props.elements?props.elements:props.patients)}/>
                <Search sx={{ color: 'action.active', ml: 1, my: 1.5 }} />   
            </Grid>): null}
            </Grid>
            <Box style={{overflowY: "hidden",
            height: props.height?props.height : "350px"}} className='scroll'>
            
            <List  component="nav" style={{marginBottom:'100px'}}>
            {(options.map((elem,i)=> {
                const {first_name, last_name, username} = elem.user||{};
                const {identifiant, irm} = elem;
                const {name, _type} = elem || {};
                return(
            <div key={i}>
                <Grid container xs={12}>
                <Grid item xs={10}>
                <ListItemButton
                    selected={selectedIndex === i}
                    onClick={(event) => handleListItemClick(event, i, elem)}>

                <ListItemText  primary={<Typography style={{ fonFamily: 'Zen Antique Soft',
                fontSize: '20px' }}> {elem.user ? (first_name+' '+last_name): name? name : identifiant}</Typography> } 
                secondary={username||irm|| "Type: "+_type}/>
                </ListItemButton>
                </Grid>
                <Grid item container xs={2}>
               {props.onReset && elem._type === "Evaluation" ?
               <Grid item xs={4}>
               <ResetStudy onReset={(value)=>props.onReset(value)} study_uid={elem.uid}/>
               </Grid>
               : <Grid item xs={4}/>}
               <Grid item xs={4}>
               <IconButton  onClick={(e)=>{
                        e.preventDefault()
                        props.onDelete(username)}}>
                    <DeleteIcon/>
                    </IconButton>
               </Grid>
                   
                </Grid>
                </Grid>
                
                
            </div>)
             }))}
            </List>
            
            </Box>
            </Box>
    );
};

export default ListComponent;

