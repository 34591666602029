import * as React from 'react';
import {Grid, Typography, Card, CardContent, CardActions, Button, CardMedia} from '@mui/material';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  setUser } from '../redux/user/user-action';
import axios from 'axios';
import { setStudy } from '../redux/study/study-action';
import { Box } from '@mui/system';
import './style.css'
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';





function Studies({setStudy,hideLoader, setUser, showLoader, user
  }) {
  const navigate = useNavigate();
  const { first_name, last_name} = user || {};
  const [studies, setStudies] = React.useState([]);
  //const theme = useTheme();

//const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
//const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));




  React.useEffect(() => {
    showLoader();
    setStudy({});
    const {access,is_staff} = user;
    !access||is_staff? navigate("/expert/login"): void(0);  
    async function fetchData(){
     // await axios.post('https://'+window.location.hostname+'/api/studies/',{
      await axios.post(process.env.REACT_APP_API+'/api/studies/',{
        username:user.username
      },
    {
      headers: {
        Authorization: `Bearer ${access}`,
        'X-CSRFToken': getCookie( 'csrftoken')
      }
    },
     )
    .then((response) => {
      hideLoader();
      //TO DO commente stp
      const filteredStudies = response.data.map((study)=>({...study, ...study.studyId}))
      
      setStudies(filteredStudies);  

    },  (error) => {
      console.log(error );
      hideLoader();
     // navigate("/");
    });



    }
    fetchData();
    
   
    }, []);

    

    

  return (

      
      <div style={{backgroundColor: "#85B2D9", height:"100vh" , paddingBottom:"7vh"}}>
       <Navbar isExpert isConnected />
        <Grid container xs={12}>
          <h1 style={{color:"white", marginLeft:"25px", fontFamily:"Kanit", fontSize:"3.5vh"}}>Bienvenue {first_name+" "+ last_name}</h1>
        </Grid>
        <Grid container xs={12}>
        <Grid item xs={1.5}/>
        <Grid style={{paddingTop: '6vh'}} item container xs={9}>
      { 
          studies.length > 0 ? studies.sort((a,b) => a.uid - b.uid).map((astudy, i)=> (
            <Grid key={i} item container xs={4} sx={{marginTop:"1vh"}}>
            <Card sx={{ width: "46vh", marginRight:"10px", border:1.5, borderRadius:"1.4vh" }}>
            <CardMedia
              component="img"
              height="150"
              image={process.env.REACT_APP_STATIC+"images/study.png"}
              alt="green iguana"
            />
            <CardContent>
              <Typography variant="h5" component="div" sx={{ fontSize:"3.7vh", mb: 2, fontFamily:"Kanit", fontWeight:"bold" }}>
                {astudy.name}
              </Typography>
              <hr/>
              <Typography sx={{fontSize:"1.7vh", fontFamily:"Kanit"}}  >
               <img alt="icon"  src={astudy._type === "Evaluation" ? process.env.REACT_APP_STATIC+'images/check-list.png' : process.env.REACT_APP_STATIC+'images/brain.png'} style={{width:"3.5vh"}}/> <span style={{position:"relative", bottom:"1.1vh"}}><span style={{fontWeight:"bolder", marginLeft:"1vh"}}>Type:</span> {" "+ astudy._type} </span> 
              </Typography>
              <hr/>
              <Typography sx={{fontSize:"1.7vh", fontFamily:"Kanit",}}  >
               <img alt="icon"  src={process.env.REACT_APP_STATIC+'images/patient.png'} style={{width:"3.3vh"}}/> <span style={{position:"relative", bottom:"1vh"}}><span style={{fontWeight:"bolder", marginLeft:"1vh"}}>Nombre de patients:</span> {" "+ astudy.patientNumber} </span> 
              </Typography>
              <Typography sx={{fontSize:"1.7vh", fontFamily:"Kanit"}}  >
               <img alt="icon"  src={process.env.REACT_APP_STATIC+'images/target.png' }style={{width:"3.3vh"}}/> <span style={{position:"relative", bottom:"1.1vh"}}><span style={{fontWeight:"bolder", marginLeft:"1vh"}}>Nombre de cibles  :</span> {" "+ astudy.targetNumber} </span> 
              </Typography>
              {
                  astudy._type !== "Evaluation" ?
                  <Typography sx={{ mb: 2, fontSize:"1.7vh", fontFamily:"Kanit"}}  color={astudy.progress === 0 ?"red" : astudy.progress === astudy.patientNumber ? "green" : "orange"}>
                  <img alt="icon"  src={process.env.REACT_APP_STATIC+'images/graphic-progression.png' }style={{width:"3.5vh"}}/> <span style={{position:"relative", bottom:"1.1vh", marginLeft:"1vh"}}> {astudy.progress + " patients pointés sur " + astudy.patientNumber} <span> ({(astudy.patientNumber?astudy.progress/astudy.patientNumber*100:0).toFixed(1)}%)</span></span>
                 </Typography>
                 :
                 <Typography sx={{ mb: 2 , fontSize:"1.7vh", fontFamily:"Kanit"}}  color={astudy.notes_done === 0 ?"red" : astudy.notes_done === astudy.patientNumber ? "green" : "orange"}>
                 <img alt="icon"  src={process.env.REACT_APP_STATIC+'images/graphic-progression.png'} style={{width:"3.5vh"}}/> <span style={{position:"relative", bottom:"1.1vh", marginLeft:"1vh"}}>{astudy.notes_done + " patients notés sur " + astudy.patientNumber} <span> ({(astudy.patientNumber?astudy.notes_done/astudy.patientNumber*100:0).toFixed(1)}%)</span></span>
                </Typography>
              }
             <hr/>
             <Box style={{height:"8vh", maxHeight:"8vh", overflow:"auto"}}>
             <Typography sx={{ mb: 2, fontSize:"1.7vh", fontFamily:"Kanit", maxHeight:"8vh"}}>
                <span style={{fontWeight:"bolder"}}>Description:</span>
                {" "+ astudy.description }
              </Typography>
             </Box>
            </CardContent>
            <CardActions style={{float: "center"}}>
              <Button 
              style={{
                backgroundColor: "#66A4D5",
              }}
               fullWidth variant="contained"  onClick={()=>{
                  setStudy({ ...astudy.studyId, studySelected: i})
                  navigate('/viewer')
              }} size="medium">
                <Typography style={{fontFamily:'Kanit', fontWeight:"bold"}}>
                  Ouvrir
                </Typography>
              </Button>
            </CardActions>
          </Card>
          </Grid>

          )
          ) : <Typography sx={{color:"white"}}>Chargement...</Typography>
      }
      </Grid>
      <Grid item xs={1.5}></Grid>
      </Grid>
      </div>
     
  );
}
const mapStateToProps = state => ({
    study: state.study.currentStudy,
    user: state.user,
  });
  
  const mapDispatchToProps = dispatch => ({
    setStudy: (study) => dispatch(setStudy(study)),
    setUser: (studies) => dispatch(setUser(studies)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(Studies);
