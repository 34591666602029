import patientTypes from "./patient-types";
import setPatient from "./patient-utils";

const INTIAL_STATE = {
    currentPatient:null, //section name
};

const patientReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case patientTypes.SET_PATIENT:
            return{
                ...state,
                currentPatient: action.payload
            };
        case patientTypes.SET_VALUES:
            return{
                ...state,
                currentPatient: setPatient(state.currentPatient, action.payload)
            };
        case patientTypes.SET_FILES:
            return{
                ...state,
                files: setPatient(state.currentPatient, action.payload)
            };
        case patientTypes.CLEAR_PATIENT:
            return{
                currentPatient: null
            };
        default:
            return state;
    }
};

export default patientReducer;