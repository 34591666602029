import * as React from 'react';
import {Grid, Typography, Box, IconButton, Alert} from '@mui/material/';
import { Add, ArrowBack} from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';
import {setPatient} from '../redux/patient/patient-action';
import { setPatients } from '../redux/patients/patients-action';
import {connect} from 'react-redux';
import axios from 'axios';
import ListComponent from '../Components/ListComponent';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';


  


function Patients({setPatient,userSelected, patients, setPatients, hideLoader, showLoader}) {

const {access, is_staff} = userSelected || {};
const navigate = useNavigate();

React.useEffect(() => {
  !access&&!is_staff ? navigate("/admin/login"):void(0);
  axios.get(process.env.REACT_APP_API +'/api/patients/',       {
    headers: {
      Authorization: `Bearer ${access}`
    }
  },)
  .then(response => {
      setPatients(response.data);
      console.log(response.data);
      hideLoader();
    })
  .catch(error => {
      hideLoader();
      console.log(error);
    });
  
}, [setPatients, userSelected, navigate, hideLoader, showLoader]);

const deleteDB = (p) => {
  const {identifiant} = p;
 axios.post(process.env.REACT_APP_API +'/api/delete-patient/',
  {identifiant: identifiant},
  {
    headers: {
      Authorization: `Bearer ${access}`,
      'X-CSRFToken': getCookie( 'csrftoken')
    }
  },
 )
.then((response) => {
  setPatients(patients.filter(patient => patient.identifiant !== identifiant));

},  (error) => {
  console.log(error )
 // navigate("/");
})
}

  return (
    <div style={{backgroundColor: "#0e2852", height:"100vh" }}>
    <Navbar isConnected/>
        <Grid container xs={12} style={{marginTop:"10%"}}>
        <Grid item xs={1.25} />
          <Grid item xs={2} >
        <IconButton sx={{ color: '#FFFF', marginLeft:"40%"}} onClick={() => {navigate('/admin')}}>
        <ArrowBack fontSize="large"/>
        </IconButton>
        </Grid>   
        <Grid item xs={5}>

    <ListComponent  search={true} elements={patients.sort((a,b) => a.index - b.index)} 
    onDelete={(value)=>deleteDB(value)}
    onSelection={(value) => {setPatient(value); navigate("/admin/update-patient")}} >
        <Box sx={{ width:'500px',display: 'flex',  marginLeft:'auto', marginRight:'auto' }}>
           <IconButton sx={{marginRight:'20px', color:'#0e2852'}} onClick={() => navigate('/admin/new-patient')}>
           <Typography style={{ textAlign:'center',fonFamily: 'Zen Antique Soft',
             fontSize: '18px' }}>Nouveau patient</Typography>
            <Add/>
           </IconButton>
        </Box>
    </ListComponent>
        </Grid>
        <Grid item xs={2}>

        </Grid>
        </Grid>
      </div>
     
  );
}


const mapDispatchToProps = dispatch => ({
    setPatient: (target) => dispatch(setPatient(target)),
    setPatients:  (target) => dispatch(setPatients(target)),
    
  });
const mapStateToProps = state => ({
userSelected: state.user,
patients: state.patients.patients})
export default connect(mapStateToProps,mapDispatchToProps)(Patients);

    