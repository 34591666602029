import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Button, IconButton } from '@mui/material';
import HelpIcon from "@mui/icons-material/Help";



const HelpViewer =({type}) => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const data = type !== "Evaluation" ? 
  [
    {
      index:0,
      title: "Comment sélectionner une étude, un patient et une cible?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page1.mkv",
      description: `Après la connexion, choisissez une des études affichées et cliquez sur "Ouvrir"
      - Vous pouvez sélectionner un patient directement sur la liste des patients.
      - Vous pouvez sélectionner une cible directement sur la liste des cibles ou en cliquant sur le bouton suivant ou précédent.
      - Sur la dernière cible, le bouton Pointage Suivant permet de passer à la première cible du patient suivant.
      - Sur la première cible de la liste, le bouton Pointage Précédent permet de passer à la première cible du patient précédent.
      `
    },
    {
      index:1,
      title: "Comment pointer une cible (1)?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page2.mkv",
      description: `
      Pour pointer une cible, vous devez déplacer les axes des vues Axiales, Sagittal et Coronal, sur les vues 3D la cible est représentée par une sphère rouge.
      - L'état de chaque cible est affiché dans la liste des cibles : non défini->rouge, modifié->orange, enregistré->vert.
      - L'état de chaque patient est affiché dans la liste des patients : toutes les cibles définies->vert, rouge sinon.
      - Les cordonnées de la cible sont mises à jour à chaque modification (Pointage / X, Y et Z).
      `
    },
    {
      index:2,
      title: "Comment pointer une cible (2)?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page3.mkv",
      description: `Vous pouvez également pointer une cible en double cliquant sur un point dans les vues 2D ou 3D.`},
    {
      index:3,
      title: "Comment pointer une cible (3)?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page4.mkv",
      description: `Vous pouvez également modifier directement les cordonnées de la cible par saisie dans les champs Pointage / X, Y et Z.`    }
    ,
    {
      index:4,
      title: "Comment changer le mode de vue dans le viewer?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page5.mkv",
      description: `Vous pouvez sélectionner le mode de vue le plus adapté pour réaliser le pointage en le sélectionnant dans le menu déroulant Affichage
      - vue 2D sagittale, coronale ou axiale
      - vue 3D (plans 2D, surface cerveau ou peeling)
      - composition 3 vues 2D + vue 3D
      `
    },
    {
      index:5,
      title: "Comment utiliser le Peeling?",
      video: process.env.REACT_APP_STATIC+"videos/page6.mkv",
      description: `
      Sélectionnez le mode Peeling sur la vue 3D
- la profondeur de peeling est modifiable via la barre de réglage ou via les boutons + et -
- la hauteur de la zone de peeling est modifiable via la barre de réglage ou via les boutons + et -
`
    },
    {
      index:6,
      title: "Comment changer le fenêtrage (contraste/luminosité) sur les vues?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page8.mkv",
      description: `
      Cliquez dans une des vues 2D et faites glisser le curseur 
        - horizontalement pour régler le contraste
        - verticalement pour régler la luminosité

        Pour revenir au réglage initial, cliquez sur Fenêtrage par défaut
        `
      
    },
    {
      index:7,
      title: "Comment afficher un masque sur les images?",
      videoUrl: process.env.REACT_APP_STATIC+"videos/page7.mkv",
      description: `S'il est disponible pour le patient, vous pouvez superposer l'affichage d'un masque sur les images (e.g. masque IRMf)
      - sélectionnez ce mode via la case à cocher 
      - le masque est visible sur les vues 2D ou la vue 3D plans
      `
    } 
  ] :
  [
    {
        index:0,
        title: "Manipuler la vue 3D",
        videoUrl: process.env.REACT_APP_STATIC+"videos/viewer3D.mp4",
        description: `Vous pouvez manipuler la vue 3D en cliquant sur le cerveau et en glissant en même temps, vous pouvez combiner le clic souris avec le bouton CTRL ou ALT.`
    },
    {
        index:1,
        title: "Utiliser le peeling",
        videoUrl: process.env.REACT_APP_STATIC+"videos/peeling.mp4",
        description: `Vous pouvez controler le Peeling en utilisant les Sliders ${"'Profondeur'"} et ${"'Hauteur'"}, Ainsi que les boutons Plus et Moins.`
    },
    {
        index:2,
        title: "Utiliser le fenêtrage",
        videoUrl: process.env.REACT_APP_STATIC+"videos/fenetrage.mp4",
        description: `Vous pouvez controler le fenêtrage (luminosité et contraste) des vues en cliquant et glissant la souris les 3 coupes, vous pouvez également réinstaller le fenêtrage en cliquant sur le bouton 'fenêtrage par défaut'.`
    },
    {
        index:3,
        title: "Comment donner une note?",
        videoUrl: process.env.REACT_APP_STATIC+"videos/evaluation.mp4",
        description: `Vous pouvez donner une note en utilsant la liste de selection, vous devez sélectionner une note pour passer au patient suivant.`
    },
    {
        index:4,
        title: "Comment changer les modes de vue?",
        videoUrl: process.env.REACT_APP_STATIC+"videos/viewmodes.mp4",
        description: `Vous pouvez changer le mode de vue en utilisant la liste de selection et en choisissant le mode de vue souhaité.`
    }


  ]

 
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="App">
      <IconButton size="medium" sx={{ color: "#1583EA" }} onClick={handleOpen}>
        <HelpIcon fontSize="medium"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <h2>{data[currentIndex].title}</h2>
          <p>{data[currentIndex].description}</p>
          <video controls autoPlay loop muted width="100%" src={data[currentIndex].videoUrl} />
          <div>
            <Button onClick={handlePrev} disabled={currentIndex === 0}>
              Précédent
            </Button>
            <Button onClick={handleNext} disabled={currentIndex === data.length - 1}>
              Suivant
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HelpViewer;