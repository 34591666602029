import * as React from 'react';
import { TextField, Grid, Typography, Button, Box, Alert } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { setUser } from '../redux/user/user-action';
import Navbar from '../Components/Navbar';







const LoginAdmin = ({ setUser, user, hideLoader, showLoader }) => {
  const [logged, setLogged] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  let navigate = useNavigate();

  React.useEffect(() => {
    const { access, is_staff } = user;
    access && is_staff ? navigate("/admin") : void (0);


    hideLoader();

  }, [navigate, hideLoader, showLoader]);

  const login = (e) => {
    e.preventDefault();
    e.stopPropagation()
    axios.post(process.env.REACT_APP_API + '/api/admin/login/',
      {
        username: username,
        password: password
      }
    )
      .then((response) => {
        setUser(response.data)
        navigate('/admin');
        setLogged(null);
      }, (error) => {
        console.log(error);
        setLogged(false);
      });
  };



  function handleChange(e) {
    e.preventDefault();
    const { value, name } = e.target;
    name === "password" ? setPassword(value) : void (0);
    name === "username" ? setUsername(value) : void (0);
  }
  return (
    <div style={{ backgroundColor: "#0e2852", height: "100vh" }}>
      <Navbar />
      <Grid container>
        <Grid item xs={4}>
        </Grid>
        <Grid container item xs={4} style={{
          backgroundColor: 'white',
          borderRadius: '4%',
          paddingBottom: '30px',
          marginTop: "8vh"
        }}>
          <Grid item xs={3}/>
          <Grid item xs={6}>
            <form onSubmit={login}>
              <img style={{ width: "12vh", display: 'flex', marginLeft: 'auto', marginRight: 'auto' }} alt="logo" src={process.env.REACT_APP_STATIC + "images/syneika.jpg"} />
              <Typography className="textNoir"> Veuillez Entrer votre mot de passe! </Typography>
              <TextField onChange={handleChange} fullWidth style={{ backgroundColor: 'white', marginBottom: '15px', marginTop: '35px' }}
                inputProps={{ style: { fontSize: 20, fontFamily: 'Zen Antique Soft' } }}
                name='username' label="Nom d'utilisateur" variant="outlined" />
              <TextField fullWidth style={{ backgroundColor: 'white' }}
                type="password" name='password' onChange={handleChange} label="Mot de passe" variant="outlined" />
              <Box pt={2}>
                {logged === false ? <Alert className='errorAlert' severity="error">Votre mot de passe est incorrect!</Alert> : null}
                <Button fullWidth
                  type="submit"
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Zen Antique Soft',
                    backgroundColor: "#0e2852",
                    marginTop: '10px',
                  }} onSubmit={(e) => { login(e) }} variant="contained">CONNEXION</Button>
              </Box>
            </form>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Grid>
    </div>

  );
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);

