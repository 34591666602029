import * as React from 'react';
import { Grid, Typography, IconButton, Box, TextField, Button, Select, MenuItem, Alert, Checkbox, FormControlLabel, FormControl, List, ListItemText, FormLabel, RadioGroup, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import { ArrowBack, OpenWith } from '@mui/icons-material/';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { connect } from 'react-redux';
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaPlusSquare } from "react-icons/fa";
import { HexColorPicker } from "react-colorful";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 4,
  p: 4,
  paddingBottom: "5px",
  paddingLeft: "3vh"
};


function NewStudy({ hideLoader, user }) {



  const [color, setColor] = React.useState("#aabbcc");
  const [newStudy, setnewStudy] = React.useState({ experts: [] });
  const [experts, setExperts] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [expertName, setExpertName] = React.useState([]);
  const [patientList, setpatientList] = React.useState([]);
  const [targets, setTargets] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [meshes, setMeshes] = React.useState([]);
  const [listSeq, setListSeq] = React.useState(false);
  const [notesErrorMessage, setNotesErrorMessage] = React.useState(null)
  const [ciblesErrorMessage, setCiblesErrorMessage] = React.useState(null)
  const [meshesErrorMessage, setMeshesErrorMessage] = React.useState(null)


  const [note, setNote] = React.useState([]);
  const [openNotesModalEdit, setOpenNotesModalEdit] = React.useState(false);
  const [openMeshesModal, setOpenMeshesModal] = React.useState(false);
  const [openMeshesModalEdit, setOpenMeshesModalEdit] = React.useState(false);

  const [target, setTarget] = React.useState([]);
  const [mesh, setMesh] = React.useState([]);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = React.useState(false);
  const index = React.useRef(0)
  const [defTargetSelected, setDefTargetSelected] = React.useState("")
  const [type, setType] = React.useState("");
  const [openNotesModal, setOpenNotesModal] = React.useState(false);
  const [treeView, setTreeView] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenNotesModal(false);
    setOpenNotesModalEdit(false);
    setOpenMeshesModal(false);
    setOpenMeshesModalEdit(false);
    setTreeView(false)
  }


  //Vérification des valeurs saisies
  const validation = () => {
    if (newStudy.name && targets.length > 0 && patientList.length > 0 ) {
      if(type === "Evaluation" && notes.length <= 0){
        setErrorMessage('Veuillez renseigner toutes les informations nécessaires! (des Notes)')
        return false;
      }
      return true;
    }
    else {
      setErrorMessage('Veuillez renseigner toutes les informations nécessaires!')
      return false;
    }
  }


// Gestion de déplacement des élements experts (glissage de souris)
  const handleOnDragEnd = (result) => {
    if(result.destination){
      const expertId = result.source.droppableId;
      var studyClone = {...newStudy};
      var expertIndex = studyClone.experts.findIndex((exp)=> exp.id.toString() === expertId.toString());
  
      const items = Array.from(studyClone.experts[expertIndex].patients);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      
      studyClone.experts[expertIndex] = {...studyClone.experts[expertIndex], patients: items}
      setnewStudy(studyClone);
    }

   // setpatientList(items);
  }

  //Gestion de selection (dans la liste de selection) des experts et des patients
  const handleChangeMultiple = (event, select) => {
    var cibleObjects = []
    const { options } = event.target;
    const experts_ = [];
    const patients_ = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        if (select === "expert") {
          experts_.push({id:options[i].value, patients:patientList});
        }
        if (select === "patient") {
          patients_.push({ identifiant: options[i].value });
        }
        cibleObjects.push(targets[options[i].value]);
      }
    }
    if (select === "expert") {
      setExpertName(experts_);
      setnewStudy({ ...newStudy, experts: experts_ });
    }
    if (select === "patient") {
      setpatientList(patients_);
      var expertsClone = [...newStudy.experts]
      var expertsFinal =  expertsClone.map((exp)=>
        ({...exp, patients:patients_})
      );
      setnewStudy({ ...newStudy, patients: patients_ , experts:expertsFinal});
    }

  };


  //Gestion de changement de mode de vue (peeling ou cerveau)
  const handleViewModeChange = (event, exp, pat) => {
    const value = event.target.value;
    var studyClone = {...newStudy};
    var expertClone = {...exp};
    var patient = expertClone.patients.find(pat_=>pat_.identifiant === pat.identifiant);
    patient.viewMode = value;
    studyClone.experts.find(e=>e.id === expertClone.id).viewMode = value;
    setnewStudy(studyClone)
  }


//Gestion de type d'étude soit Pointage ou Evaluation
  const typeSelectHandleChange = (e) => {
    const newValue = e.target.value;
    setType(newValue);
    setnewStudy({ ...newStudy, type: newValue });

  }

  //Style de la liste TreeList (des experts -> patients)
  const getItemStyle = (isDragging, draggableStyle) => ({
    // Change styles based on drag state
    background: isDragging ? 'lightblue' : 'white',
    ...draggableStyle,
    marginLeft: isDragging ? "-60vh" : void (0)
  });

  // Styling the droppable container
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: 16,
  });

  const delay = ms => new Promise(res => setTimeout(res, ms));
  React.useEffect(() => {

    const { access, is_staff } = user || {};

    // Tous les experts
    !access && !is_staff ? navigate("/admin/login") : void (0);
   
    axios.get(process.env.REACT_APP_API + '/api/experts/', {
      headers: {
        Authorization: `Bearer ${access}`
      }
    },).then(
      (response) => {        
        setExperts(response.data.sort((a,b)=> a.user.first_name.localeCompare(b.user.first_name)))
      }
    ).catch((error) => { console.log(error); })
    
    // Tous les patients
    axios.get(process.env.REACT_APP_API + '/api/patients/', {
      headers: {
        Authorization: `Bearer ${access}`
      }
    },).then(
      (response) => {
        setPatients(response.data.sort((a, b) => a.index - b.index))
      }
    ).catch((error) => { console.log(error); })

    hideLoader();
  }, []);

  // L'ajout de l'étude dans la base de données
  const submitHandle = async () => {
    const { access } = user || {};
    var final_experts = newStudy.experts;
    final_experts.map((exp, i)=>{
      const ordered_patients = exp.patients.map((patient, i) => ({ ...patient, index: i }));
      final_experts[i].patients = ordered_patients
    });


    if (validation()) {
      const study = {
        ...newStudy,
        patients: patientList,
        targets: targets,
        meshes: meshes,
        notes: notes,
        listSeq: listSeq,
      }
      await axios.post(process.env.REACT_APP_API + '/api/new-study/', study, {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie('csrftoken')
        }
      },).then(
        (response) => {
          setSuccessMessage("Etude ajouté avec succes!")
          setErrorMessage('')
        }
      ).catch((error) => {
        console.log(error);
        setErrorMessage("Une étude avec le même nom existe déja");
      })

      await delay(3000);
      setSuccessMessage("")
    }

  }

  const handleChange = (event) => {
    event.stopPropagation();

    const { name, value } = event.target;
    setnewStudy({ ...newStudy, [name]: value });
  }

  // Gestion d'ajout de nouvelle cible, note et mesh
  const handleTarget = (event, type) => {
    event.stopPropagation();
    const { name, value } = event.target;

    if (type === "note") {
      setNote({ ...note, [name]: value });

    }
    if (type === "target") {
      setTarget({ ...target, [name]: value });
    }
    if (type === "mesh") {
      setMesh({ ...mesh, [name]: value });
    }

  }

  return (

    <div style={{ backgroundColor: "#0e2852", height: "100vh" }}>
      <Navbar isConnected />
      <Grid container style={{ marginTop: "14vh" }}>
        <Grid item xs={1.25} />
        <Grid item xs={2} style={{ marginTop: "20px", paddingRight: "20px", paddingLeft: "20px" }}>
          <Box style={{ marginLeft: "40%" }}>
            <IconButton sx={{ color: '#FFFF' }} onClick={() => navigate("/admin/studies")}  >
              <ArrowBack fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={5} >
          <Box style={{
            overflowY: "scroll", backgroundColor: "white", borderRadius: "15px",
            paddingTop: "50px", paddingLeft: "10vh", maxHeight: "60vh", paddingBottom: "20px"
          }} className='scroll'>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Nom d'étude:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newStudy.name || ''}
                  name="name" onChange={(e) => { handleChange(e) }} />
              </Grid>

            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }} >
              <Grid item xs={4}>
                <Typography className="formText">Description:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField multiline size='small' style={{ width: '35vh' }} value={newStudy.description || ''}
                  name="description" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Type:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select size='small' style={{ width: '35vh' }} value={type}
                  name="type" onChange={(e) => { typeSelectHandleChange(e) }}>
                  <MenuItem key={0} value={"Pointage"}>Pointage</MenuItem>
                  <MenuItem key={1} value={"Evaluation"}>Evaluation</MenuItem>
                </Select>
              </Grid>

            </Grid>
            {
              type === 'Evaluation' ?
                <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
                  <Grid item xs={4}>
                    <Typography className="formText">Notes:</Typography>
                  </Grid>
                  <Grid item container xs={8} direction={"column"}>
                    {notes.map((note_, i) => {
                      return (
                        <Grid item container xs={1}>
                          <Box sx={{ height: "6vh", border: '0.01vh solid #CECECE', marginBottom: '0.8vh', borderRadius: "0.5vh", display: "flex", width: "80%" }}>
                          <Typography style={{ marginLeft: "1vh", position: "relative", marginTop: '1.5%', width: "80%", fontSize:"1.4vh" }}>{note_.value + (note_.label ? ": " + (
                          note_.label.length < 70 ? note_.label :
                          note_.label.substring(0, 70) + "..."
                        ) : "")}</Typography>
                           <Button
                              onClick={() => {
                                setNote(note_);
                                setOpenNotesModalEdit(true);
                              }}
                              width="2px" style={{ maxWidht: "10px" }} ><FaEdit /> </Button>
                            <Button
                              onClick={() => {
                                setNotes(notes.filter((note) => note.label !== note_.label));
                              }}
                              style={{ maxWidht: "10px" }}><FaTrash style={{ minWidht: "10px" }} />
                            </Button>
                            <Modal

                              open={openNotesModalEdit}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Grid className="formLine" item container style={{ marginBottom: "25px", width: "30vh" }} >
                                  <Grid item container xs={4}>
                                    <Typography className="formText">Description de note:</Typography>
                                  </Grid>
                                  <Grid item container xs={8}>
                                    <TextField size='small' style={{ width: '35vh' }} value={note.label || ''}
                                      name="label" onChange={(e) => {
                                        const { name, value } = e.target;
                                        setNote({ ...note, [name]: value });
                                      }} />
                                  </Grid>
                                </Grid>
                                <Grid className="formLine" item container xs={12} style={{ width: "30vh" }}>
                                  <Grid item xs={4}>

                                    <Typography className="formText">Valeur:</Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <TextField type='number' InputLabelProps={{
                                      shrink: true,
                                    }} size='small' style={{ width: '35vh' }} value={note.value || ''}
                                      name="value" onChange={(e) => {
                                        e.stopPropagation();
                                        const { name, value } = e.target;
                                        setNote({ ...note, [name]: value });
                                      }} />
                                  </Grid>
                                </Grid>
                                <Grid item container>
                                  <Typography sx={{color:"red"}}>
                                    {notesErrorMessage??notesErrorMessage}
                                  </Typography>
                                  <Button onClick={(e) => {
                                     const n1 = notes.find(n => note.label === n.label )
                                     const n2 = notes.find(n => note.value === n.value )
                                      if (!note.label) {
                                       setNotesErrorMessage("Informations incomplètes")
                                     }
                                     if ((n1 && n1 !== -1)&&(n2 && n2 !== -1)) {
                                       setNotesErrorMessage("Une note avec les mêmes informations est dèja existante!")
                                     }
                                     else  {
                                      let newNotes = notes;
                                    const index = newNotes.findIndex((note_) => note_.index === note.index);
                                    newNotes[index] = note;
                                    setNotes(newNotes);
                                    setNotesErrorMessage(null)
                                    setOpenNotesModalEdit(false)
                                     }
                                    

                                  }}
                                    variant="contained" style={{ float: "right", marginTop: "20px" }}>Modifier la note</Button>

                                </Grid>
                              </Box>
                            </Modal>
                          </Box>
                        </Grid>


                      );
                    })}

                    <Grid item container xs={1}>
                      <Button onClick={() => { setNote({}); setOpenNotesModal(true) }}><FaPlusSquare size={"3.2vh"} /></Button>
                    </Grid>
                  </Grid>
                  <Modal
                    open={openNotesModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                        <Grid item container xs={4}>
                          <Typography className="formText">Description de note:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField size='small' style={{ width: '35vh' }} value={note.label || ''}
                            name="label" onChange={(e) => { handleTarget(e, "note") }} />
                        </Grid>
                      </Grid>
                      <Grid className="formLine" item container style={{ width: "35vh" }}>
                        <Grid item xs={4}>

                          <Typography className="formText">Valeur:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField type='number' InputLabelProps={{
                            shrink: true,
                          }} size='small' style={{ width: '35vh' }} value={note.value || ''}
                            name="value" onChange={(e) => { handleTarget(e, "note") }} />
                        </Grid>
                      </Grid>
                      <Grid item container  >
                        <Grid item xs={5} />
                        <Grid item container xs={7}>
                          <Typography sx={{color:"red"}}>
                            {notesErrorMessage??notesErrorMessage}
                          </Typography>

                          <Button onClick={() => {
                            const n1 = notes.find(n => note.label === n.label )
                            const n2 = notes.find(n => note.value === n.value )
                             if (!note.label) {
                              setNotesErrorMessage("Informations incomplètes")
                            }
                            if ((n1 && n1 !== -1)&&(n2 && n2 !== -1)) {
                              setNotesErrorMessage("Une note avec les mêmes informations est dèja existante!")
                            }
                            else  {
                              const _notes = notes;
                              _notes.push({ ...note, index: _notes.length });
                              setNotes(_notes)
                              setNote({})
                              setNotesErrorMessage(null)
                            }
                          }}
                            variant="contained" style={{ float: "right", marginTop: "20px" }}>Ajouter la note</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>

                </Grid> : void (0)
            }
           
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Patients:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  multiple
                  native
                  value={patientList.identifiant}
                  style={{ width: "35vh" }}
                  // @ts-ignore Typings are not considering `native`
                  onChange={(e) => handleChangeMultiple(e, "patient")}
                  label="Native"
                  inputProps={{
                    id: 'select-multiple-native',
                  }}
                >
                  {patients ? patients.map((patient) => (
                    <option key={patient.identifiant} value={patient.identifiant}>
                      {patient.identifiant}
                    </option>
                  )) : null}
                </Select>
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Cibles:</Typography>
              </Grid>
              <Grid item xs={8}>
                {targets.map((target_, i) => {
                  return (
                    <Box sx={{ height: "6vh", border: '0.01vh solid #CECECE', marginBottom: '0.8vh', borderRadius: "0.5vh", display: "flex", width: "80%" }}>
                      <Typography style={{ marginLeft: "1vh", position: "relative", marginTop: '1.5%', width: "80%", fontSize:"1.4vh" }}>{target_.name + (target_.description ? ": " + (
                        target_.description.length < 40 ? target_.description :
                          target_.description.substring(0, 40) + "..."
                      ) : "")}</Typography>
                      <Button
                        onClick={() => {
                          index.current = i;
                          setTarget(target_);
                          const newDefTarget = targets.find((tar) => tar.name === target_.defTarget) ? targets.find((tar) => tar.name === target_.defTarget).index : "";
                          setDefTargetSelected(
                            newDefTarget
                          );
                          setOpenEdit(true);
                        }}
                        width="2px" style={{ maxWidht: "10px" }} ><FaEdit /> </Button>
                      <Button
                        onClick={() => {
                          setTargets(targets.filter((target) => target.name !== target_.name));
                        }}
                        style={{ maxWidht: "10px" }}><FaTrash style={{ minWidht: "10px" }} />
                      </Button>
                      <Modal

                        open={openEdit}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px", width: "30vh" }} >
                            <Grid item xs={4}>
                              <Typography className="formText">Nom de cible:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField size='small' style={{ width: '35vh' }} value={target.name || ''}
                                name="name" onChange={(e) => {
                                  const { name, value } = e.target;
                                  setTarget({ ...target, [name]: value });
                                }} />
                            </Grid>
                          </Grid>
                          <Grid className="formLine" item container xs={12} style={{ width: "30vh" }}>
                            <Grid item xs={4}>

                              <Typography className="formText">Description:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField multiline size='small' style={{ width: '35vh' }} value={target.description || ''}
                                name="description" onChange={(e) => {
                                  e.stopPropagation();
                                  const { name, value } = e.target;
                                  setTarget({ ...target, [name]: value });
                                }} />
                            </Grid>
                          </Grid>
                          <Grid className="formLine" item container xs={12} style={{ width: "30vh" }}>
                            <Grid item xs={4}>
                              <Typography className="formText" >Cible par défaut:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Select
                                name="defTarget"
                                style={{ width: "35vh", height: "40px" }}
                                value={defTargetSelected}
                                // @ts-ignore Typings are not considering `native`
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  const _targets = targets;
                                  const newDefTarget = value
                                  setDefTargetSelected(newDefTarget);
                                  const index = _targets.findIndex((tar) => tar.index === target.index);
                                  const newTarget = _targets.find((tar) => tar.index === value);
                                  setTarget({ ...target, [name]: newTarget ? newTarget.name : "" });
                                  setTargets(_targets)

                                }}
                                label="Native"
                                inputProps={{
                                  id: 'select-multiple-native',
                                }}
                              >
                                <MenuItem value=""  >
                                  <em>None</em>
                                </MenuItem>
                                {targets ? targets.map((target) => (
                                  <MenuItem value={target.index}>{target.name}</MenuItem>
                                )) : null}
                              </Select>
                              <Typography sx={{color:"red"}}>
                                  {ciblesErrorMessage??ciblesErrorMessage}
                              </Typography>
                              <Button onClick={(e) => {
                                // let exist = targets.find((tar)=>tar.name === target.name);

                                const n1 = targets.find(t => target.name === t.name )
                                if (!target.name) {
                                  setCiblesErrorMessage("Informations incomplètes")
                              }
                              if ((n1 && n1 !== -1)) {
                                setCiblesErrorMessage("Une cible avec les mêmes informations est dèja existante!")
                              }
                              else  {
                                let newTargets = targets;
                                const index = target.index;
                                const _index = targets.findIndex((tar) => tar.defTarget === targets[index].name);
                                const __index = targets.find((tar) => target.defTarget === tar.name) ? targets.find((tar) => target.defTarget === tar.name).index : "";
                                if (target.index > __index || __index === "") {
                                  newTargets[index] = target;
                                  if (_index > -1) {
                                    newTargets[_index].defTarget = target.name;
                                  }
                                  setTargets(newTargets);
                                }
                                else {
                                  alert("Choisir cible précedente ")
                                }
                                setCiblesErrorMessage(null)
                                setOpenEdit(false)

                         }
                        
                              
                    
                              }}
                                variant="contained" style={{ float: "right", marginTop: "20px" }}>Modifier la cible</Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Modal>
                    </Box>


                  );
                })}
              
                <Button onClick={() => { setTarget({}); setOpen(true) }}><FaPlusSquare size={"3.2vh"} /></Button>

              </Grid>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px", width: "30vh" }} >
                    <Grid item xs={4}>
                      <Typography className="formText">Nom de cible:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField size='small' style={{ width: '35vh' }} value={target.name || ''}
                        name="name" onChange={(e) => { handleTarget(e, "target") }} />
                    </Grid>
                  </Grid>
                  <Grid className="formLine" item container xs={12} style={{ width: "30vh" }}>
                    <Grid item xs={4}>

                      <Typography className="formText">Description:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField multiline size='small' style={{ width: '35vh' }} value={target.description || ''}
                        name="description" onChange={(e) => { handleTarget(e, "target") }} />
                    </Grid>
                  </Grid>
                  <Grid className="formLine" item container xs={12} style={{ width: "30vh" }}>
                    <Grid item xs={4}>
                      <Typography className="formText" >Cible par défaut:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        name="defTarget"
                        style={{ width: "35vh", height: "40px" }}
                        value={target.name}
                        // @ts-ignore Typings are not considering `native`
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setTarget({ ...target, [name]: value })
                        }}
                        label="Native"
                        inputProps={{
                          id: 'select-multiple-native',
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {targets ? targets.map((target, i) => (
                          <MenuItem value={target.name}>{target.name}</MenuItem>
                        )) : null}
                      </Select>
                      <Typography sx={{color:"red"}}>
                        {ciblesErrorMessage ?? ciblesErrorMessage }
                      </Typography>
                      <Button onClick={() => {
                         const n1 = targets.find(t => target.name === t.name )
                          if (!target.name) {
                            setCiblesErrorMessage("Informations incomplètes")
                         }
                         if ((n1 && n1 !== -1)) {
                           setCiblesErrorMessage("Une cible avec les mêmes informations est dèja existante!")
                         }
                         else  {
                          const _targets = targets;
                          _targets.push({ defTarget: "", ...target, index: _targets.length });
                          setTargets(_targets)
                          setTarget({ ...target, defTarget: target.defTarget });
                          setCiblesErrorMessage(null)
                         }
                        
                   
                      }}
                        variant="contained" style={{ float: "right", marginTop: "20px" }}>Ajouter la cible</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

            </Grid>

         
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Experts:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  multiple
                  native
                  style={{ width: "35vh" }}
                  value={expertName.id}
                  // @ts-ignore Typings are not considering `native`
                  onChange={(e) => handleChangeMultiple(e, "expert")}
                  label="Native"
                  inputProps={{
                    id: 'select-multiple-native',
                  }}
                >
                  {experts ? experts.map((expert) => (
                    <option key={expert.user.id} value={expert.user.id}>
                      {expert.user.first_name + " " + expert.user.last_name}
                    </option>
                  )) : null}
                </Select>
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Meshes supplémentaires:</Typography>
              </Grid>
              <Grid item container xs={8} direction={"column"}>
                {meshes.map((mesh_, i) => {
                  return (
                    <Grid item container xs={1}>
                      <Box sx={{ height: "6.5vh", border: '0.01vh solid #CECECE', marginBottom: '0.8vh', borderRadius: "0.5vh", display: "flex", width: "80%" }}>
                        <Typography style={{ marginLeft: "1vh", position: "relative", marginTop: '1.5%', width: "80%", fontSize:"1.4vh" }}>{mesh_.fileName + (mesh_.sem ? ": " + (
                        mesh_.sem.length < 70 ? mesh_.sem :
                        mesh_.sem.substring(0, 70) + "..."
                      ) : "")}</Typography>
                        <div style={{ width: "3vh", backgroundColor: mesh_.color }}>

                        </div>
                        <IconButton
                          onClick={() => {
                            setMesh(mesh_);
                            setOpenMeshesModalEdit(true);
                          }}
                          style={{ maxWidht: "5px" }} ><FaEdit /> </IconButton>
                        <IconButton
                          onClick={() => {
                            setMeshes(meshes.filter((mesh) => mesh.sem !== mesh_.sem));
                          }}
                          style={{ maxWidht: "10px" }}><FaTrash style={{ minWidht: "10px" }} />
                        </IconButton>
                        <Modal

                          open={openMeshesModalEdit}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <Grid className="formLine" item container style={{ marginBottom: "25px", width: "30vh" }} >
                              <Grid item container xs={4}>
                                <Typography className="formText">Sémantique:</Typography>
                              </Grid>
                              <Grid item container xs={8}>
                                <TextField size='small' style={{ width: '35vh' }} value={mesh.sem || ''}
                                  name="sem" onChange={(e) => {
                                    const { name, value } = e.target;
                                    setMesh({ ...mesh, [name]: value });
                                  }} />
                              </Grid>
                            </Grid>
                            <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                              <Grid item container xs={4}>
                                <Typography className="formText">Couleur:</Typography>
                                <div style={{ height: "8vh", width: "9vh", backgroundColor: color }}>

                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <HexColorPicker style={{ width: "35vh", height: "20vh" }} color={color} onChange={setColor} />

                              </Grid>
                            </Grid>
                            <Grid className="formLine" item container style={{ marginBottom: "25px", width: "30vh" }} >
                              <Grid item container xs={4}>
                                <Typography className="formText">Nom du fichier:</Typography>
                              </Grid>
                              <Grid item container xs={8}>
                                <TextField size='small' style={{ width: '35vh' }} value={mesh.fileName || ''}
                                  name="fileName" onChange={(e) => {
                                    const { name, value } = e.target;
                                    setMesh({ ...mesh, [name]: value });
                                  }} />
                              </Grid>
                            </Grid>
                            <Grid item container>
                              <Typography sx={{color: "red"}}>
                                {meshesErrorMessage??meshesErrorMessage}
                              </Typography>
                              <Button onClick={(e) => {
                                // let exist = targets.find((tar)=>tar.name === target.name);
                                const n1 = meshes.find(m => mesh.sem === m.sem )
                                const n2 = meshes.find(m => mesh.fileName === m.fileName )
                                if (!mesh.sem && !mesh.fileName) {
                                  setMeshesErrorMessage("Informations incomplètes")
                              }
                              if ((n1 && n1 !== -1) && (n2 && n2 !== -1)) {
                                setMeshesErrorMessage("Un mesh avec les mêmes informations est dèja existant!")
                              }
                              else  {
                              
                                let newMeshes = meshes;
                                const index = newMeshes.findIndex((mesh_) => mesh_.index === mesh.index);
                                newMeshes[index] = { ...mesh, color: color };
                                setMeshes(newMeshes);
                                setMeshesErrorMessage(null)
                                setOpenMeshesModalEdit(false)

                         }
                              }}
                                variant="contained" style={{ float: "right", marginTop: "20px" }}>Modifier le mesh</Button>

                            </Grid>
                          </Box>
                        </Modal>
                      </Box>
                    </Grid>


                  );
                })}

                <Grid item container xs={1}>
                  <Button onClick={() => { setMesh({}); setOpenMeshesModal(true) }}><FaPlusSquare size={"3.2vh"} /></Button>
                </Grid>
              </Grid>
              <Modal
                open={openMeshesModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>

                  <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                    <Grid item container xs={4}>
                      <Typography className="formText">Sémantique:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField size='small' style={{ width: '35vh' }} value={mesh.sem || ''}
                        name="sem" onChange={(e) => { handleTarget(e, "mesh") }} />
                    </Grid>
                  </Grid>
                  <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                    <Grid item container xs={4}>
                      <Typography className="formText">Couleur:</Typography>
                      <div style={{ height: "8vh", width: "9vh", backgroundColor: color }}>

                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <HexColorPicker style={{ width: "35vh", height: "20vh" }} color={color} onChange={setColor} />

                    </Grid>
                  </Grid>
                  <Grid className="formLine" item container style={{ marginBottom: "25px", width: "35vh" }} >
                    <Grid item container xs={4}>
                      <Typography className="formText">Nom du fichier:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField size='small' style={{ width: '35vh' }} value={mesh.fileName || ''}
                        name="fileName" onChange={(e) => { handleTarget(e, "mesh") }} />
                    </Grid>
                  </Grid>

                  <Grid item container  >
                    <Grid item xs={5} />
                    <Grid item container xs={7}>

                      <Typography sx={{color:"red"}}>
                        {meshesErrorMessage?? meshesErrorMessage}
                      </Typography>

                      <Button onClick={() => {
                         const n1 = meshes.find(m => mesh.sem === m.sem )
                         const n2 = meshes.find(m => mesh.fileName === m.fileName )
                         if (!mesh.sem && !mesh.fileName) {
                           setMeshesErrorMessage("Informations incomplètes")
                       }
                       if ((n1 && n1 !== -1) && (n2 && n2 !== -1)) {
                         setMeshesErrorMessage("Un mesh avec les mêmes informations est dèja existante!")
                       }
                       else  {
                       
                        const _meshes = meshes;
                          _meshes.push({ ...mesh, index: _meshes.length, color: color });
                          setMeshes(_meshes);
                          var studyClone = {...newStudy};
                          var expertsClone =[...studyClone.experts];
                           expertsClone.map((expClone,i)=>
                          {
                            const newPatients = expClone.patients.map((patient)=>
                          ({...patient, meshes:_meshes})
                          );
                          expertsClone[i].patients = newPatients;
                        }
                          );
                          studyClone.experts = expertsClone;
                          setnewStudy(studyClone)
                          setMesh({})
                         setMeshesErrorMessage(null)
                         setOpenMeshesModalEdit(false)

                  }
                        if (mesh.sem) {
                         
                        }
                      }}
                        variant="contained" style={{ float: "right", marginTop: "20px" }}>Ajouter le mesh</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

            </Grid>
            <FormControlLabel key={"s1"} control={<Checkbox onChange={(event) => {
                                                setListSeq(!listSeq)
                                              }} checked={listSeq} />} label={<Typography style={{
                                                fonFamily: 'Zen Antique Soft',
                                                fontSize: '1.4vh'
                                              }}>Activer le parcours séquentiel pour la liste patients/cibles</Typography>}></FormControlLabel>
            <Grid item container>
              <Button onClick={() => {
                const new_patientList = patientList.map((p) => {
                  if (!Object.hasOwn(p, "meshes")) {
                    return ({
                      ...p, meshes: meshes
                    })
                  }
                  else {
                    return (p)
                  }
                }
                );
                setpatientList(new_patientList)
                setTreeView(true);

              }}>MODIFIER L'ORDRE DES PATIENTS OU L'AFFICHAGE DES MESHES</Button>
            </Grid>
            <Modal
              open={treeView}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: "80vh", flexGrow: 1, maxWidth: "100%", overflowY: 'auto' }}
                >

                  {<p>Chargement ...</p> || newStudy.experts.sort((a,b)=> a.user.first_name.localeCompare(b.user.first_name)).map((exp, k) => {
                  
                    const exp_ = experts.find(expert => expert.user.id.toString() 
                    === exp.id.toString());
                  
                    
                   
                    if(exp_){
                      
                      const expert = exp_.user;
                      return (
                        <TreeItem nodeId={expert.id} label={expert.first_name + " " + expert.last_name}>
                          <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId={expert.id}>
                              {(provided, snapshot) =>
                              (
                                <List {...provided.droppableProps} ref={provided.innerRef} component="nav" style={getListStyle(snapshot.isDraggingOver)} >
  
                                  {(exp.patients.map((pat, i) => {
                                    return (
                                      <Draggable key={pat.identifiant} draggableId={pat.identifiant} index={i}>
                                        {(provided, snapshot) => (
                                          <div >
                                             <hr/>
                                            <Grid key={pat.identifiant}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                              item container >
                                              <Grid item xs={3}>
                                                <ListItemText key={i} style={{ paddingLeft: '1vh' }} primary={<Typography style={{
                                                  fonFamily: 'Zen Antique Soft',
                                                  fontSize: '1.7vh'
                                                }}
                                                >  <OpenWith style={{ width: "1.9vh", marginRight: "1vh", position: "relative", top: "0.6vh" }} />{pat.identifiant} </Typography>}
                                                />
                                              </Grid>
                                              <Grid key={i+"j"} item container xs={9} >
                                                <Grid item container xs={2} style={{position:"relative",top:'25%'}}>
                                                <Typography variant='body1' style={{fontSize:"1.6vh", fontWeight:"bolder"}}>Vue:</Typography>
                                                </Grid>
                                                <Grid item container xs={10}>
                                                <FormControl >
                                              <RadioGroup
                                                row
                                                defaultValue=""
                                                onChange={(e)=>handleViewModeChange(e,exp, pat)}
                                                value={pat.viewMode}
                                              >
                                                <FormControlLabel  value="cerveau" control={<Radio />} label={<Typography style={{fontSize:"1.5vh"}}>Cerveau</Typography>} />
                                                <FormControlLabel value="peeling" control={<Radio />} label={<Typography style={{fontSize:"1.5vh"}}>Peeling</Typography>} />
                                              </RadioGroup>
                                            </FormControl>

                                                </Grid>
                                              </Grid>
                                              <Grid item container>
                                                <Grid item container xs={12}>
                                                  <Typography style={{fontSize:"1.6vh", fontWeight:"bolder"}} variant='body1'>Meshes supplémentaires:</Typography>
                                                </Grid>
                                              { pat.meshes? pat.meshes.map((mesh, j) =>
                                              <Grid item container xs={6}>
                                                <FormControlLabel key={i+""+j} control={<Checkbox onChange={(event) => {
                                                  event.stopPropagation();
                                                  event.preventDefault();
  
                                                  setnewStudy(prevStudy => {
                                                    var studyClone = {...prevStudy};
                                                    var expertsClone = [...studyClone.experts];
                                                    var patientsClone = [...expertsClone[k].patients];

                                                    var finalPatients = patientsClone.map(patient => {
                                                      if (patient.identifiant === pat.identifiant) {
                                                        const updatedMeshes = [...patient.meshes];
                                                        updatedMeshes[j] = {
                                                          ...updatedMeshes[j],
                                                          showMesh: !updatedMeshes[j].showMesh,
                                                        };
                                                        return { ...patient, meshes: updatedMeshes };
                                                      }
                                                      return patient;
                                                    });

                                                    studyClone.experts[k].patients =  finalPatients;

                                                    return  studyClone;

                                                  
                                                  });

                                                }} checked={mesh.showMesh} />} label={<Typography style={{
                                                  fonFamily: 'Zen Antique Soft',
                                                  fontSize: '1.4vh'
                                                }}>{mesh.sem}</Typography>}></FormControlLabel>
                                                </Grid>
                                              ): void(0)}
                                              </Grid>
  
                                            </Grid>
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Draggable>)
                                  }))}
                                  {provided.placeholder}
                                </List>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </TreeItem>)
                    
                    }


                  })}
                </TreeView>
              </Box>
            </Modal>
           
            {errorMessage ?
              (<Alert severity="error" className="alert">{errorMessage}</Alert>) : null}
            {successMessage ?
              (<Alert severity="success" className="alert">{successMessage}</Alert>) : null}

          </Box>
          <Button style={{ float: "right" }} variant="contained" onClick={() => {
            submitHandle()
          }}>
            <Typography variant='body'>Ajouter l'étude</Typography></Button>
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </div>

  );
}

const mapStateToProps = state => ({
  user: state.user,
});
/*const mapStateToProps = state => ({
    
  });
  
  const mapDispatchToProps = dispatch => ({
    
  });*/
//  export default connect(mapStateToProps, mapDispatchToProps)(ExpertAdmin);
export default connect(mapStateToProps, null)(NewStudy);