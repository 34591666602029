import patientsTypes from './patients-types';

export const setPatients = (patients) => ({
    type: patientsTypes.SET_PATIENTS,
    payload: patients,
});

export const addPatient = (patient) => ({
    type: patientsTypes.ADD_PATIENT,
    payload: patient,
});

