  import * as React from 'react';
  import {TextField, Grid, Typography, Button, Box, Alert}  from '@mui/material';
  import {connect} from 'react-redux';
  import { useNavigate } from 'react-router';
  import axios from 'axios';
  import { setUser } from '../redux/user/user-action';
  import { setTargets } from '../redux/targets/targets-action';
import Navbar from '../Components/Navbar';







  const LoginExpert = ({setUser, user, hideLoader, showLoader}) => {
    const [logged, setLogged] = React.useState(null);
    const [username, setUsername] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    let navigate = useNavigate();
  

    React.useEffect(() => {
      const {access, is_staff} = user;
      access&&!is_staff? navigate("/studies"): void(0)
      hideLoader();
    
  }, [navigate, hideLoader, showLoader]);

    const login = (e) => {
      e.preventDefault();
      e.stopPropagation()
    
      axios.post(process.env.REACT_APP_API+'/api/expert/login/',

      {
        username: username,
        password: password
      }
      // data = request.body;
      // username = data['username']
      )
      .then((response) => {
        setUser(response.data)
        navigate('/studies');
        setLogged(true);
      }, (error) => {
        console.log(error);
        setLogged(false);
      });
      };
  


    function handleChange(e) {
      e.preventDefault();
      const {value, name} = e.target;
      name === "password" ?setPassword(value): void(0);
      name === "username" ?setUsername(value): void(0);
    }
    return (
      <div style={{backgroundColor: "#85B2D9", height:"100vh" }}>
        <Navbar isExpert/>
        <Grid container>
          <Grid item xs={4.2}>
          </Grid>
          <Grid container item xs={3.5}  style={{
                  backgroundColor: 'white',
                  borderRadius: '4%',
                  paddingBottom: '30px',
                  marginTop:"8vh"}}>
          <Grid item xs={1.5}></Grid>
          <Grid item xs= {9}>
          <form onSubmit={login}>
          <img style={{width:"16vh", display:'flex', marginLeft:'auto', marginRight:'auto', marginBottom:"5vh"}}  alt="logo" src={process.env.REACT_APP_STATIC+"images/syneika.jpg"}/>
          <Typography style={{ fontFamily:'Kanit', fontSize:"2vh", marginBottom:"0.6vh"}}>
              Nom d'utilisateur
          </Typography>
          <TextField size='small'  onChange={handleChange} fullWidth style={{backgroundColor:'white', marginBottom:'2vh'}} 
          name='username' label={
            <Typography style={{ fontFamily:'Kanit'}}>
              Nom d'utilisateur
          </Typography>
          } variant="outlined"  />
          <Typography style={{ fontFamily:'Kanit', fontSize:"2vh", marginBottom:"0.6vh"}}>
            Mot de Passe
          </Typography>
          <TextField size='small' fullWidth style={{backgroundColor:'white'}}
          type="password" name='password' onChange={handleChange} label={
            <Typography style={{ fontFamily:'Kanit'}}>
              Mot de passe
          </Typography>
          } variant="outlined" />
          <Box pt={2}>
          {logged === false ? <Alert className='errorAlert' severity="error">Votre mot de passe est incorrect!</Alert>:null }
          <Button fullWidth
          type="submit"
          style={{
            fontSize:'18px',
            fontFamily:'Kanit',
            backgroundColor: "#66A4D5",
            marginTop: '10px',
          }} onSubmit={(e)=>{login(e)}} variant="contained">
          <Typography style={{color:"white", fontSize:"20px", fontFamily:'Kanit'}}>
          CONNEXION
          </Typography>

            </Button>
          </Box>
          </form>
          </Grid>
          <Grid item xs= {1.5}></Grid>
          </Grid>
          </Grid>
          </div>
      
    );
  }

  const mapStateToProps = state => ({
    user: state.user,
  });

  const mapDispatchToProps = dispatch => ({
      setUser: (user) => dispatch(setUser(user)),
      setTargets: (targets)=> dispatch(setTargets(targets)),
      
  });
  export default connect(mapStateToProps, mapDispatchToProps)(LoginExpert);

