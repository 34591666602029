import patientTypes from './patient-types';

export const setPatient = (patient) => ({
    type: patientTypes.SET_PATIENT,
    payload: patient,
});

export const modifyPatient = patient => ({
    type: patientTypes.SET_VALUES,
    payload: patient
});

export const setFiles = patient => ({
    type: patientTypes.SET_FILES,
    payload: patient
});

export const clearPatient = () => ({
    type: patientTypes.CLEAR_PATIENT,
});

