
import {connect} from 'react-redux';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import Background from '../Background';
import { jsPDF } from "jspdf";
import $ from 'jquery';
import 'jspdf-autotable'
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { ArrowBack, Download } from '@mui/icons-material';
import 'jspdf-autotable';
import "./style.css";
import { getCookie } from '../Utils';



const ExportStudies = ({hideLoader, user}) => {

    const [isLoading, setIsLoading ] = useState(false)
    const [experts, setExperts] = useState([]);
    const [studySelected, setStudySelected] = useState("");
    const [expertSelected, setExpertSelected] = useState("");
    const [studies, setStudies] = useState([]);
    const navigate = useNavigate()
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [expert, setExpert] = useState(null);
    const [study, setStudy] = useState(null);

    useEffect(() => {
      window.addEventListener('error', e => {
          if (e.message === 'ResizeObserver loop limit exceeded') {
              const resizeObserverErrDiv = document.getElementById(
                  'webpack-dev-server-client-overlay-div'
              );
              const resizeObserverErr = document.getElementById(
                  'webpack-dev-server-client-overlay'
              );
              if (resizeObserverErr) {
                  resizeObserverErr.setAttribute('style', 'display: none');
              }
              if (resizeObserverErrDiv) {
                  resizeObserverErrDiv.setAttribute('style', 'display: none');
              }
          }
      });
  }, []);
    
   
    
   
    
    const VirtuosoTableComponents = {
      Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
      )),
      Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
      ),
      TableHead,
      TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
      TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };
    
    function fixedHeaderContent() {
      return (
        <TableRow>
          {columns.map((column) => (
            <React.Fragment key={column.dataKey}>
               <TableCell
              key={column.dataKey}
              variant="head"
              align={column.numeric || false ? 'right' : 'center'}
              style={{ width: column.width, border:"0.1px solid gray", padding:'0 0 0 0', margin:"0 0 0 0"}}
              sx={{
                backgroundColor: 'background.paper',
                
                
              }}
            >
              {column.label}
              { column.columns && (<React.Fragment> 
          {column.columns.map((col)=>(
            <TableCell style={{ width: 100, border:"0.1px solid gray", padding:"10px"  }}  >
            {col.label.length<13 ? col.label : col.label.substring(0, 10) + "..."}
          </TableCell>
          ))}
          </React.Fragment>)
          }
            </TableCell>
         

            </React.Fragment>
          ))}
        </TableRow>
      );
    }
    
    function rowContent(_index, row) {
      return (
        <React.Fragment>
          {columns.map((column) => (
            <React.Fragment>
            <TableCell
              key={column.dataKey}
             align={column.numeric || false ? 'right' : 'center'}
            >
              {row[column.dataKey]}
              { column.columns && (<React.Fragment> 
          {column.columns.map((col)=>(
            <TableCell style={{ width: column.width/column.columns.length, borderBottom:0 }}  >
            { row[col.dataKey]}
          </TableCell>
          ))}
          </React.Fragment>)
          }
            </TableCell>

            </React.Fragment>
            
          ))}
        </React.Fragment>
      );
    }
    

    
  

  const handleSelectStudyChange = async (event) => {
    setIsLoading(true)
    const selectedstudy = event.target.value;
    setStudySelected(selectedstudy);
    const selected_study = studies.find(s=> s.uid === selectedstudy);
    setStudy(selected_study);
    var classicColumn = [{
      width: 80,
      label: 'Identifiant',
      dataKey: 'identifiant',
    },
    {
      width: 100,
      label: 'IRM',
      dataKey: 'irm',
    },
   ]

    if(selected_study.targets && selected_study.targets.length > 0){
      classicColumn.push({
        width: selected_study.targets.length *100,
        label: "Cibles",
        dataKey: "cibles",
        columns: []

      })
      selected_study.targets.map((target)=>{
        classicColumn[classicColumn.length-1].columns.push({
          width: 100,
          label: target.name.toString(),
          dataKey: target.name.toString(),
        })
      })
    
    }

    classicColumn.push( {
      width: 80,
      label: 'Mode de vue',
      dataKey: 'vueMode',
    })

    if(selected_study.meshes_supp && selected_study.meshes_supp.length > 0){
      classicColumn.push({
        width: selected_study.meshes_supp.length*100,
        label: "Meshes supplémentaires",
        dataKey:"suppMesh",
        columns: []
      })
      selected_study.meshes_supp.map((mesh)=>{
        classicColumn[classicColumn.length-1].columns.push({
          width: 100,
          label: mesh.sem,
          dataKey: mesh.sem,
        })
      })
    
    }


    if(selected_study._type === "Evaluation"){
      classicColumn.push({
        width: 120,
        label: 'Note',
        dataKey: 'note',
      })
    }
    setColumns(classicColumn);
    const {access, is_staff} = user || {};
    await axios.post(process.env.REACT_APP_API + '/api/study-experts/', {
      uid: selectedstudy
    }, {
      headers: {
        Authorization: `Bearer ${access}`,
        'X-CSRFToken': getCookie('csrftoken')
      }
    })
      .then(async (response) => {
        const finalExperts = response.data.sort((a,b)=> a.user.first_name.localeCompare(b.user.first_name))
        setExperts(finalExperts);
        // setFilteredExperts(response.data);
        const expert = finalExperts[0];
        setExpertSelected(expert.user.username);
        setExpert(expert)
    await axios.post(process.env.REACT_APP_API + '/api/patients-study-expert/', {
      study: selectedstudy,
      username: expert.user.username
    }, {
      headers: {
        Authorization: `Bearer ${access}`,
        'X-CSRFToken': getCookie('csrftoken')
      }
    })
      .then(async (response) => {
        const patients = response.data;
        let table_studies = [];
        await Promise.all(patients.map(async (patient, i) => {
                      
          var tablestudy = {index: patient.index,
            identifiant:patient.index+2,
            irm: patient.patientId.irm,
        vueMode:patient.viewMode } 
          if(patient.suppMesh.length > 0){
            patient.suppMesh.map((mesh)=>{
              if(mesh.showMesh){
                tablestudy = {...tablestudy, [mesh.meshSupp.sem]: <span style={{color:mesh.meshSupp.color}}> {mesh.meshSupp.sem}</span>}
              }
              else{
                tablestudy = {...tablestudy, [mesh.meshSupp.sem]: <span style={{color:"gray"}}> {mesh.meshSupp.sem}</span>}
              }
            })
          }
          if(true){
             await axios.post(process.env.REACT_APP_API + '/api/get-targets/',
            {
              patient: patient.patientId.identifiant,
              expert: expert.user.username,
              study: selected_study.uid
    
            }
            , {
              headers: {
                Authorization: `Bearer ${access}`,
                'X-CSRFToken': getCookie('csrftoken')
              }
            })
              .then(async (response) => {
                if(response.data.length > 0){
                  response.data.map((target)=>{
                    tablestudy = {...tablestudy, [target.targetId.name]: <span > {"(" + target.x.toString() +","+target.y.toString()+"," + target.z.toString()+")"}</span>, note: target.note?`"${target.note.label}"`:""}
    
                  })
                }
               });
          }
          table_studies.push(tablestudy);
        return tablestudy;
      })).then((table_studies)=>{
        setRows(table_studies.sort((a,b)=>a.index-b.index))
      });
      })

            });

            setIsLoading(false)
  }

  const handleSelectExpertChange = async (event) => {
    setIsLoading(true)
    const {access, is_staff} = user || {};
    const selectedexpert = event.target.value;
    setExpertSelected(selectedexpert);
    setExpert(experts.find(e=>e.user.username === selectedexpert))
    await axios.post(process.env.REACT_APP_API + '/api/patients-study-expert/', {
      study: studySelected,
      username: selectedexpert
    }, {
      headers: {
        Authorization: `Bearer ${access}`,
        'X-CSRFToken': getCookie('csrftoken')
      }
    })
      .then(async (response) => {
        const patients = response.data;
        let table_studies = [];
        await Promise.all(patients.map(async (patient, i) => {
                      
          var tablestudy = {index: patient.index,
            identifiant:patient.index+2,
            irm: patient.patientId.irm,
        vueMode:patient.viewMode} 
          if(patient.suppMesh.length > 0){
            patient.suppMesh.map((mesh)=>{
              if(mesh.showMesh){
                tablestudy = {...tablestudy, [mesh.meshSupp.sem]: <span style={{color:mesh.meshSupp.color}}> {mesh.meshSupp.sem}</span>}
              }
              else{
                tablestudy = {...tablestudy, [mesh.meshSupp.sem]: <span style={{color:"gray"}}> {mesh.meshSupp.sem}</span>}
              }
            })
          }
          if(true){
             await axios.post(process.env.REACT_APP_API + '/api/get-targets/',
            {
              patient: patient.patientId.identifiant,
              expert: selectedexpert,
              study: studySelected
    
            }
            , {
              headers: {
                Authorization: `Bearer ${access}`,
                'X-CSRFToken': getCookie('csrftoken')
              }
            })
              .then(async (response) => {
                if(response.data.length > 0){
                  response.data.map((target)=>{
                    tablestudy = {...tablestudy, [target.targetId.name]: <span > {"(" + target.x.toString() +","+target.y.toString()+"," + target.z.toString()+")"}</span>, note: target.note?target.note.label:""}
    
                  })
                }
               });
          }
          table_studies.push(tablestudy);
        return tablestudy;
      })).then((table_studies)=>{
        setRows(table_studies.sort((a,b)=>a.index-b.index))
      });
      })

      setIsLoading(false)

  }


    useEffect( ()=>{
      setIsLoading(true)
      
      const fetchData = async () =>{
        const {access, is_staff} = user || {};
        !access&&!is_staff?navigate("/admin/login"):void(0);
        var filteredStudies;
        await axios.get(process.env.REACT_APP_API + '/api/all-studies/', {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie('csrftoken')
          }
        })
          .then(async (response) => {
            if(response.data.length>0){
              filteredStudies = response.data;
              setStudies(filteredStudies);
              const selectedstudy = filteredStudies[0];
              setStudy(selectedstudy)
              setStudySelected(selectedstudy.uid);
              var classicColumn = [{
                width: 80,
                label: 'Identifiant',
                dataKey: 'identifiant',
              },
              {
                width: 100,
                label: 'IRM',
                dataKey: 'irm',
              },
             ]
  
              if(selectedstudy.targets.length > 0){
                classicColumn.push({
                  width: selectedstudy.targets.length *100,
                  label: "Cibles",
                  dataKey: "cibles",
                  columns: []

                })
                selectedstudy.targets.map((target)=>{
                  classicColumn[classicColumn.length-1].columns.push({
                    width: 100,
                    label: target.name.toString(),
                    dataKey: target.name.toString(),
                  })
                })
              
              }

              classicColumn.push( {
                width: 80,
                label: 'Mode de vue',
                dataKey: 'vueMode',
              })
  
              if(selectedstudy.meshes_supp.length > 0){
                classicColumn.push({
                  width: selectedstudy.meshes_supp.length*100,
                  label: "Meshes supplémentaires",
                  dataKey:"suppMesh",
                  columns: []
                })
                selectedstudy.meshes_supp.map((mesh)=>{
                  classicColumn[classicColumn.length-1].columns.push({
                    width: 100,
                    label: mesh.sem,
                    dataKey: mesh.sem,
                  })
                })
              
              }

  
              if(selectedstudy._type === "Evaluation"){
                classicColumn.push({
                  width: 120,
                  label: 'Note',
                  dataKey: 'note',
                })
              }
              setColumns(classicColumn);
              await axios.post(process.env.REACT_APP_API + '/api/study-experts/', {
                uid: filteredStudies[0].uid
              }, {
                headers: {
                  Authorization: `Bearer ${access}`,
                  'X-CSRFToken': getCookie('csrftoken')
                }
              })
                .then(async (response) => {
                  const finalExperts = response.data
                  setExperts(finalExperts);
                  // setFilteredExperts(response.data);
                  const expert = finalExperts[0];
                  setExpert(expert)
                  setExpertSelected(expert.user.username);
              await axios.post(process.env.REACT_APP_API + '/api/patients-study-expert/', {
                study: selectedstudy.uid,
                username: expert.user.username
              }, {
                headers: {
                  Authorization: `Bearer ${access}`,
                  'X-CSRFToken': getCookie('csrftoken')
                }
              })
                .then(async (response) => {
                  const patients = response.data;
                  let table_studies = [];
                  await Promise.all(patients.map(async (patient, i) => {
                      
                    var tablestudy = {index: patient.index,
                      identifiant:patient.index+2,
                      irm: patient.patientId.irm,
                  vueMode:patient.viewMode } 
                    if(patient.suppMesh.length > 0){
                      patient.suppMesh.map((mesh)=>{
                        if(mesh.showMesh){
                          tablestudy = {...tablestudy, [mesh.meshSupp.sem]: <span style={{color:mesh.meshSupp.color}}> {mesh.meshSupp.sem}</span>}
                        }
                        else{
                          tablestudy = {...tablestudy, [mesh.meshSupp.sem]: <span style={{color:"gray"}}> {mesh.meshSupp.sem}</span>}
                        }
                      })
                    }
                    if(true){
                       await axios.post(process.env.REACT_APP_API + '/api/get-targets/',
                      {
                        patient: patient.patientId.identifiant,
                        expert: expert.user.username,
                        study: selectedstudy.uid
              
                      }
                      , {
                        headers: {
                          Authorization: `Bearer ${access}`,
                          'X-CSRFToken': getCookie('csrftoken')
                        }
                      })
                        .then(async (response) => {
                          if(response.data.length > 0){
                            response.data.map((target)=>{
                              tablestudy = {...tablestudy, [target.targetId.name]: <span > {"(" + target.x.toString() +","+target.y.toString()+"," + target.z.toString()+")"}</span>, note: target.note?target.note.label:""}
              
                            })
                          }
                         });
                    }
                    table_studies.push(tablestudy);
                  return tablestudy;
                })).then((table_studies)=>{
                  setRows(table_studies.sort((a,b)=>a.index-b.index))
                });
                  
                })
          
                      });
            }
           
          });
          setIsLoading(false)
      }
      fetchData();
      
      hideLoader();

 
    }, []);


    return(
      <Grid container style={{backgroundColor: "#0e2852", height:"100vh" }} direction={"column"}>
        {isLoading && (
        <div className="loading-overlay">
          <div className="loading-animation">
            {/* Add your loading animation component here */}
          </div>
        </div>
      )}
      <Grid container item xs={1} style={{backgroundColor: "#303233",}}>
     <Grid container  item xs={6} >
     <Box
       component="img"
       sx={{
           height: "7vh",
           backgroundRepeat: "no-repeat",
           backgroundPosition: "center",
           backgroundSize: "cover",
           display: "grid",
           placeItems: "center"
       }}
       alt="comet web."
       src={process.env.REACT_APP_STATIC+"images/logo.png"}
     />
     </Grid>
     <Grid item xs={5}/>
     
     <Grid  item xs={1} >
     <Box
       component="img"
       sx={{
           marginLeft:"20px",
           height: "7vh",
           backgroundRepeat: "no-repeat",
           backgroundPosition: "center",
           backgroundSize: "cover",
           display: "grid",
           placeItems: "center"
       }}
       alt="comet web."
       src={process.env.REACT_APP_STATIC+"images/syneika-rem.png"}
     />
     </Grid>
     </Grid>

     <Grid container item xs={0.3} >
      

     </Grid>
     
     <Grid container item xs={1.2}sx={{paddingLeft:"10vh"}} >
      <Grid item xs={2}/>
      <Grid item container xs={3}>
        <Grid item container xs={3}>
        <Typography sx={{color: "white"}}>Etude: {}</Typography>
        </Grid>
        <Grid item container xs={3} sx={{height:'1vh'}}>
        <Select
        sx={{backgroundColor:"white"}}
        value={studySelected}
        onChange={handleSelectStudyChange}
         size='small'>
          {studies.map((study)=>
            <MenuItem key={study.uid} value={study.uid}>
              {study.name}
            </MenuItem>
          )}
         </Select>
        </Grid>
      </Grid>
      <Grid item container xs={3}>
        <Grid item container xs={3}>
        <Typography sx={{color: "white"}}>Participant: {}</Typography>
        </Grid>
        <Grid item container xs={3} sx={{height:'1vh'}}>
        <Select  sx={{backgroundColor:"white"}}
        value={expertSelected}
        onChange={handleSelectExpertChange}
         size='small'>
          {experts.map((expert)=>
            <MenuItem key={expert.user.username} value={expert.user.username}>
              {expert.user.first_name + " " + expert.user.last_name}
            </MenuItem>
          )}
         </Select>
        </Grid>
      </Grid>
      <Grid item container xs={3.5} direction="row">
      <Grid item container xs={3} >
      <IconButton
      onClick={()=>{
        const doc = new jsPDF();
        const tableData = [];

        // Prepare table data
        const headerRow = [];
        columns.forEach((column) => { // Single column label
            if (column.columns) {
              // Parent column label
              column.columns.forEach((childColumn) => {
                headerRow.push(childColumn.label); // Child column label
              });
            } 
            else{
              headerRow.push(column.label);
            }
        });
        tableData.push(headerRow); // Header row
       
        
      
        rows.forEach((row) => {
          const rowData = [];
          columns.forEach((column) => {
            if (column.columns) {
              column.columns.forEach((childColumn) => {
                if (row[childColumn.dataKey].props.style && row[childColumn.dataKey].props.style.color === 'gray'){
                  rowData.push("");
                }
                else{
                  rowData.push(row[childColumn.dataKey].props.children[1])
                }
                ; // Child column data
              });
            } else {
              rowData.push(row[column.dataKey]); // Single column data
            }
          });
          tableData.push(rowData); // Data rows
        });
      
        // Set table options
        const options = {
          margin: { top: 9 },
          startY: 20,
          styles: {
            fontSize: 6, // Adjust the font size here
          },
        };
      
        // Generate PDF table
        doc.autoTable({
          head: [tableData[0]], // Header row
          body: tableData.slice(1), // Data rows
          ...options,
        });
      
    
        doc.save(study.name +"_"+expert.user.last_name+".pdf");
         
      }}
       style={{color:"white"}}>
          <Download style={{color:'white'}}/> PDF
        </IconButton>
      </Grid>
      <Grid item container xs={4} >
      <IconButton
      onClick={()=>{
        let csvContent = '';

  // Prepare header rows
  const headerRow1 = columns.map((column) => column.columns?column.columns.map(col=>col.label):column.label).join(',');

  csvContent += headerRow1 + '\n'; // Add first header row

  // Prepare data rows
  rows.forEach((row) => {
    const rowData = columns.map((column) => {
      if (column.columns ){
      return (column.columns.map(col=>{
        if (row[col.dataKey].props.style && row[col.dataKey].props.style.color === 'gray'){
          return(0)
        }
        if (row[col.dataKey].props.style && row[col.dataKey].props.style.color !== 'gray'){
          return(1)
        }
        else{
          const value = row[col.dataKey].props.children[1];
          console.log(value);
          return(value.includes(',') ? `"${value}"` : value)
        }
        }))
    } else{
      // return (row[column.dataKey].includes(',') ? `"${row[column.dataKey]}"` : row[column.dataKey])
      console.log(row[column.dataKey]);
      return (row[column.dataKey]);
  }});
    const dataRow = rowData.join(',');
    console.log(dataRow);
    csvContent += dataRow + '\n';
  });

  // Create a Blob object with the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a download link
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', study.name +"_"+expert.user.last_name+'.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
      }}
      style={{color:"white"}}>
          <Download style={{color:'white'}}/> CSV
        </IconButton>
      </Grid>
      </Grid>
     </Grid>
     <Grid container item xs={9.5} >
     <Grid item xs={1} />
         <Grid item xs={1} >
       <IconButton sx={{ color: '#FFFF'}} onClick={() => {navigate('/admin')}}>
       <ArrowBack fontSize="large"/>
       </IconButton>
       </Grid>   
       <Grid container item xs={7.5}>
       <Paper  style={{ height: 600, width: '100%' }}>
      <TableVirtuoso
      id='tableau'
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper>
     
       </Grid>
       
     <Grid item xs={1}></Grid>
     </Grid>
     </Grid>
    );

}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ExportStudies);
