import patientsTypes from "./patients-types";


const INTIAL_STATE = {
    patients: [] //section name
};

const patientsReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case patientsTypes.SET_PATIENTS:
            return{
                    ...state,
                    patients: action.payload
            };
         case patientsTypes.ADD_PATIENT:
            return {
                ...state,
                patients: [
                    ...state.patients,
                    action.payload
                ]
            };
        default:
            return state;
    }
};

export default patientsReducer;