import expertTypes from './expert-types';

export const setExpert = (expert) => ({
    type: expertTypes.SET_EXPERT,
    payload: expert,
});

export const modifyExpert = expert => ({
    type: expertTypes.SET_VALUES,
    payload: expert
});

export const clearExpert = () => ({
    type: expertTypes.CLEAR_EXPERT,
});

