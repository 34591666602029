
import * as React from 'react';
import { Grid, Typography, useTheme, IconButton, useMediaQuery}  from '@mui/material';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';


const Help = ({textColor, inViewer}) => {
  const theme = useTheme();
  //const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
 // const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  const videosAndDescriptions = [
    {
      index:0,
      title: "Comment sélectionner une étude, un patient et une cible?",
      video: process.env.REACT_APP_STATIC+"videos/page1.mkv",
      description: `Après la connexion, choisissez une des études affichées et cliquez sur "Ouvrir"
      - Vous pouvez sélectionner un patient directement sur la liste des patients.
      - Vous pouvez sélectionner une cible directement sur la liste des cibles ou en cliquant sur le bouton suivant ou précédent.
      - Sur la dernière cible, le bouton Pointage Suivant permet de passer à la première cible du patient suivant.
      - Sur la première cible de la liste, le bouton Pointage Précédent permet de passer à la première cible du patient précédent.
      `
    },
    {
      index:1,
      title: "Comment pointer une cible (1)?",
      video: process.env.REACT_APP_STATIC+"videos/page2.mkv",
      description: `
      Pour pointer une cible, vous devez déplacer les axes des vues Axiales, Sagittal et Coronal, sur les vues 3D la cible est représentée par une sphère rouge.
      - L'état de chaque cible est affiché dans la liste des cibles : non défini->rouge, modifié->orange, enregistré->vert.
      - L'état de chaque patient est affiché dans la liste des patients : toutes les cibles définies->vert, rouge sinon.
      - Les cordonnées de la cible sont mises à jour à chaque modification (Pointage / X, Y et Z).
      `
    },
    {
      index:2,
      title: "Comment pointer une cible (2)?",
      video: process.env.REACT_APP_STATIC+"videos/page3.mkv",
      description: `Vous pouvez également pointer une cible en double cliquant sur un point dans les vues 2D ou 3D.`},
    {
      index:3,
      title: "Comment pointer une cible (3)?",
      video: process.env.REACT_APP_STATIC+"videos/page4.mkv",
      description: `Vous pouvez également modifier directement les cordonnées de la cible par saisie dans les champs Pointage / X, Y et Z.`    }
    ,
    {
      index:4,
      title: "Comment changer le mode de vue dans le viewer?",
      video: process.env.REACT_APP_STATIC+"videos/page5.mkv",
      description: `Vous pouvez sélectionner le mode de vue le plus adapté pour réaliser le pointage en le sélectionnant dans le menu déroulant Affichage
      - vue 2D sagittale, coronale ou axiale
      - vue 3D (plans 2D, surface cerveau ou peeling)
      - composition 3 vues 2D + vue 3D
      `
    },
    {
      index:5,
      title: "Comment utiliser le Peeling?",
      video: process.env.REACT_APP_STATIC+"videos/page6.mkv",
      description: `
      Sélectionnez le mode Peeling sur la vue 3D
- la profondeur de peeling est modifiable via la barre de réglage ou via les boutons + et -
- la hauteur de la zone de peeling est modifiable via la barre de réglage ou via les boutons + et -
`
    },
    {
      index:6,
      title: "Comment changer le fenêtrage (contraste/luminosité) sur les vues?",
      video: process.env.REACT_APP_STATIC+"videos/page8.mkv",
      description: `
      Cliquez dans une des vues 2D et faites glisser le curseur 
        - horizontalement pour régler le contraste
        - verticalement pour régler la luminosité

        Pour revenir au réglage initial, cliquez sur Fenêtrage par défaut
        `
      
    },
    {
      index:7,
      title: "Comment afficher un masque sur les images?",
      video: process.env.REACT_APP_STATIC+"videos/page7.mkv",
      description: `S'il est disponible pour le patient, vous pouvez superposer l'affichage d'un masque sur les images (e.g. masque IRMf)
      - sélectionnez ce mode via la case à cocher 
      - le masque est visible sur les vues 2D ou la vue 3D plans
      `
    }
  
   
  ]
  
  const [mainVideo, setMainVideo] = React.useState(videosAndDescriptions[0].video);
  const [title, setTitle] = React.useState(videosAndDescriptions[0].title);
  const [description, setDescription] = React.useState(videosAndDescriptions[0].description);
  const [index, setIndex] = React.useState(videosAndDescriptions[0].index);
  const [fade, setFade] = React.useState(false)


  const videoParentRef = React.useRef();

  React.useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    setFade(true)
    setTimeout(()=>{
      setFade(false)

    },1001);
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                //setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);


return(
    <div>
         <Grid container sx={{height: inViewer?"80vh":"100vh"}} direction='column'>
            <Grid item xs={0.75} sm={0.75} md={0.75} lg={0.75} style={{height:'2vh'}}>
           { textColor? <Typography
           className={fade?"fadein":""}
            style={{
                fontSize:inViewer ? "2.5vh" : "4vh",
                fontFamily:'Kanit',
                color: textColor,
                paddingLeft:inViewer?"0":"2vh",

            }}>
                {title}
            </Typography> : null}
            </Grid>
            <Grid style={{paddingLeft:inViewer?"0vh":"2vh", paddingRight:inViewer?"3vh":"3vh", height:'3vh'}} container item xs={3}>
                <Typography
                className={fade?"fadein":""}
                style={{color:textColor === "white" ? "white" :"black", fontSize:inViewer?"1.7vh":"2vh", fontFamily:"Kanit", whiteSpace: "pre-line"
              }}>
                   {description}
                    </Typography>
            </Grid>
            <Grid item container xs={8}>
              <Grid container item xs={0.5} sm={0.5} md={0.5}  style={{paddingLeft:inViewer?'0vh':'0vh'}} direction="column">
              <Grid item  xs={5.75} sm={5.75} md={5.75}/>
                <Grid item container xs={0.5} sm={0.5} md={0.5}>
                <IconButton
              size="small"
              onClick={()=>{
                let i = index;
                if(i > 0){
                  i--;
                  setMainVideo(videosAndDescriptions[i].video);
                  setDescription(videosAndDescriptions[i].description);
                  setTitle(videosAndDescriptions[i].title);
                  setIndex(videosAndDescriptions[i].index);
                  setFade(true)
                }
              }}
              >
                 <FaCaretLeft style={{color: textColor, filter: "drop-shadow(4px 11px 5px rgba(0, 0, 0, 0.19))"}} size={inViewer?45: (isMediumScreen?50: 70)} viewBox='0 0 300 450'  />
                </IconButton>
                </Grid>
                <Grid item  xs={5.75} sm={5.75} md={5.75}/>

              </Grid>
              <Grid item container xs={10.75}  sm={10.5} md={10.5} lg={10.5}>
            <div
            className={fade?"fadein":""}
              ref={videoParentRef}
              style={{width: "100%",
                overflow:"hidden",
                display:"block",
                height: inViewer?(isMediumScreen?"50vh":"55vh"):(isMediumScreen?"57vh":"65vh"),
              borderRadius:"6vh",
              boxShadow:"0px 15px 6px 5px rgba(0, 0, 0, .08)"}}
              onAnimationEnd={()=>{setFade(false)}}
              dangerouslySetInnerHTML={{
                __html: `
                <video
                controls
                style="margin-top:${inViewer?"-90px":(isMediumScreen?"-53px":"-200px")}"
                width="100%"
                height=${inViewer?"600vh":(isMediumScreen?"500vh":"900vh")}
                  loop
                  muted
                  autoplay
                  playsinline
                  preload="metadata"
                >
                <source src="${mainVideo}" type="video/mp4"  />
                </video>`
              }}
            />
              </Grid>
              <Grid item container  sm={0.5} xs={0.5} md={0.5} style={{paddingLeft:inViewer?'1vh':'0h'}} direction="column">
                <Grid item xs={5.75} md={5.75} sm={5.75}>

                </Grid>
                <Grid item container xs={0.5} md={0.5} sm={0.5}>
                <IconButton
               onClick={()=>{

              let i = index;
              if(i < videosAndDescriptions.length-1){
                i++;
                setMainVideo(videosAndDescriptions[i].video);
                setDescription(videosAndDescriptions[i].description);
                setTitle(videosAndDescriptions[i].title);
                setIndex(videosAndDescriptions[i].index);
                setFade(true)
              }

            }}
            
             size='small' >
               <FaCaretRight size={inViewer?45: (isMediumScreen?50: 70)} viewBox='0 0 300 450'  style={{color: textColor, filter: "drop-shadow(4px 11px 5px rgba(0, 0, 0, 0.19))",
}}  />
              </IconButton>
                    </Grid>
                <Grid item  xs={5.75}>

                </Grid>
                
              
              </Grid>
            </Grid>
           

        </Grid>

        
    </div>
)
    }
export default Help;
