import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user-reducer';
import patientReducer from './patient/patient-reducer';
import patientsReducer from './patients/patients-reducer';
import {persistReducer} from 'redux-persist';
import loadingReducer from './loading/loading-reducer';
import expertReducer from './expert/expert-reducer';
import studyReducer from './study/study-reducer';
import studiesReducer from './studies/studies-reducer';
import targetsReducer from './targets/targets-reducer';
import locReducer from './localizations/localizations-reducer';





const persistConfig = {
    key:'root',
    storage,
    whitelist:['user', 'expert', "study", "studies", "localizations",'patient'],
    blacklist:['patients',"targets",]
}

const rootReducer = combineReducers({
    user: userReducer,
    patient: patientReducer,
    patients: patientsReducer,
    loading: loadingReducer,
    expert: expertReducer,
    study: studyReducer,
    studies: studiesReducer,
    targets: targetsReducer,
    localizations: locReducer
});
export default persistReducer(persistConfig,rootReducer);
