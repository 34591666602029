import targetsTypes from "./targets-types";
import setTargets from "./targets-utils";

const INTIAL_STATE = {
    currentTargets:null,
};

const targetsReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case targetsTypes.SET_TARGETS:
            return{
                ...state,
                currentTargets: action.payload
            };
        case targetsTypes.SET_VALUES:
                return{
                    ...state,
                    currentTargets: setTargets(state.currentTargets, action.payload)
                }
        case targetsTypes.CLEAR_TARGETS:
            return{
                currentTargets: null
            };
        default:
            return state;
    }
};

export default targetsReducer;