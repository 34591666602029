import "@kitware/vtk.js/Rendering/Profiles/All";
import "@kitware/vtk.js/IO/Core/DataAccessHelper/HttpDataAccessHelper";
import { connect } from "react-redux";
import "malihu-custom-scrollbar-plugin";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css";
import "./style.css";
import * as React from "react";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkImageMapper from "@kitware/vtk.js/Rendering/Core/ImageMapper";
import vtkImageReslice from "@kitware/vtk.js/Imaging/Core/ImageReslice";
import vtkImageSlice from "@kitware/vtk.js/Rendering/Core/ImageSlice";
import vtkInteractorStyleImage from "@kitware/vtk.js/Interaction/Style/InteractorStyleImage";
import vtkPolyDataReader from "@kitware/vtk.js/IO/Legacy/PolyDataReader";
import vtkPiecewiseFunction from "@kitware/vtk.js/Common/DataModel/PiecewiseFunction";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkOutlineFilter from "@kitware/vtk.js/Filters/General/OutlineFilter";
import vtkResliceCursorWidget from "@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget";
import vtkWidgetManager from "@kitware/vtk.js/Widgets/Core/WidgetManager";
import vtkSphereSource from "@kitware/vtk.js/Filters/Sources/SphereSource";
import { CaptureOn } from "@kitware/vtk.js/Widgets/Core/WidgetManager/Constants";
import { SlabMode } from "@kitware/vtk.js/Imaging/Core/ImageReslice/Constants";
import { xyzToViewType } from "@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants";
import $ from "jquery";
import vtk from "@kitware/vtk.js/vtk"
import { useEffect, useRef, useState } from "react";
import vtkXMLImageDataReader from "@kitware/vtk.js/IO/XML/XMLImageDataReader";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Slider,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { FaCaretRight, FaMinus, FaPlus } from "react-icons/fa"; //FaSave
import { FaCaretLeft } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import axios from "axios";
import vtkCellPicker from "@kitware/vtk.js/Rendering/Core/CellPicker";
import vtkColorTransferFunction from "@kitware/vtk.js/Rendering/Core/ColorTransferFunction";
import vtkInteractorStyleTrackballCamera from "@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera";
import { Box } from "@mui/system";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Help from "./Help";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { BiReset } from "react-icons/bi";
import vtkMatrixBuilder from "@kitware/vtk.js/Common/Core/MatrixBuilder";
import vtkDataArray from "@kitware/vtk.js/Common/Core/DataArray";
import vtkPlane from "@kitware/vtk.js/Common/DataModel/Plane";
import vtkClipClosedSurface from "@kitware/vtk.js/Filters/General/ClipClosedSurface";
import { setPatient } from "../redux/patient/patient-action";
import { setUser } from "../redux/user/user-action";
import ListViewer from "./ListViewer";
import { setStudy } from "../redux/study/study-action";
import { setPatients } from "../redux/patients/patients-action";
import { setTargets } from "../redux/targets/targets-action";
import cloneDeep from "lodash.clonedeep";
import HelpViewer from "./HelpViewer";
import { getCookie } from "../Utils";


// ----------------------------------------------------------------------------
// Define main attributes
// ----------------------------------------------------------------------------
const VolumeViewer = ({
  study,
  hideLoader,
  showLoader,
  setUser,
  user,
  setPatient,
  patients,
  setPatients,
  setTargets,
  targets,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const viewColors = [
    [1, 0, 0], // sagittal
    [0, 1, 0], // coronal
    [0, 0, 1], // axial
    [0.5, 0.5, 0.5], // 3D
  ];
  const pickEvent3D = useRef(null);
  const buttonMinusRef = useRef(null);
  const buttonPlusRef = useRef(null);
  const sliderRef = useRef(null);
  const view3DTypeRef = useRef("Plans")
  const vues2DEvents = useRef([])
  const defaultColorLevel = useRef(null);
  const defaultColorWindow = useRef(null);
  const [peelingSliderEnabeled, enablePeelingSlider] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [scale, setScale] = useState(1.0);
  const scaleRef = useRef(1);
  const peelingState = useRef(null);
  const [buttonCerveauDisable, setButtonCerveauDisable] = useState(false);//a way to delete this
  const [buttonPeelingDisable, setButtonPeelingDisable] = useState(false);
  const [display3D, setDisplay3D] = useState(true);
  const [displaySagittal, setDisplaySagittal] = useState(true);
  const [displayCoronal, setDisplayCoronal] = useState(true);
  const [displayAxial, setDisplayAxial] = useState(true);
  const sphere3D = useRef(vtkSphereSource.newInstance({ radius: 0.5 }));
  const xText = useRef(null);
  const yText = useRef(null);
  const zText = useRef(null);
  const targetsListRef = useRef();
  const isExecuting = useRef(false);
  const origin = useRef(null);
  const context = useRef(null);
  const sphereActor3D = useRef(vtkActor.newInstance());
  const sagittalContainer = useRef(null);
  const coronalContainer = useRef(null);
  const axialContainer = useRef(null);
  const vue3DRef = useRef(null);
  const sphereMapperRef = useRef(null);
  const widgetRef = useRef(null);
  const studyRef = useRef(null);
  const [zCut, setZcut] = useState(0);
  const [zMaxSkin, setZmax] = useState(0);
  const [zMinSkin, setZmin] = useState(0);
  const [listDisable, setListDisable] = useState(false);
  const [maskchecked, setMaskchecked] = useState(false);
  const maskIsAvailable = useRef(false);
  const [defaultTargets, setDefaultTargets] = useState({});
  const imageRef = useRef();
  const [viewMode, setViewMode] = useState(3);
  const [view, setView] = useState("Plans");
  const [gridAxial, setGridAxial] = useState(6);
  const [gridCoronal, setGridCoronal] = useState(6);
  const [gridSagittal, setGridSagittal] = useState(6);
  const [grid3D, setGrid3D] = useState(6);
  const [openHelp, setOpenHelp] = useState(false);
  const [heightAxial, setHeightAxial] = useState("42.5vh");
  const [heightCoronal, setHeightCoronal] = useState("42.5vh");
  const [heightSagittal, setHeighSagittal] = useState("42.5vh");
  const [height3D, setHeight3D] = useState("48.5vh");
  const [renderTargets, setRenderTargets] = useState([]);
  const [localizations, setLocalizations] = useState([]);
  const [targetIndex, setTargetIndex] = useState(0);
  const [currentPatientIndex, setCurrentPatientIndex] = useState(0);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openSnackbar1, setOpenSnackBar1] = useState(false);
  const patientsFilesMapRef = useRef(new Map());
  const logoutTimerIdRef = React.useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [targetNote, setTargetNote] = useState(0);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const prevPatientRef = useRef({});
  const patientFilesRef = useRef();
  const [nextPreviousDisable, setNextPreviousDisable] = useState(false);
  let clickCount = 0;
  let clickCount1 = 0;
  const [informationMessage, setInformationMessage] = useState("");
  const [openSnackbar2, setOpenSnackBar2] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const timerClickRef = useRef(null);
  const timerClickRef1 = useRef(null);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert sx={{zIndex: 100}} elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const container = document.getElementById('root')
    container.classList.add('vtk-progress-cursor');
    hideLoader()
    const autoLogout = () => {
      if (document.visibilityState === "hidden") {
        const timeOutId = window.setTimeout(() => {
          setUser({});
          setTargets([]);
          setPatients([]);
          navigate("/expert/login");
        }, 100 * 60 * 100); //100 min of inactivity
        logoutTimerIdRef.current = timeOutId;
      } else {
        window.clearTimeout(logoutTimerIdRef.current);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
    };
  }, []);

  function useCancelToken() {
    const token = useRef(new Map());
    const cancel = (id) => { token.current.set(id, { cancelled: true }) };
    const uncancel = (id) => { token.current.set(id, { cancelled: false }) };

    return [token.current, cancel, uncancel];
  }
  const [token, cancel, uncancel] = useCancelToken();

  const add3DDoubleClickEventListener = (actor, picker) => {
    const widget = widgetRef.current;
    const container = document.getElementById('root')
    container.classList.add('vtk-wait-cursor');
    picker.setPickFromList(1);
    picker.setTolerance(0);
    picker.initializePickList();
    picker.addPickList(actor);
    const myEvent = context.current[3].GLWindow
      .getRenderWindow()
      .getInteractor()
      .onLeftButtonPress(async (callData) => {
        clickCount++;
        if (clickCount === 1) {
          timerClickRef.current = setTimeout(function () {
            clickCount = 0;
          }, 400);
        }
        if (clickCount === 2) {
          clearTimeout(timerClickRef.current);
          clickCount = 0;
          context.current[3].GLWindow.getRenderWindow().getInteractor().getView().setCursor("wait");
          setTimeout(async () => {
            if (context.current[3].GLWindow.getRenderer() !== callData.pokedRenderer) {
              return;
            }
            const pos = callData.position;
            const point = [pos.x, pos.y, 0.0];
            console.log(`Pick at: ${point}`);
            picker.pick(point, context.current[3].GLWindow.getRenderer());
            sphere3D.current.set({ phiResolution: 100 });
            sphere3D.current.set({ thetaResolution: 100 });
            sphere3D.current.setRadius(3);
            if (picker.getActors().length === 0) {
              const pickedPoint = picker.getPickPosition();
              console.log(`No cells picked, default: ${pickedPoint}`);
              sphere3D.current.setCenter(pickedPoint);
              widget.setCenter(pickedPoint);
              updateTextFields({
                x: pickedPoint[0],
                y: pickedPoint[1],
                z: pickedPoint[2],
              });
              studyRef.current.target.localization = {
                x: pickedPoint[0],
                y: pickedPoint[1],
                z: pickedPoint[2],
              };
              if (studyRef.current.target.status !== "IN PROGRESS") {
                const index = studyRef.current.targets.findIndex(
                  (target) => target.name === studyRef.current.target.name
                );
                let _targets = studyRef.current.targets;
                _targets[index].status = "IN PROGRESS";
                setTargets(_targets);
                studyRef.current.targets = _targets;
                setRenderTargets(_targets);
                studyRef.current.target.status_previous =
                  studyRef.current.target.status;
                studyRef.current.target.status = "IN PROGRESS";
              }
              updateViews();
            } else {
              const pickedCellId = picker.getCellId();
              console.log("Picked cell: ", pickedCellId);
              const pickedPoint = picker.getPickedPositions();
              const a = pickedPoint[0];
              console.log(`Picked: ${pickedPoint}`);
              const arr1 = [a[0], a[1], a[2]];
              const arr2 = [pickedPoint[0], pickedPoint[1], pickedPoint[2]];
              var arr = view3DTypeRef.current === "Plans" ? arr2 : arr1;

              if (studyRef.current.target.status !== "IN PROGRESS") {
                const index = studyRef.current.targets.findIndex(
                  (target) => target.name === studyRef.current.target.name
                );
                let _targets = studyRef.current.targets;
                _targets[index].status = "IN PROGRESS";
                setTargets(_targets);
                studyRef.current.targets = _targets;
                setRenderTargets(_targets);
                studyRef.current.target.status_previous =
                  studyRef.current.target.status;
                studyRef.current.target.status = "IN PROGRESS";
              }

              sphere3D.current.setCenter(arr);
              widget.setCenter(arr);
              updateTextFields({ x: arr[0], y: arr[1], z: arr[2] });
              studyRef.current.target.localization = {
                x: arr[0],
                y: arr[1],
                z: arr[2],
              };
              updateViews();
              sphereActor3D.current.getProperty().setColor(...viewColors[0]);
            }
            context.current[3].GLWindow.getRenderWindow().render();
            context.current[3].GLWindow.getRenderWindow().getInteractor().getView().setCursor("default");
          }, 400)

        }
      });
    pickEvent3D.current = myEvent;
    container.classList.remove('vtk-wait-cursor');
  }

  const calculateCameraOrientation = () => {
    const GLWindow = context.current[3].GLWindow;
    const xyzSphere = sphere3D.current.getCenter();
    const xyzBrain = [90.5, 127.5, 127.5]
    const normalized = [xyzSphere[0] - xyzBrain[0], xyzSphere[1] - xyzBrain[1], xyzSphere[2] - xyzBrain[2]];
    const absNormalized = normalized.map(Math.abs);
    let max = {
      value: 0
    };
    if (absNormalized[0] === 0 && absNormalized[1] === 0 && absNormalized[2] === 0) {
      GLWindow.getRenderer()
        .getActiveCamera()
        .setDirectionOfProjection(0, 1, 0);
    }
    else {
      absNormalized.map((abs, i) => {
        if (abs > max.value) {
          max = {
            value: abs,
            i: i
          }
        }
        return true;
      });
      const plusOrMinus = normalized[max.i] > 0 ? -1 : 1;
      let v =
        [0, 0, 0]
      v[max.i] = plusOrMinus;
      const vup = GLWindow.getRenderer().getActiveCamera().getViewUp()
      if (vup[0] === 0 && vup[1] === 0 && vup[2] === 1) {
        //do noting
      }
      else {
        GLWindow.getRenderer().getActiveCamera().setViewUp([0, 0, 1]);
      }
      GLWindow.getRenderer()
        .getActiveCamera()
        .setDirectionOfProjection(v[0], v[1], v[2]);
    }
  }

  const setCameraOrientation = (DirectionOfProjection, viewUp) => {
    const GLWindow = context.current[3].GLWindow;
    GLWindow.getRenderer()
      .getActiveCamera()
      .setDirectionOfProjection(
        DirectionOfProjection[0],
        DirectionOfProjection[1],
        DirectionOfProjection[2]
      );
    GLWindow.getRenderer().getActiveCamera().setViewUp([viewUp[0], viewUp[1], viewUp[2]]);
  }

  const calculatePeeling = async (pd, newValue, scaleParam,) => {

    enablePeelingSlider(false);

    const points = pd.getPoints()

    const image = imageRef.current;
    const bounds = image.getBounds();
    const xSize = bounds[1] - bounds[0];
    const ySize = bounds[3] - bounds[2];
    const zSize = bounds[5] - bounds[4];

    const colorWindow = context.current[0].resliceActor
      .getProperty()
      .getColorWindow();
    const colorLevel = context.current[0].resliceActor
      .getProperty()
      .getColorLevel();

    if (newValue === 1) scaleParam = scaleParam - 0.0001;
    vtkMatrixBuilder
      .buildFromDegree()
      .identity()
      .translate(xSize / 2, ySize / 2, zSize / 2)
      .scale(scaleParam, scaleParam, scaleParam)
      .translate(-xSize / 2, -ySize / 2, -zSize / 2)
      .apply(points.getData());
    scaleRef.current = newValue;
    setScale(newValue);

    const getNumberOfPoints = await points.getNumberOfPoints();

    const scalars = vtkDataArray.newInstance({
      size: getNumberOfPoints * 3,
      numberOfComponents: 3,
    });

    for (var j = 0; j < getNumberOfPoints; j++) {
      const xyz = [];
      let worldPos, currentVxValue;
      try {
        points.getPoint(j, xyz);
        worldPos = image.indexToWorld(xyz);
        currentVxValue = parseInt(
          await image.getScalarValueFromWorld(worldPos, 0)
        );
        currentVxValue =
          currentVxValue < colorLevel - colorWindow / 2
            ? 0
            : currentVxValue > colorLevel + colorWindow / 2
              ? 1
              : (currentVxValue - (colorLevel - colorWindow / 2)) / colorWindow;
        currentVxValue = currentVxValue < 0 ? 0 : currentVxValue;
        if (newValue === 1)
          scalars.setTuple(j, [255 / 255, 205 / 255, 148 / 255]);
        else {
          scalars.setTuple(j, [currentVxValue, currentVxValue, currentVxValue]);
        }
      }
      catch (error) {
        console.log(error);
      }

    }

    pd.getPointData().setScalars(scalars);
    return pd;
  }

  const peelingRender = async (
    scaleParam,
    newValue,
    viewUp,
    DirectionOfProjection,
    CameraDistance,
    handleChange
  ) => {

    let pd, image, pdOrig, GLWindow, actor, mapper, op, filter, actor2, mapper2;
    const studyType = study._type;
    let points;
    const element = vue3DRef.current;


    if (peelingState.current) {

      const pdOrig = peelingState.current.pd;
      pd = pdOrig;
      image = peelingState.current.image;
      actor = peelingState.current.actor;
      mapper = peelingState.current.mapper;
      op = peelingState.current.op;
      GLWindow = context.current[3].GLWindow;
      actor.setMapper(mapper);
      const scaledPd = await calculatePeeling(pd, newValue, scaleParam, handleChange);
      mapper.setInputData(scaledPd);
      mapper.setColorModeToDirectScalars();
      actor.modified();
      GLWindow.getRenderWindow().render();

    } else if (!peelingState.current) {
      deleteResources();



      if (!patientFilesRef.current) {
        const readerSkin1 = vtkPolyDataReader.newInstance();
        await readerSkin1
          .setUrl(
            process.env.REACT_APP_PATIENTS +
            studyRef.current.patient.irm +
            "/MR-T1_Skin.vtk",
            { loadData: true }
          )
          .then(async () => {
            pd = readerSkin1.getOutputData();
            const json = readerSkin1.toJSON()
            const clone = cloneDeep(json);
            const readerSkinClone = vtkPolyDataReader.newInstance(clone);
            op = readerSkinClone.getOutputPort();
          })
          .catch((error) => {
            console.log(error);
            console.log("235");
            setOpenSnackBar1(true);
            setErrorMessage(
              "Erreur de téléchargement, veuillez actualiser la page!"
            );
          });

      } else {
        pdOrig = patientFilesRef.current.pdSkin;
        pd = vtk(pdOrig.getState());
        points = pd.getPoints();
        op = patientFilesRef.current.op;
      }



      if (context.current[3]) {
        GLWindow = context.current[3].GLWindow;
        GLWindow.getRenderer().removeAllActors();
      }
      else {
        GLWindow = vtkGenericRenderWindow.newInstance();
        GLWindow.setContainer(element);
        GLWindow.resize();
        sphereActor3D.current.getProperty().setColor(...viewColors[0]);
        sphere3D.current.set({ phiResolution: 100 });
        sphere3D.current.set({ thetaResolution: 100 });
        sphere3D.current.setRadius(3);
      }




      context.current[3] = {
        ...context.current[3],
        GLWindow: GLWindow
      }
      image = imageRef.current;
      actor = vtkActor.newInstance();
      mapper = vtkMapper.newInstance();
      actor2 = vtkActor.newInstance();
      mapper2 = vtkMapper.newInstance();
      let picker = vtkCellPicker.newInstance();
      if (pickEvent3D.current) {
        pickEvent3D.current.unsubscribe();
        pickEvent3D.current = null
      }

      if (studyType !== "Evaluation") {
        add3DDoubleClickEventListener(actor, picker);
      }
      const widget = widgetRef.current;
      const center_ = widget.getWidgetState().getCenter();
      sphere3D.current.setCenter(center_);

      const mapperSphere = vtkMapper.newInstance();
      mapperSphere.setInputConnection(sphere3D.current.getOutputPort());
      sphereActor3D.current.setMapper(mapperSphere);
      GLWindow.getRenderer().addActor(sphereActor3D.current);

      if (viewUp && DirectionOfProjection) {
        setCameraOrientation(DirectionOfProjection, viewUp)
      }
      else {
        calculateCameraOrientation()
      }
      sphereMapperRef.current = mapperSphere;

      actor.setMapper(mapper);
      const bounds = image.getBounds();

      const center = [
        (bounds[1] + bounds[0]) / 2,
        (bounds[3] + bounds[2]) / 2,
        (bounds[5] + bounds[4]) / 2,
      ];

      const NAMED_COLORS = {
        BANANA: [209 / 255, 163 / 255, 164 / 255],
        TOMATO: [255 / 255, 205 / 255, 148 / 255],
        SANDY_BROWN: [244 / 255, 164 / 255, 96 / 255],
      };

      const clipColor = NAMED_COLORS.BANANA;
      const baseColor = NAMED_COLORS.TOMATO;
      let zCut = 0;
      if (!peelingState.current || !peelingState.current.zCut) {
        let zMax = 0,
          yMin = 10000,
          zYMin = 0,
          zMin = 10000;
        const clipTopHeight = 110; // millimeters
        const clipNoseHeight = 10; // millimeters
        const getNumberOfPoints = points.getNumberOfPoints()
        for (var i = 0; i < getNumberOfPoints; ++i) {
          const XYZ = [];
          points.getPoint(i, XYZ);
          zMax = zMax < XYZ[2] ? XYZ[2] : zMax;
          zMin = zMin > XYZ[2] ? XYZ[2] : zMin;
          if (yMin > XYZ[1]) {
            yMin = XYZ[1];
            zYMin = XYZ[2];
          }
        }
        let zCutTop = zMax - clipTopHeight;
        let zCutNose = zYMin + clipNoseHeight;
        zCut = zCutNose < zCutTop ? zCutNose : zCutTop;

        setZmax(parseFloat(zMax.toFixed(2)));
        setZmin(parseFloat(zMin.toFixed(2)));
      } else {
        zCut = peelingState.current.zCut;
      }


      const planes = [];

      if (!peelingState.current || !peelingState.current.zCut) {
        actor2.setMapper(mapper2);

        setZcut(zCut);
        const plane3 = vtkPlane.newInstance({
          origin: [center[0], center[1], zCut],
          normal: [0.0, 0.0, -1],
        });
        planes.push(plane3);

        filter = vtkClipClosedSurface.newInstance({
          clippingPlanes: planes,
          activePlaneId: 2,
          clipColor: clipColor,
          baseColor: baseColor,
          activePlaneColor: NAMED_COLORS.SANDY_BROWN,
          passPointData: false,
        });

        filter.setInputConnection(op);

        filter.setScalarModeToColors();

        filter.update();

        const filterData = filter.getOutputData();

        mapper2.setInputData(filterData);
        GLWindow.getRenderer().addActor(actor2);
      } else {
        actor2.setMapper(mapper2);
        setZcut(peelingState.current.zCut);
        const plane3 = vtkPlane.newInstance({
          origin: [center[0], center[1], peelingState.current.zCut],
          normal: [0.0, 0.0, -1],
        });
        planes.push(plane3);

        filter = vtkClipClosedSurface.newInstance({
          clippingPlanes: planes,
          activePlaneId: 2,
          clipColor: clipColor,
          baseColor: baseColor,
          activePlaneColor: NAMED_COLORS.SANDY_BROWN,
          passPointData: false,
        });

        filter.setInputConnection(op);

        filter.setScalarModeToColors();

        filter.update();

        const filterData = filter.getOutputData();

        mapper2.setInputData(filterData);
        GLWindow.getRenderer().addActor(actor2);
      }

      const scaledPd = await calculatePeeling(pd, newValue, scaleParam)
      mapper.setInputData(scaledPd);
      mapper.setColorModeToDirectScalars();
      actor.modified();
      const originBrain = origin.current;

      actor.setPosition(originBrain[0], originBrain[1], originBrain[2]);

      studyRef.current.patient.showSuppMesh.map(async (mesh) => {

        if (mesh.showMesh) {
          const path = process.env.REACT_APP_PATIENTS +
            studyRef.current.patient.irm + "/" + mesh.meshSupp.fileName + ".vtk";
          const reader1 = vtkPolyDataReader.newInstance();

          await reader1
            .setUrl(path)
            .then(async () => {
              const suppMeshpd = reader1.getOutputData();
              const mapper = vtkMapper.newInstance();
              const actor = vtkActor.newInstance();
              actor.setMapper(mapper);
              mapper.setInputData(suppMeshpd);
              const hexColor = mesh.meshSupp.color; // Red color
              const rgbColor = hexToRgb(hexColor);


              // Set the new scale along the X-axis*
              GLWindow.getRenderer().addActor(actor);
              actor.getProperty().setLineWidth(5)

              actor.getProperty().setColor(...rgbColor);
              GLWindow.getRenderWindow().render()
            })
            .catch((error) => {
              console.log(error);
              console.log("1710");
              setOpenSnackBar1(true);
            });

        }


      })

      GLWindow.getRenderer().addActor(actor);

      GLWindow.getRenderer().setBackground([0, 0, 0]);

      GLWindow.getRenderer().resetCamera();
      if (CameraDistance) {
        GLWindow.getRenderer().getActiveCamera().setPosition(CameraDistance[0], CameraDistance[1], CameraDistance[2]);
        GLWindow.getRenderer().resetCameraClippingRange();
      }
      GLWindow.getRenderWindow().render();


      context.current[3] = {
        ...context.current[3],
        actor: actor,
        mapper: mapper,
        GLWindow: GLWindow,
        actor2: actor2,
        mapper2: mapper2,
        pd: pd,
        pdOrig: pdOrig,
        picker: picker
      };
      peelingState.current = {
        ...peelingState.current,
        identifiant: patientFilesRef.current.identifiant,
        op: op,
        image: image,
        pd: pd,
        pdOrig: pdOrig,
        actor: actor,
        mapper: mapper,
        zCut: zCut,
        actor2: actor2,
        mapper2: mapper2,
        filter: filter,
      };
      enablePeelingSlider(true);
    }
    enablePeelingSlider(true);
    if (!pd) {

      setErrorMessage("Erreur de chargement du fichier peau");
      setOpenSnackBar1(true);
    }
  };

  //Handle peeling change for the slider and + - buttons
  const peelingHandleChange = async (newScale) => {
    enablePeelingSlider(false)
    const container = document.getElementById('root')
    container.classList.add('vtk-progress-cursor');
    let newValue = scale;
    if (newScale) {
      newValue = newScale;
    }
    if (newValue === 0) newValue = 0.02;
    const scaleParam = newValue / scaleRef.current;
    scaleRef.current = newValue;
    setScale(newValue);
    const viewUp = context.current[3].GLWindow.getRenderer()
      .getActiveCamera()
      .getViewUp();
    const DirectionOfProjection = context.current[3].GLWindow.getRenderer()
      .getActiveCamera()
      .getDirectionOfProjection();
    const position = context.current[3].GLWindow.getRenderer().getActiveCamera().getPosition();

    await peelingRender(scaleParam, newValue, viewUp, DirectionOfProjection, position, true);
    enablePeelingSlider(true)
    container.classList.remove('vtk-progress-cursor');
  };

  const targetNoteHandleChangle = async (_event) => {
    const newValue = _event.target.value;
    setTargetNote(newValue);
    const { access } = user;
    let _patients = [...patients];

    const patientIndex = patients.findIndex(
      (patient) => patient.index === studyRef.current.patient.index
    );
    const target_Index = targets.findIndex(
      (target) => target.name === studyRef.current.target.name
    );
    let _targets = [...targets];
    _targets[target_Index].note = newValue;
    setTargets(_targets);
    setRenderTargets(_targets);

    let completed_note = false;
    if (study._type === "Evaluation") {
      completed_note = _targets.every((element) => {
        if (element.note) {
          return true;
        } else {
          return false;
        }
      });

      if (completed_note) {
        _patients[patientIndex].note = "DONE";
        setPatients(_patients);
      }
      else {
        _patients[patientIndex].note = "undone";
        setPatients(_patients);
      }
    }

    await axios
      .post(
        process.env.REACT_APP_API + "/api/save-target-note/",
        {
          uid: studyRef.current.target.uid,
          note: newValue,
          note_done: _patients[patientIndex].note,
          study: study.uid,
          username: user.username,
          identifiant: studyRef.current.patient.identifiant,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      )
      .then(
        (_response) => {
        },
        (_error) => {
          alert("erreur enregistrement database");
        }
      );

    //save to the BDD
  };


  const loadPatientFilesWithAnimation = async (irm, identifiant) => {


    if (!patientsFilesMapRef.current.get(identifiant)) {
      setIsLoading(true);
      const cerveauButton = document.getElementById("CerveauButton");
      const peelingButton = document.getElementById("PeelingButton");
      const container = document.getElementById('root')
      cerveauButton.classList.add("text-view-3d");
      peelingButton.classList.add("text-view-3d");
      container.classList.add('vtk-progress-cursor');
      const BrainVtkPath =
        process.env.REACT_APP_PATIENTS + irm + "/MR-T1_Brain.vtk";
      const readerBrain = vtkPolyDataReader.newInstance();



      const readerSkin1 = vtkPolyDataReader.newInstance();
      patientFilesRef.current = {
        identifiant: null,
        pdBrain: null,
        pdSkin: null,
        op: null
      };

      setButtonCerveauDisable(true);
      setButtonPeelingDisable(true);

      return new Promise((resolve, reject) => {

        readerBrain
          .setUrl(BrainVtkPath)
          .then(async () => {
            const pdBrain = await readerBrain.getOutputData();
            var op;
            var pdSkin;
            cerveauButton.classList.remove("text-view-3d");
            setButtonCerveauDisable(false);
            await readerSkin1
              .setUrl(process.env.REACT_APP_PATIENTS + irm + "/MR-T1_Skin.vtk")
              .then(async () => {
                pdSkin = await readerSkin1.getOutputData();
                const json = readerSkin1.toJSON()
                const clone = cloneDeep(json);
                const readerSkinClone = vtkPolyDataReader.newInstance(clone);
                op = await readerSkinClone.getOutputPort();
                setButtonPeelingDisable(false);
              })

            resolve({ pdBrain: pdBrain, op: op, pdSkin: pdSkin, identifiant: identifiant, irm: irm });
            peelingButton.classList.remove("text-view-3d");

          })
          .catch(error => {
            reject(error);
          });
        console.log(identifiant + " loaded");
      });



    }

  };

  const NextOrPrevPatientLoadFiles = async (identifiant, patients) => {
    let pdSkin, opSkin, pdBrain;
    const irm = await patients.find(
      (patient) => patient.identifiant === identifiant
    ).irm;

    if (!patientsFilesMapRef.current.get(identifiant)) {
      const BrainVtkPath =
        process.env.REACT_APP_PATIENTS + irm + "/MR-T1_Brain.vtk";
      const readerBrain = vtkPolyDataReader.newInstance();
      await readerBrain
        .setUrl(BrainVtkPath)
        .then(async () => {
          pdBrain = await readerBrain.getOutputData();
        })
        .catch((error) => {
          console.log(error);
          console.log("611");
          setOpenSnackBar1(true);
        });

      const readerSkin1 = vtkPolyDataReader.newInstance();
      //  const readerSkin2 = vtkPolyDataReader.newInstance();
      await readerSkin1
        .setUrl(process.env.REACT_APP_PATIENTS + irm + "/MR-T1_Skin.vtk", {
          loadData: true,
        })
        .then(async () => {
          pdSkin = await readerSkin1.getOutputData();
          const json = readerSkin1.toJSON()
          const clone = cloneDeep(json);
          const readerSkinClone = vtkPolyDataReader.newInstance(clone);
          opSkin = readerSkinClone.getOutputPort();
        })
        .catch((error) => {
          console.log(error);
          console.log("623");
          setOpenSnackBar1(true);
        });


      patientsFilesMapRef.current.set(identifiant, {
        identifiant: identifiant,
        pdSkin: pdSkin,
        op: opSkin,
        pdBrain: pdBrain,
      });
    } else {
    }
  };

  useEffect(() => {

    const { access, is_staff } = user;
    const { name } = study;
    !access || !name ? navigate("/expert/login") : void (0);

    async function fetchData() {
      studyRef.current = { ...studyRef.current, target: {}, patient: {} };
      onStudySelect(study.uid, study._type, study.listSeq).then(async ({ patient, patients }) => {
        enablePeelingSlider(false);
        setMaskchecked(false);
        await onPatientSelect(patient, study.uid, patients)
          .then(async () => {
            loadFilesandViewer(patient, study._type, patients)
          })
          .catch((error) => {
            console.log(error);
            console.log("662");
            setOpenSnackBar1(true);
          });
      });
    }

    is_staff ? navigate("/") : !name ? navigate("/studies") : fetchData();

    return () => {
      reset();
      context.current = null
    };
  }, []);
  useEffect(() => {
    if (
      context.current &&
      (displayAxial || displayCoronal || displaySagittal)
    ) {
      if (isMediumScreen) {
        displayAxial
          ? setHeightAxial("50vh")
          : isSmallScreen
            ? setHeightAxial("40vh")
            : void 0;
        displayCoronal
          ? setHeightCoronal("50vh")
          : isSmallScreen
            ? setHeightCoronal("40vh")
            : void 0;
        displaySagittal
          ? setHeighSagittal("50vh")
          : isSmallScreen
            ? setHeighSagittal("40vh")
            : void 0;
      }

      if (displaySagittal) {
        context.current[0].grw.resize();
      }
      if (displayCoronal) {
        context.current[1].grw.resize();
      }
      if (displayAxial) {
        context.current[2].grw.resize();
      }
    }
  }, [heightSagittal, heightAxial, heightCoronal]);

  useEffect(() => {
    setRenderTargets(null);
  }, [renderTargets]);

  const reset = () => {
    setListDisable(true);
    setNextPreviousDisable(true)
    if (context.current) {

      deleteResources()

    }

  };

  const handleTextFieldChangleLocation = (e, type) => {
    const a = Number(e.target.value);
    const widget = widgetRef.current;
    let center = widget.getWidgetState().getCenter();
    if (a > 0 && a < 255) {
      if (type === "x") {
        center[0] = a;
        sphere3D.current.setCenter(center);
        widget.setCenter(center);
        studyRef.current.target.localization = {
          ...studyRef.current.target.localization,
          x: a,
        };
      }

      if (type === "y") {
        center[1] = a;
        sphere3D.current.setCenter(center);
        widget.setCenter(center);
        studyRef.current.target.localization = {
          ...studyRef.current.target.localization,
          y: a,
        };
      }

      if (type === "z") {
        center[2] = a;
        sphere3D.current.setCenter(center);
        widget.setCenter(center);
        studyRef.current.target.localization = {
          ...studyRef.current.target.localization,
          z: a,
        };
      }
      if (studyRef.current.target.status !== "IN PROGRESS") {
        const index = studyRef.current.targets.findIndex(
          (target) => target.name === studyRef.current.target.name
        );
        let _targets = studyRef.current.targets;
        _targets[index].status = "IN PROGRESS";
        setTargets(_targets);
        studyRef.current.targets = _targets;
        setRenderTargets(_targets);
        studyRef.current.target.status_previous =
          studyRef.current.target.status;
        studyRef.current.target.status = "IN PROGRESS";
      }

    }

    const grw = context.current[3].GLWindow;
    sphere3D.current.set({ phiResolution: 100 });
    sphere3D.current.set({ thetaResolution: 100 });
    sphere3D.current.setRadius(3);
    sphere3D.current.setCenter(center);
    sphereActor3D.current.getProperty().setColor(...viewColors[0]);
    grw.getRenderWindow().render();
    updateViews();
  };

  const loadFilesandViewer = async (patient, type, patients) => {
    const identifiant = patient.identifiant;
    const irm = patient.irm;
    if (prevPatientRef.current.identifiant) {
      const prevIdentifiant = prevPatientRef.current.identifiant;
      cancel(prevIdentifiant);
    }
    if (patientsFilesMapRef.current.get(identifiant)) {
      const p = await patientsFilesMapRef.current.get(identifiant);
      patientFilesRef.current = p;
    }
    else {
      patientFilesRef.current = {};
      const cerveauButton = document.getElementById("CerveauButton");
      const peelingButton = document.getElementById("PeelingButton");
      cerveauButton.classList.add("text-view-3d");
      peelingButton.classList.add("text-view-3d");
      const p = await loadPatientFilesWithAnimation(irm, identifiant);
      patientFilesRef.current = p;
    }
    uncancel(identifiant);
    await loadViewer(type);

    const patients_ = patients.sort((a, b) => a.index - b.index);

    const patientIndex = patients_.findIndex(
      (patient) => patient.identifiant === identifiant
    );
    let newMap = new Map();
    prevPatientRef.current = patient;

    if (patientIndex >= 0 && patientIndex < patients_.length - 3) {

      for (let i = patientIndex; i <= patientIndex + 3; i++) {
        const this_patient_identifiant = patients_[i].identifiant;

        if (!token.get(identifiant).cancelled) {

          if (patientsFilesMapRef.current.get(this_patient_identifiant)) {
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant));
          }
          else {
            await NextOrPrevPatientLoadFiles(this_patient_identifiant, patients);
            console.log(this_patient_identifiant + " loaded --");
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant)
            )
          }

        }
        else {
          console.log(this_patient_identifiant + " cancelled");
        }
      }

    }
    if (patientIndex === 2 || patientIndex === 1) {

      for (var i = patientIndex; i >= 0; i--) {
        if (!token.get(identifiant).cancelled) {
          const this_patient_identifiant = patients_[i].identifiant;
          if (patientsFilesMapRef.current.get(this_patient_identifiant)) {
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant));
          }
          else {
            await NextOrPrevPatientLoadFiles(this_patient_identifiant, patients);
            console.log(this_patient_identifiant + " loaded");
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant)
            )
          }
        }
        else {
          console.log(identifiant + " cancelled'");
        }
      }
    }
    if (patientIndex > 2 && patientIndex < patients_.length) {
      for (let i = patientIndex; i >= patientIndex - 3; i--) {
        const this_patient_identifiant = patients_[i].identifiant;

        if (!token.get(identifiant).cancelled) {
          if (patientsFilesMapRef.current.get(this_patient_identifiant)) {
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant));
          }
          else {
            await NextOrPrevPatientLoadFiles(this_patient_identifiant, patients);
            console.log(this_patient_identifiant + " loaded");
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant)
            )
          }
        }
        else {
          console.log(this_patient_identifiant + " cancelled");
        }
      }

    }

    if (patientIndex === patients_.length - 2 || patientIndex === patients_.length - 3) {

      for (i = patientIndex + 1; i <= patients.length - 1; i++) {
        if (!token.get(identifiant).cancelled) {
          const this_patient_identifiant = patients_[i].identifiant;
          if (patientsFilesMapRef.current.get(this_patient_identifiant)) {
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant));
          }
          else {
            await NextOrPrevPatientLoadFiles(this_patient_identifiant, patients);
            console.log(this_patient_identifiant + " loaded");
            newMap.set(this_patient_identifiant,
              patientsFilesMapRef.current.get(this_patient_identifiant)
            )
          }
        }
        else {
          console.log(identifiant + " cancelled");
        }
      }
    }
    patientsFilesMapRef.current = newMap;

  }

  const loadViewer = async (type) => {
    if (!isExecuting.current) {
      // block other clicks from performing actions in parallel
      isExecuting.current = true;
      try {
        reset();
        await viewer(type);
      } catch (err) {
      } finally {
        isExecuting.current = false;
      }
    }
  };


  function updateReslice(
    interactionContext = {
      viewType: '',
      reslice: null,
      actor: null,
      renderer: null,
      resetFocalPoint: false, // Reset the focal point to the center of the display image
      keepFocalPointPosition: false, // Defines if the focal point position is kepts (same display distance from reslice cursor center)
      computeFocalPointOffset: false, // Defines if the display offset between reslice center and focal point has to be
      // computed. If so, then this offset will be used to keep the focal point position during rotation.
      spheres: null,
    }
  ) {
    const widget = widgetRef.current;
    const modified = widget.updateReslicePlane(
      interactionContext.reslice,
      interactionContext.viewType
    );
    if (modified) {
      const resliceAxes = interactionContext.reslice.getResliceAxes();
      // Get returned modified from setter to know if we have to render
      interactionContext.actor.setUserMatrix(resliceAxes);
      const planeSource = widget.getPlaneSource(interactionContext.viewType);
      interactionContext.sphereSources[0].setCenter(planeSource.getOrigin());
      interactionContext.sphereSources[1].setCenter(planeSource.getPoint1());
      interactionContext.sphereSources[2].setCenter(planeSource.getPoint2());
    }
    widget.updateCameraPoints(
      interactionContext.renderer,
      interactionContext.viewType,
      interactionContext.resetFocalPoint,
      interactionContext.keepFocalPointPosition,
      interactionContext.computeFocalPointOffset
    );
    widgetRef.current = widget;
    return modified;
  }
  function updateViews() {
    const views = [context.current[0], context.current[1], context.current[2]];
    views.forEach((obj, i) => {
      updateReslice({
        viewType: xyzToViewType[i],
        reslice: obj.reslice,
        actor: obj.resliceActor,
        renderer: obj.renderer,
        resetFocalPoint: false,
        keepFocalPointPosition: false,
        computeFocalPointOffset: true,
        sphereSources: obj.sphereSources,
        resetViewUp: true,
      });
      if (maskchecked) {
        updateReslice({
          viewType: xyzToViewType[i],
          reslice: obj.reslice1,
          actor: obj.resliceActor1,
          renderer: obj.renderer,
          resetFocalPoint: false,
          keepFocalPointPosition: false,
          computeFocalPointOffset: true,
          sphereSources: obj.sphereSources,
          resetViewUp: true,
        });
      }

      obj.renderWindow.render();
      context.current[3].GLWindow.getRenderWindow().render();

    });
  }

  const handleCloseSnackBar = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
    setOpenSnackBar1(false);
    setOpenSnackBar2(false);

  };

  const handleMask = async () => {
    const image = imageRef.current;
    setMaskchecked(!maskchecked);
    isExecuting.current = false;
    const widget = widgetRef.current;
    const viewAttributes = [context.current[0], context.current[1], context.current[2]];

    if (!maskchecked) {
      // block other clicks from performing actions in parallel
      isExecuting.current = true;
      try {
        const readerMask = vtkXMLImageDataReader.newInstance({ fetchGzip: true });
        await readerMask
          .setUrl(
            process.env.REACT_APP_PATIENTS +
            studyRef.current.patient.irm +
            "/mask.vti"
          )
          .then(async () => {
            const image1 = readerMask.getOutputData();
            image1.setOrigin(image.getOrigin());
            viewAttributes.forEach((obj, i) => {
              obj.reslice1.setInputData(image1);
              obj.renderer.addActor(obj.resliceActor1);
              const viewType = xyzToViewType[i];
              widgetRef.current = widget;

              viewAttributes.forEach((v) => {
                v.widgetInstance.onInteractionEvent(
                  ({ computeFocalPointOffset, canUpdateFocalPoint }) => {
                    const activeViewType = widget
                      .getWidgetState()
                      .getActiveViewType();
                    const keepFocalPointPosition =
                      activeViewType !== viewType && canUpdateFocalPoint;

                    updateReslice({
                      viewType: viewType,
                      reslice: obj.reslice1,
                      actor: obj.resliceActor1,
                      renderer: obj.renderer,
                      resetFocalPoint: false,
                      keepFocalPointPosition,
                      computeFocalPointOffset,
                      sphereSources: obj.sphereSources,
                    });
                  }
                );
              });

              updateReslice({
                viewType,
                reslice: obj.reslice1,
                actor: obj.resliceActor1,
                renderer: obj.renderer,
                resetFocalPoint: true, // At first initilization, center the focal point to the image center
                keepFocalPointPosition: false, // Don't update the focal point as we already set it to the center of the image
                computeFocalPointOffset: true, // Allow to compute the current offset between display reslice center and display focal point
                sphereSources: obj.sphereSources,
              });

              obj.renderer.getActiveCamera().zoom(1.5);
              obj.renderWindow.render();
              widgetRef.current = widget;
            });
          })
          .catch((error) => {
            console.log(error);
            console.log("1037");
            setOpenSnackBar1(true);
          });
      } finally {
        isExecuting.current = false;
      }
    } else {
      viewAttributes.forEach((obj, i) => {
        obj.renderer.removeActor(obj.resliceActor1);
        const viewType = xyzToViewType[i];
        widgetRef.current = widget;
        updateReslice({
          viewType,
          reslice: obj.reslice1,
          actor: obj.resliceActor1,
          renderer: obj.renderer,
          resetFocalPoint: true, // At first initilization, center the focal point to the image center
          keepFocalPointPosition: false, // Don't update the focal point as we already set it to the center of the image
          computeFocalPointOffset: true, // Allow to compute the current offset between display reslice center and display focal point
          sphereSources: obj.sphereSources,
        });
        obj.renderer.getActiveCamera().zoom(1.5);
        obj.renderWindow.render();
        widgetRef.current = widget;
      });
    }
  };


  const plansRender = async (viewUp, DirectionOfProjection, type) => {
    deleteResources();
    const element = vue3DRef.current;
    let grw;
    let obj = {}

    const picker = vtkCellPicker.newInstance();
    const image = imageRef.current;
    const widget = widgetRef.current;
    const viewAttributes = [context.current[0], context.current[1], context.current[2]];



    if (context.current[3]) {
      grw = context.current[3].GLWindow;
      obj = {
        renderWindow: grw.getRenderWindow(),
        renderer: grw.getRenderer(),
        GLWindow: grw.getOpenGLRenderWindow(),
        interactor: grw.getInteractor(),
        widgetManager: vtkWidgetManager.newInstance(),
        orientationWidget: null,
        grw: grw,
      };
    }
    else {
      grw = vtkGenericRenderWindow.newInstance();
      grw.setContainer(element);
      grw.resize();
      obj = {
        renderWindow: grw.getRenderWindow(),
        renderer: grw.getRenderer(),
        GLWindow: grw.getOpenGLRenderWindow(),
        interactor: grw.getInteractor(),
        widgetManager: vtkWidgetManager.newInstance(),
        orientationWidget: null,
        grw: grw,
      };
      obj.renderer.setBackground([0, 0, 0]);
      obj.renderWindow.addRenderer(obj.renderer);
      obj.renderWindow.addView(obj.GLWindow);
      obj.renderWindow.setInteractor(obj.interactor);
      obj.interactor.setView(obj.GLWindow);
      obj.interactor.initialize();
      obj.widgetManager.setRenderer(obj.renderer);
      obj.interactor.setInteractorStyle(
        vtkInteractorStyleTrackballCamera.newInstance()
      );
      obj.reslice = vtkImageReslice.newInstance();
      obj.reslice.setSlabMode(SlabMode.MEAN);
      obj.reslice.setSlabNumberOfSlices(1);
      obj.reslice.setTransformInputSampling(false);
      obj.reslice.setAutoCropOutput(true);
      obj.reslice.setOutputDimensionality(2);
      obj.resliceMapper = vtkImageMapper.newInstance();
      obj.resliceMapper.setInputConnection(obj.reslice.getOutputPort());
      obj.resliceActor = vtkImageSlice.newInstance();
      obj.resliceActor.setMapper(obj.resliceMapper);
      sphereActor3D.current.getProperty().setColor(...viewColors[0]);
      sphere3D.current.set({ phiResolution: 100 });
      sphere3D.current.set({ thetaResolution: 100 });
      sphere3D.current.setRadius(3);

    }
    context.current[3] = {
      ...context.current[3],
      GLWindow: grw,
    }


    const center = widget.getWidgetState().getCenter();
    sphere3D.current.setCenter(center);

    const mapper = vtkMapper.newInstance();
    mapper.setInputConnection(sphere3D.current.getOutputPort());
    sphereActor3D.current.setMapper(mapper);
    obj.renderer.removeAllActors();
    obj.renderer.addActor(sphereActor3D.current);



    if (DirectionOfProjection && viewUp) {
      setCameraOrientation(DirectionOfProjection, viewUp)
    } else {
    }

    sphereMapperRef.current = mapper;

    const outline = vtkOutlineFilter.newInstance();
    outline.setInputData(image);
    const outlineMapper = vtkMapper.newInstance();
    outlineMapper.setInputData(outline.getOutputData());
    const outlineActor = vtkActor.newInstance();
    outlineActor.setMapper(outlineMapper);
    obj.renderer.addActor(outlineActor);

    if (pickEvent3D.current) {
      pickEvent3D.current.unsubscribe();
      pickEvent3D.current = null
    }

    if (type !== "Evaluation") {
      add3DDoubleClickEventListener(outlineActor, picker)
    }




    obj.outlineActor = outlineActor;
    obj.renderer.resetCamera();
    obj.renderWindow.render();
    viewAttributes.forEach((_obj, _i) => {
      if (view3DTypeRef.current === "Plans") {
        obj.renderer.addActor(_obj.resliceActor);
      }
    });
    obj.renderWindow.render();
    obj.renderer.resetCamera();
    obj.renderer.resetCameraClippingRange();
    context.current[3] = {
      ...context.current[3],
      actor: obj.resliceActor,
      mapper: obj.resliceMapper,
      GLWindow: obj.grw,
      picker: picker,

    };
    setIsLoading(false);
    const container = document.getElementById('root')
    container.classList.remove('vtk-progress-cursor');
  };

  const deleteResources = async () => {
    if (context.current) {
      if (context.current[3]) {
        const { actor, mapper,
          actor2, mapper2, filter, picker } = context.current[3];
        context.current[3].GLWindow.getRenderer().removeAllActors();

        if (actor) {
          actor.delete()
        }
        if (picker) {
          picker.delete()
        }
        if (mapper) {
          mapper.delete()
        }
        if (actor2) {
          actor2.delete()
        }
        if (mapper2) {
          mapper2.delete()
        }
        if (filter) {
          filter.delete()
        }

      }
    }
    if (peelingState.current) {
      if (peelingState.current.actor && peelingState.current.mapper) {
        peelingState.current.actor.delete();
        peelingState.current.mapper.delete();
      }
      if (peelingState.current.actor2 && peelingState.current.mapper2) {
        peelingState.current.actor2.delete();
        peelingState.current.mapper2.delete();
      }

      if (peelingState.current.filter) {
        peelingState.current.filter.delete();
      }

      peelingState.current = null;
    }

  }
  function hexToRgb(hex) {
    hex = hex.replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16) / 255;
    const g = parseInt(hex.substring(2, 4), 16) / 255;
    const b = parseInt(hex.substring(4, 6), 16) / 255;

    return [r, g, b];
  }

  const brainRender = async (viewUp, DirectionOfProjection, CameraDistance) => {
    const widget = widgetRef.current;
    let pd, GLWindow, actor, mapper;
    const element = vue3DRef.current;
    const studyType = study._type
    const path =
      process.env.REACT_APP_PATIENTS +
      studyRef.current.patient.irm +
      "/MR-T1_Brain.vtk";



    const picker = vtkCellPicker.newInstance();
    mapper = vtkMapper.newInstance();
    actor = vtkActor.newInstance();
    deleteResources();

    if (patientFilesRef.current) {
      pd = patientFilesRef.current.pdBrain;
    } else if (!patientFilesRef.current) {
      const reader1 = vtkPolyDataReader.newInstance();
      await reader1
        .setUrl(path)
        .then(async () => {
          pd = reader1.getOutputData();
        })
        .catch((error) => {
          console.log(error);
          console.log("1268");
          setOpenSnackBar1(true);
        });
    }

    if (context.current[3]) {
      GLWindow = context.current[3].GLWindow;

    }
    else {
      GLWindow = vtkGenericRenderWindow.newInstance();
      GLWindow.setContainer(element);
      GLWindow.resize();
      sphereActor3D.current.getProperty().setColor(...viewColors[0]);
      sphere3D.current.set({ phiResolution: 100 });
      sphere3D.current.set({ thetaResolution: 100 });
      sphere3D.current.setRadius(3);
    }


    context.current[3] = {
      ...context.current[3],
      GLWindow: GLWindow
    }

    const center = widget.getWidgetState().getCenter();
    sphere3D.current.setCenter(center);

    const mapperSphere = vtkMapper.newInstance();
    mapperSphere.setInputConnection(sphere3D.current.getOutputPort());
    sphereActor3D.current.setMapper(mapperSphere);
    GLWindow.getRenderer().removeAllActors();
    GLWindow.getRenderer().addActor(sphereActor3D.current);


    sphereMapperRef.current = mapperSphere;

    const originBrain = origin.current;

    actor.setMapper(mapper);
    actor.setPosition(originBrain[0], originBrain[1], originBrain[2]);

    mapper.setInputData(pd);

    if (DirectionOfProjection && viewUp) {
      setCameraOrientation(DirectionOfProjection, viewUp)


    } else {
      calculateCameraOrientation()
    }
    if (pickEvent3D.current) {
      pickEvent3D.current.unsubscribe();
      pickEvent3D.current = null
    }
    pickEvent3D.current = null;


    if (studyType !== "Evaluation") {
      add3DDoubleClickEventListener(actor, picker)
    }

    studyRef.current.patient.showSuppMesh.map(async (mesh) => {

      if (mesh.showMesh) {
        const path = process.env.REACT_APP_PATIENTS +
          studyRef.current.patient.irm + "/" + mesh.meshSupp.fileName + ".vtk";
        const reader1 = vtkPolyDataReader.newInstance();

        await reader1
          .setUrl(path)
          .then(async () => {
            const suppMeshpd = reader1.getOutputData();
            const mapper = vtkMapper.newInstance();
            const actor = vtkActor.newInstance();
            actor.setMapper(mapper);
            mapper.setInputData(suppMeshpd);
            const hexColor = mesh.meshSupp.color; // Red color
            const rgbColor = hexToRgb(hexColor);


            GLWindow.getRenderer().addActor(actor);
            actor.getProperty().setLineWidth(5)

            actor.getProperty().setColor(...rgbColor);
            GLWindow.getRenderWindow().render()
          })
          .catch((error) => {
            console.log(error);
            console.log("1710");
            setOpenSnackBar1(true);
          });

      }


    })
    GLWindow.getRenderer().addActor(actor);
    GLWindow.getRenderer().setBackground([0, 0, 0]);
    GLWindow.getRenderer().resetCamera();
    if (CameraDistance) {
      GLWindow.getRenderer().getActiveCamera().setPosition(CameraDistance[0], CameraDistance[1], CameraDistance[2]);
      GLWindow.getRenderer().resetCameraClippingRange();
    }
    else {
      GLWindow.getRenderer().getActiveCamera().zoom(1.3);
    }

    GLWindow.getRenderer().getActiveCamera().pitch(2.3);
    GLWindow.getRenderWindow().render();

    const obj = {
      actor: actor,
      mapper: mapper,
      GLWindow: GLWindow,
      pd: pd,
      picker: picker
    };

    context.current[3] = { ...obj };
  };

  const updateTextFields = (value) => {
    var x = value.x ? value.x : 90.5;
    var y = value.y ? value.y : 127.5;
    var z = value.z ? value.z : 127.5;
    if (xText.current && yText.current && zText.current) {
      xText.current.value = x.toFixed(2);
      yText.current.value = y.toFixed(2);
      zText.current.value = z.toFixed(2);
    }
  };
  const viewer = async (type) => {
    var localization_viewer;
    let widget;
    let viewAttributes = [];
    const appCursorStyles = {
      translateCenter: 'crosshair',
      rotateLine: 'alias',
      translateAxis: 'pointer',
      default: 'default',
    }; 
    if (!context.current) {
      widget = vtkResliceCursorWidget.newInstance();
      const widgetState = widget.getWidgetState();
      const t = 1.9;
      widgetState.getStatesWithLabel('line').forEach((handle) => handle.setScale3(t, t, t));
      widgetState.getStatesWithLabel('handles').forEach((handle) => handle.setOpacity(150));
      widgetState.getStatesWithLabel('rotation').forEach((handle) => handle.setScale1(10));
      widgetState.getCenterHandle().setVisible(true);
      widgetState.getCenterHandle().setScale1(20);
      widgetState.getCenterHandle().setOpacity(2)



      const showDebugActors = true;

      for (let i = 0; i < 3; i++) {
        const element =
          i === 0
            ? sagittalContainer.current
            : i === 1
              ? coronalContainer.current
              : i === 2
                ? axialContainer.current
                : sagittalContainer.current;

        const grw = vtkGenericRenderWindow.newInstance();
        grw.setContainer(element);
        grw.resize();
        const obj = {
          type: i,
          grw: grw,
          renderWindow: grw.getRenderWindow(),
          renderer: grw.getRenderer(),
          GLWindow: grw.getOpenGLRenderWindow(),
          interactor: grw.getInteractor(),
          widgetManager: vtkWidgetManager.newInstance(),
        };
        obj.renderer.getActiveCamera().setParallelProjection(true);
        obj.renderer.setBackground([0, 0, 0]);
        obj.renderWindow.addRenderer(obj.renderer);
        obj.renderWindow.addView(obj.GLWindow);
        obj.renderWindow.setInteractor(obj.interactor);
        obj.interactor.setView(obj.GLWindow);
        obj.interactor.initialize();
        obj.interactor.bindEvents(element);
        obj.widgetManager.setRenderer(obj.renderer);
        obj.interactor.setInteractorStyle(
          vtkInteractorStyleImage.newInstance()
        );
        obj.widgetInstance = obj.widgetManager.addWidget(
          widget,
          xyzToViewType[i]
        );
        obj.widgetInstance.setScaleInPixels(true);
        obj.widgetInstance.setKeepOrthogonality(true);
        obj.widgetManager.enablePicking();
        obj.widgetInstance.setCursorStyles(appCursorStyles);
        obj.widgetManager.setCaptureOn(CaptureOn.MOUSE_MOVE);

        obj.reslice = vtkImageReslice.newInstance();
        obj.reslice.setSlabMode(SlabMode.MEAN);
        obj.reslice.setSlabNumberOfSlices(1);
        obj.reslice.setTransformInputSampling(false);
        obj.reslice.setAutoCropOutput(true);
        obj.reslice.setOutputDimensionality(2);

        obj.resliceMapper = vtkImageMapper.newInstance();
        obj.resliceMapper.setInputConnection(obj.reslice.getOutputPort());
        obj.resliceActor = vtkImageSlice.newInstance();

        defaultColorLevel.current = obj.resliceActor
          .getProperty()
          .getColorLevel();
        defaultColorWindow.current = obj.resliceActor
          .getProperty()
          .getColorWindow();

        obj.resliceActor.setMapper(obj.resliceMapper);
        //FMRI actor
        obj.reslice1 = vtkImageReslice.newInstance();
        obj.reslice1.setSlabMode(SlabMode.MEAN);
        obj.reslice1.setSlabNumberOfSlices(1);
        obj.reslice1.setTransformInputSampling(false);
        obj.reslice1.setAutoCropOutput(true);
        obj.reslice1.setOutputDimensionality(2);
        obj.resliceMapper1 = vtkImageMapper.newInstance();
        obj.resliceMapper1.setInputConnection(obj.reslice1.getOutputPort());
        obj.resliceActor1 = vtkImageSlice.newInstance();
        const rgb = vtkColorTransferFunction.newInstance();// coleur du masque
        rgb.addRGBPoint(0, 0, 0, 0); 
        rgb.addRGBPoint(255, 56, 255, 30);
        const ofun = vtkPiecewiseFunction.newInstance();// coleur du masque
        ofun.addPoint(1, 0);
        ofun.addPoint(50, 0);
        ofun.addPoint(180, 1);
        ofun.addPoint(255, 1);
        obj.resliceActor1.getProperty().setPiecewiseFunction(ofun);
        obj.resliceActor1.getProperty().setRGBTransferFunction(rgb);
        obj.resliceActor1.setMapper(obj.resliceMapper1);
        obj.sphereActors = [];
        obj.sphereSources = [];

        obj.interactor.onLeftButtonRelease(async (callData) => {
          clickCount1++;
          if (clickCount1 === 1) {
            timerClickRef1.current = setTimeout(async function () {
              const colorWindow = context.current[i].resliceActor
                .getProperty()
                .getColorWindow();
              const colorLevel = context.current[i].resliceActor
                .getProperty()
                .getColorLevel();
              for (var j = 0; j < 3; j++) {
                context.current[j].resliceActor
                  .getProperty()
                  .setColorWindow(colorWindow);
                context.current[j].resliceActor
                  .getProperty()
                  .setColorLevel(colorLevel);
              }
              if (peelingState.current && view3DTypeRef.current === "Peeling") {
                const viewUp = context.current[3].GLWindow.getRenderer()
                  .getActiveCamera()
                  .getViewUp();
                const DirectionOfProjection =
                  context.current[3].GLWindow.getRenderer()
                    .getActiveCamera()
                    .getDirectionOfProjection();
                const position = context.current[3].GLWindow.getRenderer().getActiveCamera().getPosition();
                setIsLoading(true);
                //console.log("peeeel");
                await peelingRender(1, scaleRef.current, viewUp, DirectionOfProjection, position);
                setIsLoading(false);

              }
              updateViews();
              clickCount1 = 0;
            }, 100);
            const container = document.getElementById('root')
            container.classList.remove('vtk-progress-cursor');
          }
          if (clickCount1 === 2) {
            clearTimeout(timerClickRef1.current);
            clickCount1 = 0;
          }

        })
        // Here 

        for (let j = 0; j < 3; j++) {
          const sphere = vtkSphereSource.newInstance();
          sphere.setRadius(10);
          const mapper = vtkMapper.newInstance();
          mapper.setInputConnection(sphere.getOutputPort());
          const actor = vtkActor.newInstance();
          actor.setMapper(mapper);
          actor.getProperty().setColor(...viewColors[i]);
          actor.setVisibility(showDebugActors);
          obj.sphereActors.push(actor);
          obj.sphereSources.push(sphere);
        }
        if (i < 3) {
          viewAttributes.push(obj);
        }
      }
      widgetRef.current = widget;
      context.current = viewAttributes;
    }
    else {
      widget = widgetRef.current;
      if (context.current) {
        viewAttributes = [context.current[0], context.current[1], context.current[2]];
      }

    }

    const reader = vtkXMLImageDataReader.newInstance({ fetchGzip: true });


    await reader
      .setUrl(
        process.env.REACT_APP_PATIENTS +
        studyRef.current.patient.irm +
        "/MR-T1.vti"
      )
      .then(async () => {
        const image = reader.getOutputData();
        widget.setImage(image);
        const origins = image.getOrigin();

        origin.current = origins;

        const center = widget.getWidgetState().getCenter();
        imageRef.current = image;

        const localization_DB = studyRef.current.target.localization;
        localization_viewer = [
          center[0] - origins[0],
          center[1] - origins[1],
          center[2] - origins[2],
        ];

        if (
          localization_DB.x === 0 &&
          localization_DB.y === 0 &&
          localization_DB.z === 0
        ) {
          studyRef.current.target.localization = {
            x: localization_viewer[0],
            y: localization_viewer[1],
            z: localization_viewer[2],
          };
        } else {
          localization_viewer = [
            localization_DB.x - origins[0],
            localization_DB.y - origins[1],
            localization_DB.z - origins[2],
          ];
          widget.setCenter(localization_viewer);
        }

        if (type) {
          if (type === "Evaluation") {
            if (vues2DEvents.current.length > 2) {
              for (var i = 0; i < vues2DEvents.current.length; i++) {
                vues2DEvents.current[i].unsubscribe()
              }
            }
            vues2DEvents.current = []
          }

          viewAttributes.forEach((obj, i) => {
            if (type === "Evaluation") {
              obj.widgetManager.disablePicking();
              obj.widgetInstance.setEnabled(false);

            }
            else {
              obj.widgetManager.enablePicking();
              obj.widgetInstance.setEnabled(true);

              const event = obj.interactor.onLeftButtonPress(async (callData) => {
                clickCount++;
                if (clickCount === 1) {
                  timerClickRef.current = setTimeout(function () {
                    clickCount = 0;
                  }, 300);
                }
                if (clickCount === 2) {
                  context.current[1].grw.getRenderWindow().getInteractor().getView().setCursor("progress");
                  context.current[0].grw.getRenderWindow().getInteractor().getView().setCursor("progress");
                  context.current[2].grw.getRenderWindow().getInteractor().getView().setCursor("progress");
                  clearTimeout(timerClickRef.current);
                  clickCount = 0;
                  const pos = callData.position;
                  const arr = obj.interactor
                    .getView()
                    .displayToWorld(pos.x, pos.y, pos.z, obj.renderer);
                  const c = widget.getWidgetState().getCenter();
                  let position = [];
                  if (obj.type === 0) {
                    widget.setCenter([c[0], arr[1], arr[2]]);
                    updateTextFields({ x: c[0], y: arr[1], z: arr[2] });
                    position = [c[0], arr[1], arr[2]];
                  }

                  if (obj.type === 1) {
                    widget.setCenter([arr[0], c[1], arr[2]]);
                    updateTextFields({ x: arr[0], y: c[1], z: arr[2] });
                    position = [arr[0], c[1], arr[2]];
                  }
                  if (obj.type === 2) {
                    widget.setCenter([arr[0], arr[1], c[2]]);
                    updateTextFields({ x: arr[0], y: arr[1], z: c[2] });
                    position = [arr[0], arr[1], c[2]];
                  }
                  sphere3D.current.set({ phiResolution: 100 });
                  sphere3D.current.set({ thetaResolution: 100 });
                  sphere3D.current.setRadius(3);
                  sphere3D.current.setCenter(position);
                  studyRef.current.target.localization = {
                    x: position[0],
                    y: position[1],
                    z: position[2],
                  };
                  context.current[3].GLWindow.getRenderWindow().render();

                  //TODO
                  if (studyRef.current.target.status !== "IN PROGRESS") {
                    const index = studyRef.current.targets.findIndex(
                      (target) => target.name === studyRef.current.target.name
                    );
                    let _targets = studyRef.current.targets;
                    _targets[index].status = "IN PROGRESS";
                    setTargets(_targets);
                    studyRef.current.targets = _targets;
                    setRenderTargets(_targets);
                    studyRef.current.target.status_previous =
                      studyRef.current.target.status;
                    studyRef.current.target.status = "IN PROGRESS";
                  }
                  updateViews();
                  context.current[1].grw.getRenderWindow().getInteractor().getView().setCursor("default");
                  context.current[0].grw.getRenderWindow().getInteractor().getView().setCursor("default");
                  context.current[2].grw.getRenderWindow().getInteractor().getView().setCursor("default");

                }
              });
              vues2DEvents.current.push(
                event
              )
            }


            obj.renderer.removeAllActors();
            obj.reslice.setInputData(image);
            obj.renderer.addActor(obj.resliceActor);
            const reslice = obj.reslice;
            const viewType = xyzToViewType[i];
            widgetRef.current = widget;


            viewAttributes.forEach((v) => {

              v.widgetInstance.onInteractionEvent(
                ({ computeFocalPointOffset, canUpdateFocalPoint }) => {
                  const activeViewType = widget
                    .getWidgetState()
                    .getActiveViewType();
                  const keepFocalPointPosition =
                    activeViewType !== viewType && canUpdateFocalPoint;

                  updateReslice({
                    viewType: viewType,
                    reslice: reslice,
                    actor: obj.resliceActor,
                    renderer: obj.renderer,
                    resetFocalPoint: false,
                    keepFocalPointPosition,
                    computeFocalPointOffset,
                    sphereSources: obj.sphereSources,
                  });

                  const center = widget.getWidgetState().getCenter();
                  const origin = widget.getWidgetState().getImage().getOrigin();
                  const locations = [
                    origin[0] - center[0],
                    origin[1] - center[1],
                    origin[2] - center[2],
                  ];

                  sphere3D.current.setCenter(center);
                  const GLWindow = context.current[3].GLWindow;
                  GLWindow.getRenderWindow().render();

                  if (studyRef.current.target.status !== "IN PROGRESS") {
                    const index = studyRef.current.targets.findIndex(
                      (target) => target.name === studyRef.current.target.name
                    );
                    let _targets = studyRef.current.targets;
                    _targets[index].status = "IN PROGRESS";
                    setTargets(_targets);
                    studyRef.current.targets = _targets;
                    setRenderTargets(_targets);
                    studyRef.current.target.status_previous =
                      studyRef.current.target.status;
                    studyRef.current.target.status = "IN PROGRESS";
                  }
                  updateTextFields({
                    x: -locations[0],
                    y: -locations[1],
                    z: -locations[2],
                  });
                  studyRef.current.target.localization = {
                    x: -locations[0],
                    y: -locations[1],
                    z: -locations[2],
                  };
                }
              );

            });



            updateReslice({
              viewType,
              reslice,
              actor: obj.resliceActor,
              renderer: obj.renderer,
              resetFocalPoint: true, // At first initilization, center the focal point to the image center
              keepFocalPointPosition: false, // Don't update the focal point as we already set it to the center of the image
              computeFocalPointOffset: true, // Allow to compute the current offset between display reslice center and display focal point
              sphereSources: obj.sphereSources,
            });
            obj.renderer.getActiveCamera().zoom(1.5);
            obj.renderWindow.render();
            obj.interactor.render();
          });

        }


       
        setNextPreviousDisable(false)


      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Les fichiers IRM du patient non trouvés!");
        setOpenSnackBar1(true);

      });

    if (type === "Evaluation") {
      if (pickEvent3D.current) {
        pickEvent3D.current.unsubscribe();
        pickEvent3D.current = null;
      }
    }


    if (studyRef.current.patient.viewMode === "cerveau") {
      view3DTypeRef.current = "Cerveau";
      setView("Cerveau");
      setIsLoading(true);
      brainRender();
      setIsLoading(false)

    }
    else if (studyRef.current.patient.viewMode === "peeling") {
      view3DTypeRef.current = " ";
      setView("Peeling");
      setIsLoading(true);
      peelingRender(0.7, 0.7);
      setIsLoading(false);

    }
    else {

      view3DTypeRef.current = "Plans";
      setView("Plans");
      plansRender(null, null, type);
      setIsLoading(false);
    }
    updateViews()
  };

  const clipSkin = (newZcut) => {
    setTimeout(async () => {
      if (peelingState.current) {
        const planes = [];
        const op = peelingState.current.op;

        let z = zCut;
        if (newZcut) {
          z = newZcut;
        }
        const actor = peelingState.current.actor2;
        const mapper = peelingState.current.mapper2;
        const grw = context.current[3].GLWindow;
        const image = peelingState.current.image;
        const bounds = image.getBounds();
        const filter = peelingState.current.filter;

        const center = [
          (bounds[1] + bounds[0]) / 2,
          (bounds[3] + bounds[2]) / 2,
          (bounds[5] + bounds[4]) / 2,
        ];

        const plane3 = vtkPlane.newInstance({
          origin: [center[0], center[1], z],
          normal: [0.0, 0.0, -1],
        });
        planes.push(plane3);

        filter.setClippingPlanes(planes);

        filter.update();
        const filterData = filter.getOutputData();
        mapper.setInputData(filterData);
        actor.modified();

        grw.getRenderWindow().render();
        peelingState.current = {
          ...peelingState.current,
          op: op,
          zCut: z,
          mapper2: mapper,
          actor2: actor,
        };
      }

    }, 100)
  };

  // ZOOM IN
  const [t, setT] = useState(undefined);
  const [start, setStart] = useState(100);
  const onMouseDown = (inOut, view, e) => {
    if (e) {
      if (e.button !== 2) {
        zoom(inOut, view);
        setT(setTimeout(() => onMouseDown(inOut, view), start));
        setStart(start / 2);
      }
    } else if (!e) {
      zoom(inOut, view);
      setT(setTimeout(() => onMouseDown(inOut, view), start));
      setStart(start / 2);
    }
  };

  const onMouseUp = () => {
    clearTimeout(t);
    setStart(100);
  };

  const zoom = (inOut, view) => {
    const loc = studyRef.current.target.localization;

    if (inOut === "in") {
      if (view === "zoom1") {
        context.current[0].renderer
          .getActiveCamera()
          .setFocalPoint(loc.x, loc.y, loc.z);
        context.current[0].renderer.getActiveCamera().zoom(1.05);
        context.current[0].renderWindow.render();
      }
      if (view === "zoom2") {
        context.current[1].renderer
          .getActiveCamera()
          .setFocalPoint(loc.x, loc.y, loc.z);
        context.current[1].renderer.getActiveCamera().zoom(1.05);
        context.current[1].renderWindow.render();
      }
      if (view === "zoom3") {
        context.current[2].renderer
          .getActiveCamera()
          .setFocalPoint(loc.x, loc.y, loc.z);
        context.current[2].renderer.getActiveCamera().zoom(1.05);
        context.current[2].renderWindow.render();
      }
      if (view === "zoom4") {
        const grw = context.current[3].GLWindow;
        let zoom;
        zoom = context.current[3].zoom ? context.current[3].zoom + 0.05 : 1.05;
        grw.getRenderer().resetCamera();
        grw.getRenderer().getActiveCamera().zoom(zoom);
        grw.getRenderWindow().render();
        context.current[3].zoom = zoom;
      }
    }
    if (inOut === "out") {
      if (view === "zoom1") {
        context.current[0].renderer
          .getActiveCamera()
          .setFocalPoint(loc.x, loc.y, loc.z);
        context.current[0].renderer.getActiveCamera().zoom(0.95);
        context.current[0].renderWindow.render();
      }
      if (view === "zoom2") {
        context.current[1].renderer
          .getActiveCamera()
          .setFocalPoint(loc.x, loc.y, loc.z);
        context.current[1].renderer.getActiveCamera().zoom(0.95);
        context.current[1].renderWindow.render();
      }
      if (view === "zoom3") {
        context.current[2].renderer
          .getActiveCamera()
          .setFocalPoint(loc.x, loc.y, loc.z);
        context.current[2].renderer.getActiveCamera().zoom(0.95);
        context.current[2].renderWindow.render();
      }
      if (view === "zoom4") {
        const grw = context.current[3].GLWindow;
        let zoom;
        zoom = context.current[3].zoom ? context.current[3].zoom - 0.05 : 0.95;
        grw.getRenderer().resetCamera();
        grw.getRenderer().getActiveCamera().zoom(zoom);
        grw.getRenderWindow().render();
        context.current[3].zoom = zoom;
      }
    }
  };

  const saveLocations = async () => {
    const loc = studyRef.current.target.localization;
    const arr = [loc.x, loc.y, loc.z];
    const { access } = user;
    const target_Index = targets.findIndex(
      (target) => target.name === studyRef.current.target.name
    );
    let _targets = [...targets];
    _targets[target_Index].status = "DONE";
    const localizationIndex = localizations.findIndex(
      (loc) => loc.targetId.name === studyRef.current.target.name
    );
    let _localizations = [...localizations];
    _localizations[localizationIndex].x = arr[0];
    _localizations[localizationIndex].y = arr[1];
    _localizations[localizationIndex].z = arr[2];
    setLocalizations(_localizations);
    setTargets(_targets);
    setRenderTargets(_targets);

    const completed = _targets.every((element) => {
      if (element.status === "DONE") {
        return true;
      } else {
        return false;
      }
    });

    const patientIndex = patients.findIndex(
      (patient) => patient.index === studyRef.current.patient.index
    );
    let _patients = [...patients];

    if (completed) {
      _patients[patientIndex].status = "DONE";
      setPatients(_patients);
    }

    await axios
      .post(
        process.env.REACT_APP_API + "/api/patients/update_locations/",
        {
          localization: arr,
          study: study.uid,
          patient: studyRef.current.patient.identifiant,
          target: studyRef.current.target.name,
          expert: user.username,
          patient_done: _patients[patientIndex].status,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      )
      .then(
        (_response) => {
          setOpenSnackBar(true);
        },
        (_error) => {
          alert("erreur enregistrement database");
        }
      );
  };
  const onLocationSelect = async (target) => {
    const grw1 = context.current[3].GLWindow;
    setTargetNote(target.note ? target.note.uid : 0);
    let name = target.uid;
    let localization = localizations.find((localization) => localization.uid === name);

    if (localization.x === 0 || localization.y === 0 || localization.z === 0) {
      if (target.defTarget && target.defTarget !== "") {
        if (studyRef.current.target.name === target.defTarget) {
          localization = studyRef.current.target.localization;
        } else if (defaultTargets.hasOwnProperty(target.defTarget)) {
          localization = defaultTargets[target.defTarget];
        }
      }
    }
    setDefaultTargets({
      ...defaultTargets,
      [studyRef.current.target.name]: studyRef.current.target.localization,
    });

    updateTextFields(localization);

    studyRef.current.target = target;
    studyRef.current.target.localization = localization;
    setRenderTargets([]);

    if (origin.current) {
      const originBrain = origin.current;
      var center = [90.5, 127.5, 127.5];
      if (localization.x !== 0 && localization.y !== 0 && localization.z !== 0) {
        center = [
          localization.x - originBrain[0],
          localization.y - originBrain[1],
          localization.z - originBrain[2],
        ];
      } else {
        studyRef.current.target.localization = {
          x: 90.5,
          y: 127.5,
          z: 127.5,
        };
      }
      sphere3D.current.setCenter(center);
      const widget = widgetRef.current;
      await widget.setCenter(center);
    }

    grw1.getRenderWindow().render();

    updateViews();
  };

  const onPatientSelect = async (patient, study_uid, patients) => {
    const container = document.getElementById('root')
    container.classList.add('vtk-progress-cursor');
    setIsLoading(true)
    setView("Plans");
    view3DTypeRef.current = "Plans"
    enablePeelingSlider(false);
    setMaskchecked(false);
    setScale(1.0);
    deleteResources();
    scaleRef.current = 1;
    const { access } = user;
    var localizations;
    var targets;
    maskIsAvailable.current = false;
    await axios
      .post(
        process.env.REACT_APP_API + "/api/get-targets/",
        {
          study: study_uid,
          expert: user.username,
          patient: patient.identifiant,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      )
      .then(async (response) => {
        localizations = await response.data.sort(
          (a, b) => a.targetId.index - b.targetId.index
        );
        setLocalizations(localizations);
        targets = localizations.map((loc) => ({
          ...loc.targetId,
          status: loc.status,
          note: loc.note,
          uid: loc.uid,
        }));
        setTargets(targets);
      })
      .catch((error) => console.log(error));
    await axios
      .get(process.env.REACT_APP_PATIENTS + patient.irm + "/mask.vti")
      .then((response) => {
        if (response.data.includes("VTKFile")) {
          maskIsAvailable.current = true;
        } else {
          maskIsAvailable.current = false;
        }
      })
      .catch((_error) => {
        maskIsAvailable.current = false;
      });
    var savedLoc = localizations.filter((loc) => loc.status === "DONE");
    var maxIndex =
      savedLoc.length > 0 && savedLoc.length !== localizations.length
        ? Math.max.apply(
          Math,
          savedLoc.map((o) => {
            return o.targetId.index;
          })
        )
        : 0;

    const current_patient_index = patients.findIndex(
      (_patient) => _patient.identifiant === patient.identifiant
    );

    setPatient({
      currentPatientIndex: current_patient_index,
      targetIndex: maxIndex,
    });
    setCurrentPatientIndex(current_patient_index);
    setTargetIndex(maxIndex);
    var lastSaved =
      savedLoc.length > 0
        ? savedLoc.find((o) => {
          return o.targetId.index === maxIndex;
        })
        : localizations[0];

    const final_target = {
      localization: {
        x: lastSaved.x,
        y: lastSaved.y,
        z: lastSaved.z,
      },
      ...lastSaved.targetId,
      status: lastSaved.status,
      note: lastSaved.note,
      uid: lastSaved.uid,
    };
    setTargetNote(lastSaved.note ? lastSaved.note.uid : 0);

    updateTextFields(final_target.localization);
    studyRef.current.target = final_target;
    studyRef.current.patient = patient;
    studyRef.current.targets = targets;
    const localization = {
      x: lastSaved.x,
      y: lastSaved.y,
      z: lastSaved.z,
    };
    setDefaultTargets({
      ...defaultTargets,
      [lastSaved.targetId.name]: localization,
    });
    const element = document.getElementById(
      "patient" +
      patients.findIndex((patient_) => patient_.identifiant === patient.identifiant)
    );
    const element2 = document.getElementById(
      "target" +
      targets.findIndex((tar) => tar.index === final_target.index)
    );
    $(".scroll_patients").mCustomScrollbar("scrollTo", element);
    $(".scroll_targets").mCustomScrollbar("scrollTo", element2);

  };

  const onStudySelect = async (study_uid, type, listSeq) => {
    if (type === "Evaluation") {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
    let actual_patient = {};
    const { access } = user;
    var patients_ = []
    await axios
      .post(
        process.env.REACT_APP_API + "/api/patients-study-expert/",
        { study: study_uid, username: user.username },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      )
      .then(async (response) => {

        patients_ = response.data.sort((a, b) => a.index - b.index);

        patients_ = patients_
          .map((res) => ({
            ...res.patientId,
            status: res.status,
            note: res.status_note,
            showSuppMesh: res.suppMesh,
            index: res.index,
            viewMode: res.viewMode
          })).sort((a, b) => a.index - b.index);
        setPatients(patients_);
      })
      .catch((error) => console.log(error));
    const first_patient = patients_[0];
    await axios
      .post(
        process.env.REACT_APP_API + "/api/current-patient/",
        {
          study: study_uid,
          expert: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      )
      .then(async (response) => {
        actual_patient = response.data
          ? response.data.patientId.index
            ? patients_.find(p => p.identifiant === response.data.patientId.identifiant)
            : first_patient
          : first_patient;
      })
      .catch((error) => console.log(error));
    return {
      patient: actual_patient,
      patients: patients_
    };
  };

  return (
    <Grid
      id="container"
      style={{
        height: "100vh",
        paddingLeft: isLargeScreen || isSmallScreen ? "2px" : "5px",
      }}
      container
    >
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-animation">
          </div>
        </div>
      )}
      <Grid item container xs={1.5}>
        <ListViewer
          showLoader={showLoader}
          localizations={localizations}
          ref={targetsListRef}
          currentPatientIndex={currentPatientIndex}
          targetIndex={targetIndex}
          disable={listDisable&&study.listSeq}
          onStudySelect={async (study_uid, type, listSeq) => {

            onStudySelect(study_uid, type, listSeq).then(async ({ patient, patients }) => {
              if (prevPatientRef.current.identifiant) {
                const prevIdentifiant = prevPatientRef.current.identifiant;
                cancel(prevIdentifiant);
              }
              await onPatientSelect(patient, study_uid, patients).then(async () => {
                loadFilesandViewer(patient, type, patients)
              })
            }
            );
          }}
          onLocationSelect={async (target) => {
            onLocationSelect(target);
          }}
          onPatientSelect={async (patient, study_uid) => {
            onPatientSelect(patient, study_uid, patients).then(async () => {
              loadFilesandViewer(patient, study._type, patients)
            });
          }}
        />
      </Grid>

      {false ? (
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          width={"50%"}
          height={"43%"}
        />
      ) : (
        <Grid
          item
          container
          xs={8}
          id="viewer"
          style={{ backgroundColor: "black" }}
        >
          <Grid
            sx={{ border: 1, borderColor: "white", paddingLeft: "1vh" }}
            item
            container
            xs={gridSagittal}
            style={{ display: !displaySagittal ? "none" : "block" }}
          >
            <Grid
              style={{ height: "0.7vh" }}
              container
              item
              xs={12}
            >
              <Grid item xs={5.75} />
              <Grid item container xs={0.5}>
                <p
                  style={{
                    color: "white",
                    marginTop: "0px",
                    fontFamily: "Zen Antique Soft",
                    fontSize: "2.5vh",
                    zIndex: "98",
                  }}
                >
                  H
                </p>
              </Grid>
              <Grid item container xs={5.75} justifyContent="flex-end" >
                <IconButton
                  onMouseUp={
                    onMouseUp
                  }
                  onMouseLeave={onMouseUp}
                  onMouseDown={(e) => onMouseDown("out", "zoom1", e)}
                  style={{ color: "white", zIndex: "98", height: "3vh" }}
                >
                  <ZoomOutIcon />
                </IconButton>
                <IconButton
                  onMouseDown={(e) => {
                    onMouseDown("in", "zoom1", e);
                  }}
                  onMouseUp={() => {
                    onMouseUp();
                  }}
                  onMouseLeave={onMouseUp}
                  style={{
                    color: "white",
                    zIndex: "98",
                    height: "3vh",
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container xs={12} >
              <Grid item container xs={0.5} direction="column">
                <Grid item xs={5.75}></Grid>
                <Grid item xs={0.5}>
                  <p
                    style={{
                      color: "white",
                      margin: "0",
                      fontFamily: "Zen Antique Soft",
                      fontSize: "2.5vh",
                      zIndex: "98",
                    }}
                  >
                    A
                  </p>
                </Grid>
                <Grid item xs={5.75}></Grid>
              </Grid>
              <Grid
                id="view1"
                item
                xs={11}
                style={{
                  height: heightSagittal,
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
                ref={sagittalContainer}
              />
              <Grid item container xs={0.5} direction="column">
                <Grid item xs={5.75}></Grid>
                <Grid item xs={0.5}>
                  <p
                    style={{
                      color: "white",
                      margin: "0",
                      fontFamily: "Zen Antique Soft",
                      fontSize: "2.5vh",
                      zIndex: "98",
                    }}
                  >
                    P
                  </p>
                </Grid>
                <Grid item xs={5.75}></Grid>
              </Grid>
            </Grid>
            <Grid style={{ height: "0.1vh" }} item container xs={12}>
              <Grid item xs={5.75}>
                <Typography
                  className="listCibles"
                  style={{
                    fontFamily: "Kanit"
                  }}
                  color={"white"}
                >
                  SAGITTAL
                </Typography>
              </Grid>
              <Grid item xs={0.5}>
                <p
                  style={{
                    color: "white",
                    margin: "0",
                    fontFamily: "Zen Antique Soft",
                    fontSize: "2.5vh",
                    zIndex: "98",
                  }}
                >
                  B
                </p>
              </Grid>
              <Grid item xs={5.75}>
              </Grid>
            </Grid>

          </Grid>

          <Grid
            sx={{ border: 1, borderColor: "white" }}
            item
            container
            xs={gridCoronal}
            style={{
              paddingLeft: "1vh",
              display: !displayCoronal ? "none" : "block",
            }}
          >
            <Grid
              style={{ height: "0.7vh" }}
              container
              item
              xs={12}
            >
              <Grid item xs={5.75} />
              <Grid container item xs={0.5}>
                <p
                  style={{
                    color: "white",
                    margin: "0",
                    fontFamily: "Zen Antique Soft",
                    fontSize: "2.5vh",
                    zIndex: "98",
                  }}
                >
                  H
                </p>
              </Grid>
              <Grid item xs={5.75} container justifyContent='flex-end'>
                <IconButton
                  onMouseUp={
                    onMouseUp
                  }
                  onMouseLeave={onMouseUp}
                  onMouseDown={(e) => onMouseDown("out", "zoom2", e)}
                  style={{ color: "white", zIndex: "98", height: "3vh" }}
                >
                  <ZoomOutIcon />
                </IconButton>
                <IconButton
                  onMouseDown={(e) => {
                    onMouseDown("in", "zoom2", e);
                  }}
                  onMouseLeave={onMouseUp}
                  onMouseUp={() => {
                    onMouseUp();
                  }}
                  style={{
                    color: "white",
                    zIndex: "98",
                    height: "3vh",
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item container xs={0.5} direction="column">
                <Grid item xs={5.75} />
                <Grid item xs={0.5}>
                  <p
                    style={{
                      color: "white",
                      position: "absolute",
                      margin: "0",
                      fontFamily: "Zen Antique Soft",
                      fontSize: "2.5vh",
                      zIndex: "98",
                    }}
                  >
                    D
                  </p>
                </Grid>
                <Grid item xs={5.75} />
              </Grid>

              <Grid
                id="view2"
                item
                xs={11}
                style={{ height: heightCoronal }}
                ref={coronalContainer}
                
              />
              <Grid item container xs={0.5} direction="column">
                <Grid item xs={5.75} />
                <Grid item xs={0.5}>
                  <p
                    style={{
                      color: "white",
                      position: "absolute",
                      margin: "0",
                      fontFamily: "Zen Antique Soft",
                      fontSize: "2.5vh",
                      zIndex: "98",
                    }}
                  >
                    G
                  </p>
                </Grid>
                <Grid item xs={5.75} />
              </Grid>
              <Grid></Grid>
            </Grid>
            <Grid style={{ height: "0.1vh" }} item container xs={12}>
              <Grid item xs={5.75}>
                <Typography
                  className="listCibles"
                  style={{
                    fontFamily: "Kanit"
                  }}
                  color={"white"}
                >
                  CORONAL
                </Typography>
              </Grid>
              <Grid item xs={0.5}>
                <p
                  style={{
                    color: "white",
                    margin: "0",
                    fontFamily: "Zen Antique Soft",
                    fontSize: "2.5vh",
                    zIndex: "98",
                  }}
                >
                  B
                </p>
              </Grid>
              <Grid item xs={5.75}>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{ border: 1, borderColor: "white", paddingLeft: "1vh" }}
            item
            container
            xs={gridAxial}
            style={{
              paddingRight: "1vh",
              display: !displayAxial ? "none" : "block",
            }}
          >
            <Grid
              style={{ height: "0.7vh" }}
              container
              item
              xs={12}
            >
              <Grid item container xs={5.75}></Grid>
              <Grid item container xs={0.5}>
                <p
                  style={{
                    color: "white",
                    zIndex: "98",
                    margin: "0",
                    fontFamily: "Zen Antique Soft",
                    fontSize: "2.5vh",
                  }}
                >
                  A
                </p>
              </Grid>
              <Grid item container xs={5.75} justifyContent="flex-end">
                <IconButton
                  onMouseDown={(e) => {
                    onMouseDown("out", "zoom3", e);
                  }}
                  onMouseUp={() => {
                    onMouseUp();
                  }}
                  onMouseLeave={onMouseUp} style={{
                    color: "white",
                    zIndex: "98",
                    height: "3vh",
                  }}
                >
                  <ZoomOutIcon />
                </IconButton>
                <IconButton
                  onMouseUp={onMouseUp}
                  onMouseDown={(e) => onMouseDown("in", "zoom3", e)}
                  onMouseLeave={onMouseUp} style={{
                    color: "white",
                    zIndex: "98",
                    height: "3vh",
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </Grid>

            </Grid>
            <Grid item container xs={12}>
              <Grid item container xs={0.5} direction="column">
                <Grid item xs={5.75} />
                <Grid item container xs={0.5}>
                  <p
                    style={{
                      color: "white",
                      zIndex: "98",
                      margin: "0",
                      fontFamily: "Zen Antique Soft",
                      fontSize: "2.5vh",
                    }}
                  >
                    D
                  </p>
                </Grid>
                <Grid item xs={5.75} />
              </Grid>
              <Grid
                id="view3"
                item
                xs={11}
                style={{ height: heightAxial, }}
                ref={axialContainer}
              />
              <Grid item container xs={0.5} direction="column">
                <Grid item xs={5.75}></Grid>
                <Grid item xs={0.5}>
                  <p
                    style={{
                      color: "white",
                      margin: "0",
                      fontFamily: "Zen Antique Soft",
                      fontSize: "2.5vh",
                      zIndex: "98",
                    }}
                  >
                    G
                  </p>
                </Grid>
                <Grid item xs={5.75}></Grid>
              </Grid>
            </Grid>
            <Grid style={{ height: "0.1vh" }} item container xs={12}>
              <Grid item xs={5.75}>
                <Typography
                  className="listCibles"
                  style={{
                    fontFamily: "Kanit"
                  }}
                  color={"white"}
                >
                  AXIAL
                </Typography>
              </Grid>
              <Grid item xs={0.5}>
                <p
                  style={{
                    color: "white",
                    margin: "0",
                    fontFamily: "Zen Antique Soft",
                    fontSize: "2.5vh",
                    zIndex: "98",
                  }}
                >
                  P
                </p>
              </Grid>
              <Grid item xs={5.75}>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{ border: 1, borderColor: "white" }}
            item
            container
            xs={grid3D}
            style={{ display: !display3D ? "none" : "block" }}
          >
            <Grid
              className="allViews"
              xs={12}
              item
              container
              style={{ height: "0.7vh", zIndex: "1" }}
            >
              <Grid xs={9} container item className="viewButtons" >
                <ToggleButtonGroup
                  color="primary"
                  value={view}
                  exclusive
                  onChange={async (event) => {
                    const newView = event.target.value;
                    const container = document.getElementById('vue3D');
                    container.classList.add('vtk-wait-cursor');

                    if (peelingState.current) {
                      if (peelingState.current.actor && peelingState.current.mapper) {
                        peelingState.current.actor.delete();
                        peelingState.current.mapper.delete();
                      }
                      if (peelingState.current.actor2 && peelingState.current.mapper2) {
                        peelingState.current.actor2.delete();
                        peelingState.current.mapper2.delete();
                      }
                      if (peelingState.current.filter) {
                        peelingState.current.filter.delete();
                      }
                      peelingState.current = null;
                    }

                    if (newView === "Cerveau" && newView !== view3DTypeRef.current) {

                      setButtonPeelingDisable(true);
                      setButtonCerveauDisable(true);
                      enablePeelingSlider(false)
                      setView(newView);
                      view3DTypeRef.current = "Cerveau"

                      setTimeout(async () => {

                        const viewUp = context.current[3].GLWindow.getRenderer()
                          .getActiveCamera()
                          .getViewUp();
                        const DirectionOfProjection =
                          context.current[3].GLWindow.getRenderer()
                            .getActiveCamera()
                            .getDirectionOfProjection();
                        if (view === "Peeling") {
                          await brainRender(viewUp, DirectionOfProjection, null);
                          container.classList.remove('vtk-wait-cursor');
                        }
                        else {
                          await brainRender()
                          container.classList.remove('vtk-wait-cursor');
                        }
                        setButtonPeelingDisable(false);
                        setButtonCerveauDisable(false);
                      }, 55);
                    } else if (newView === "Plans" && newView !== view3DTypeRef.current) {
                      view3DTypeRef.current = "Plans";
                      setView(newView);
                      setTimeout(async () => {
                        enablePeelingSlider(false);
                        const viewUp = context.current[3].GLWindow.getRenderer()
                          .getActiveCamera()
                          .getViewUp();
                        const DirectionOfProjection =
                          context.current[3].GLWindow.getRenderer()
                            .getActiveCamera()
                            .getDirectionOfProjection();
                        await plansRender(viewUp, DirectionOfProjection, study._type);
                        container.classList.remove('vtk-wait-cursor');
                      }, 50);
                    } else if (newView === "Peeling" && newView !== view3DTypeRef.current) {

                      setButtonPeelingDisable(true);
                      setButtonCerveauDisable(true);

                      setTimeout(async () => {
                        const viewUp = context.current[3].GLWindow.getRenderer()
                          .getActiveCamera()
                          .getViewUp();
                        const DirectionOfProjection =
                          context.current[3].GLWindow.getRenderer()
                            .getActiveCamera()
                            .getDirectionOfProjection();
                        if (view === "Cerveau") {
                          const position = context.current[3].GLWindow.getRenderer().getActiveCamera().getPosition();
                          await peelingRender(0.7, 0.7, viewUp, DirectionOfProjection, position);
                          container.classList.remove('vtk-wait-cursor');
                        }
                        else {
                          await peelingRender(0.7, 0.7);
                          container.classList.remove('vtk-wait-cursor');
                        }

                        setButtonPeelingDisable(false);
                        setButtonCerveauDisable(false);
                        if (peelingState.current.pd) {
                          view3DTypeRef.current = "Peeling"
                          setView(newView);
                        }
                      }, 50);

                    }
                  }}
                >
                  <ToggleButton
                    id="plansButton"
                    className="viewButton"
                    style={{
                      color: "white",
                      backgroundColor: view === "Plans" ? "#2589d9" : "#66A4D5",
                      zIndex: "98",
                      fontFamily: "Kanit",
                      fontSize: "1.4vh"
                    }}
                    value="Plans"
                  >
                    Plans
                  </ToggleButton>
                  <ToggleButton
                    disabled={buttonCerveauDisable}
                    id="CerveauButton"
                    className="viewButton"
                    style={{
                      color: "white",
                      backgroundColor: view === "Cerveau" ? "#2589d9" : "#66A4D5",
                      zIndex: "98",
                      fontFamily: "Kanit",
                      fontSize: "1.4vh"
                    }}
                    value="Cerveau"
                  >
                    Cerveau

                  </ToggleButton>
                  <ToggleButton
                    disabled={buttonPeelingDisable}
                    id="PeelingButton"
                    className="viewButton"
                    style={{
                      color: "white",
                      backgroundColor: view === "Peeling" ? "#2589d9" : "#66A4D5",
                      zIndex: "98",
                      fontFamily: "Kanit",
                      fontSize: "1.4vh"
                    }}
                    value="Peeling"
                  >
                    Peeling
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid
              id="vue3D"
              className="vue3D"
              style={{ height: height3D }}
              xs={12}
              item
              ref={vue3DRef}
            />
          </Grid>
          <Snackbar
              open={openSnackbar} react
              sx={{ position: "absolute", top: "95%", marginLeft: "39%" }}
              autoHideDuration={3500}
              onClose={handleCloseSnackBar}
            >
              <Alert onClose={handleCloseSnackBar} severity="success">
                Pointage enregistré!
              </Alert>
            </Snackbar>
            <Snackbar
              open={openSnackbar1}
              sx={{ position: "absolute", top: "95%", marginLeft: "39%" }}
              autoHideDuration={null}
              onClose={handleCloseSnackBar}
            >
              <Alert onClose={handleCloseSnackBar} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={openSnackbar2} react
              sx={{ position: "absolute", top: "95%", marginLeft: "39%", zIndex:100 }}
              autoHideDuration={3500}
              onClose={handleCloseSnackBar}
            >
              <Alert onClose={handleCloseSnackBar} severity="info" >
                {informationMessage}
              </Alert>
            </Snackbar>
        </Grid>
      )}
      <Grid item container xs={2.5}>
        <Grid item container xs={12} direction="column" >
          <Grid item container xs={0.5}>
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "#85B2D9" }}
            >
              <Box
                component="img"
                sx={{
                  height: isSmallScreen ? "1vh" : "5vh",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  margin: "0",
                }}
                alt="comet web."
                src={process.env.REACT_APP_STATIC + "images/syneika-rem.png"}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} sx={{ height: "40vh", maxHeight: "40vh" }}>
            <Grid
              sx={{ paddingLeft: ".3vh" }}
              container
              item
              xs={12}
            >
              <h2 className="listTitles" style={{ marginBottom: "0.1vh" }}>
                <img alt="icon" src={process.env.REACT_APP_STATIC + 'images/view.png'} style={{ width: "3vh", position: "relative", top: "0.6vh" }} />
                {" "}
                Affichage
              </h2>
              <Box sx={{ border: 1, width: "98%" }}>
                <Select
                  className="selectVue"
                  value={viewMode}
                  label="studies"
                  input={<OutlinedInput sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} label="Tag" />}
                  style={{
                    width: "95%",
                    height: "4vh",
                    marginLeft: "1vh",
                  }}
                  size={"small"}
                  onChange={async (event) => {
                    const newMode = event.target.value;
                    setViewMode(newMode);
                    if (newMode === 0) {
                      view3DTypeRef.current = "Plans"
                      setHeighSagittal("42.5vh");
                      setHeightAxial("42.5vh");
                      setHeightCoronal("42.5vh");
                      setHeight3D("48.5vh");
                      setDisplayCoronal(false);
                      setDisplaySagittal(true);
                      setDisplay3D(false);
                      setDisplayAxial(false);
                      setGrid3D(0);
                      setGridCoronal(0);
                      setGridSagittal(12);
                      setGridAxial(0);

                      isMediumScreen
                        ? setHeighSagittal("80vh")
                        : isSmallScreen
                          ? setHeighSagittal("45vh")
                          : setHeighSagittal("90vh");
                    } else if (newMode === 1) {
                      view3DTypeRef.current = "Plans"
                      setHeighSagittal("42.5vh");
                      setHeightAxial("42.5vh");
                      setHeightCoronal("42.5vh");
                      setHeight3D("48.5vh");

                      setDisplayCoronal(true);
                      setDisplaySagittal(false);
                      setDisplay3D(false);
                      setDisplayAxial(false);
                      setGrid3D(0);
                      setGridCoronal(12);
                      setGridSagittal(0);
                      setGridAxial(0);
                      isMediumScreen
                        ? setHeightCoronal("80vh")
                        : isSmallScreen
                          ? setHeightCoronal("45vh")
                          : setHeightCoronal("90vh");
                    }
                    if (newMode === 2) {
                      view3DTypeRef.current = "Plans"
                      setHeighSagittal("42.5vh");
                      setHeightAxial("42.5vh");
                      setHeightCoronal("42.5vh");
                      setHeight3D("48.5vh");

                      setDisplayCoronal(false);
                      setDisplaySagittal(false);
                      setDisplay3D(false);
                      setDisplayAxial(true);
                      setGrid3D(0);
                      setGridCoronal(0);
                      setGridSagittal(0);
                      setGridAxial(12);
                      isMediumScreen
                        ? setHeightAxial("80vh")
                        : isSmallScreen
                          ? setHeightAxial("45vh")
                          : setHeightAxial("90vh");
                    }
                    if (newMode === 3) {
                      setDisplayCoronal(true);
                      setDisplayAxial(true);
                      setDisplaySagittal(true);
                      setDisplay3D(true);
                      setGridSagittal(6);
                      setGridAxial(6);
                      setGridCoronal(6);
                      setGrid3D(6);
                      setHeighSagittal("42.5vh");
                      setHeightAxial("42.5vh");
                      setHeightCoronal("42.5vh");
                      setHeight3D("48.5vh");
                    }
                    if (newMode === 4) {
                      setDisplayCoronal(false);
                      setDisplayAxial(false);
                      setDisplaySagittal(false);
                      setDisplay3D(true);
                      setGridSagittal(0);
                      setGridAxial(0);
                      setGridCoronal(0);
                      setGrid3D(12);
                      isMediumScreen
                        ? setHeight3D("80vh")
                        : isSmallScreen
                          ? setHeight3D("45vh")
                          : setHeight3D("90vh");
                    }
                  }}
                >
                  <MenuItem key={0} value={0}>
                    <Typography
                      sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                      Vue sagittale
                    </Typography>
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    <Typography
                      sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                      Vue Coronale
                    </Typography>
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    <Typography
                      sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                      Vue axiale
                    </Typography>
                  </MenuItem>
                  <MenuItem key={3} value={3}>
                    <Typography
                      sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                      3 vues 2D + Vue 3D
                    </Typography>
                  </MenuItem>
                  <MenuItem key={3} value={4}>
                    <Typography
                      sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                      Vue 3D
                    </Typography>
                  </MenuItem>
                </Select>

                <FormControlLabel
                  style={{ marginLeft: "0.08vh" }}
                  control={
                    <Checkbox
                      disabled={!maskIsAvailable.current}
                      className="affichage_text"
                      onChange={handleMask}
                      checked={maskchecked}
                    />
                  }
                  label={
                    <Typography className="affichage_text" style={{ fontFamily: "Kanit" }}>
                      MASQUE
                    </Typography>
                  }
                />
                <br />
                <Button
                  size="small"
                  variant="contained"
                  style={{ marginLeft: "1vh", backgroundColor: "#66A4D5" }}
                  onClick={async () => {
                    for (var i = 0; i < 3; i++) {
                      context.current[i].resliceActor
                        .getProperty()
                        .setColorWindow(defaultColorWindow.current);
                      context.current[i].resliceActor
                        .getProperty()
                        .setColorLevel(defaultColorLevel.current);
                    }

                    if (view === "Peeling" && peelingState.current) {
                      const viewUp = context.current[3].GLWindow.getRenderer()
                        .getActiveCamera()
                        .getViewUp();
                      const DirectionOfProjection =
                        context.current[3].GLWindow.getRenderer()
                          .getActiveCamera()
                          .getDirectionOfProjection();
                      const position = context.current[3].GLWindow.getRenderer().getActiveCamera().getPosition();

                      await peelingRender(1, scaleRef.current, viewUp, DirectionOfProjection, position);
                    }
                    updateViews();
                  }}
                >
                  <Typography className="affichage_text" style={{ fontFamily: "Kanit" }}>
                    Fenêtrage par défaut
                  </Typography>
                </Button>
                <br />
                <Grid container sx={{ paddingTop: "1vh", paddingLeft: "1vh" }} item>
                  <Typography className="affichage_text" style={{ fontFamily: "Kanit" }}>PEELING</Typography>
                </Grid>
                <Grid className="peeling" item container spacing={6} sx={{ paddingLeft: "1vh" }}>
                  <Grid className="peeling" item container xs={5.5}>
                    <Grid sx={{ paddingTop: "1vh" }} item xs={12}>
                      <Typography className="affichage_text" style={{ fontFamily: "Kanit" }}>
                        PROFONDEUR
                      </Typography>
                    </Grid>
                    <Grid sx={{ marginLeft: "1vh" }} item container xs={12}>
                      <Grid container item xs={12}>
                        <IconButton
                          ref={buttonPlusRef}
                          size="small"
                          disabled={!peelingSliderEnabeled}
                          onClick={() => {
                            if (scale > 0.6) {
                              const newScale = scale - 0.02;
                              peelingHandleChange(newScale)
                            }
                          }}
                        >
                          <FaMinus />
                        </IconButton>
                        <IconButton
                          ref={buttonMinusRef}
                          size="small"
                          disabled={!peelingSliderEnabeled}
                          onClick={() => {
                            if (scale < 1) {
                              const newScale = scale + 0.02;
                              //setScale(newScale)
                              peelingHandleChange(newScale)
                            }
                          }}
                        >
                          <FaPlus />
                        </IconButton>
                      </Grid>
                      <Grid container item xs={12}>
                        <Slider
                          ref={sliderRef}
                          onMouseUp={() => peelingHandleChange()}
                          size="small"
                          marks
                          disabled={!peelingSliderEnabeled}
                          defaultValue={1}
                          valueLabelDisplay="auto"
                          onChange={(_event, newValue) => { setScale(newValue) }}
                          step={0.02}
                          min={0.6}
                          max={1}
                          value={scale.toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={5.5}>
                    <Grid sx={{ paddingTop: "1vh" }} item xs={12}>
                      <Typography className="affichage_text" style={{ fontFamily: "Kanit" }}>HAUTEUR</Typography>
                    </Grid>
                    <Grid sx={{ marginLeft: "1vh" }} item container xs={12}>
                      <Grid container item xs={12}>
                        <IconButton
                          disabled={!peelingSliderEnabeled}
                          onClick={() => {
                            // $("*").css("cursor", "progress");
                            setTimeout(() => {
                              if (zCut > 30) {
                                const newZcut = zCut - 2;
                                clipSkin(newZcut);
                                setZcut(newZcut)
                              }
                              //$("*").css("cursor", "pointer");
                            }, 55);
                          }}
                          size="small"
                        >
                          <FaMinus />
                        </IconButton>
                        <IconButton
                          disabled={!peelingSliderEnabeled}
                          onClick={() => {
                            //  $("*").css("cursor", "progress");
                            setTimeout(() => {
                              if (zCut < 215) {
                                const newZcut = zCut + 2;
                                clipSkin(newZcut);
                                setZcut(newZcut);
                              }
                              //   $("*").css("cursor", "pointer");
                            }, 55);
                          }}
                          size="small"
                        >
                          <FaPlus />
                        </IconButton>
                      </Grid>
                      <Grid container item xs={12}>
                        <Slider
                          size="small"
                          // marks
                          onMouseUp={() => clipSkin()}
                          disabled={!peelingSliderEnabeled}
                          defaultValue={1}
                          valueLabelDisplay="auto"
                          onChange={(_event, newValue) => {
                            setZcut(newValue);
                          }}
                          value={zCut.toFixed(2)}
                          step={1.0}
                          min={zMinSkin}
                          max={zMaxSkin}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={1.3} sx={{ height: "9vh", maxHeight: "9vh" }}>
            {study._type === "Evaluation" ? (
              <Grid
                container
                item
                xs={12}
              >
                <h2 className="listTitles"><img alt="icon" src={process.env.REACT_APP_STATIC + 'images/check-list.png'} style={{ width: "3vh", position: "relative", top: "0.6vh" }} /> Note</h2>
                <Grid item container xs={12}>
                  <Grid item xs={2} />
                  <Grid item container xs={8}>
                    <Select
                      style={{
                        width: "95%",
                        height: "3vh",
                        marginLeft: "1vh",
                      }}
                      value={targetNote}
                      label="Note"
                      onChange={targetNoteHandleChangle}
                    >
                      {study.notesChoices.map((note) =>
                        <MenuItem value={note.uid}>
                          <Typography
                            sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                            {note.label}
                          </Typography>
                        </MenuItem>
                      )}


                    </Select>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid container xs={5.5} item sx={{
            placeItems: "center",
            paddingLeft: ".3vh",
            height: "47vh", maxHeight: "47vh"
          }}
          >
            <h2 className="listTitles" style={{ marginBottom: "0.1vh" }}>
              <img alt="icon" src={process.env.REACT_APP_STATIC + 'images/brain.png'} style={{ width: "3vh", position: "relative", top: "0.6vh" }} />
              {" "}
              Pointage
            </h2>
            <Box sx={{ border: 1, width: "98%", paddingBottom: "2vh" }}>
              <h4
                style={{
                  lineHeight: "1.2",
                  textAlign: "center",
                  letterSpacing: "0.2px",
                }}
              >
                <CustomWidthTooltip
                  title={
                    <Typography>
                      {studyRef.current
                        ? studyRef.current.target.name
                        : ""}
                    </Typography>
                  }
                >
                  <span>
                    {studyRef.current
                      ? studyRef.current.target.name
                        ? studyRef.current.target.name.length < 18
                          ? studyRef.current.target.name
                          : studyRef.current.target.name.substring(0, 18) + "..."
                        : null
                      : null}
                  </span>
                </CustomWidthTooltip>

                {study._type === "Pointage" && <Tooltip sx={{zIndex: "999",}} title="Revenir à la position enregistrée">
                  <IconButton
                    size="small"
                    onClick={() => {
                      let _targets = targets;
                      const target = _targets.find(
                        (target) => target.uid === studyRef.current.target.uid
                      );
                      const targetId = _targets.findIndex(
                        (target) => target.uid === studyRef.current.target.uid
                      );
                      if (target.status === "IN PROGRESS") {
                        onLocationSelect(studyRef.current.target);
                        _targets[targetId].status =
                          studyRef.current.target.status_previous;
                        setTargets(_targets);
                        studyRef.current.targets = _targets;
                        setRenderTargets(_targets);
                        studyRef.current.target.status =
                          studyRef.current.target.status_previous;
                      }
                    }}
                    variant="contained"
                    style={{
                      backgroundColor: "#66A4D5",
                      marginLeft: "1vh",
                      marginTop: "0.2vh",
                      color: "white"
                    }}
                  >
                    <BiReset />
                  </IconButton>
                </Tooltip>}
                <br />

                <Box style={{ maxHeight: "8vh", height: "7.5vh", overflow: "auto", marginLeft: '2vh', marginRight: '2vh' }}>
                  <span className="list_cbile_description" style={{ maxHeight: "7.5vh" }}>
                    {studyRef.current
                      ? studyRef.current.target.description
                      : null}
                  </span>
                </Box>

              </h4>
              <Grid item container xs={12}>
                <Grid item xs={3.5} />
                <Grid item xs={5}>
                  <Grid item xs={10} sx={{ display: "flex", paddingBottom: "0.8vh" }}>
                    <Typography
                      className="localisation"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      X:
                    </Typography>{" "}
                    <TextField
                      disabled={study._type === "Evaluation"}
                      onChange={(e) => handleTextFieldChangleLocation(e, "x")}
                      inputProps={{
                        style: { fontSize: "2.1vh", marginLeft: "1vh" },
                      }}
                      variant="standard"
                      inputRef={xText}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{ display: "flex", paddingBottom: "0.8vh" }}>
                    <Typography
                      className="localisation"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Y:
                    </Typography>{" "}
                    <TextField
                      disabled={study._type === "Evaluation"}
                      onChange={(e) => handleTextFieldChangleLocation(e, "y")}
                      inputProps={{
                        style: { fontSize: "2.1vh", marginLeft: "1vh" },
                      }}
                      variant="standard"
                      inputRef={yText}
                    />
                  </Grid>
                  <Grid item xs={10} sx={{ display: "flex", paddingBottom: "2vh" }}>
                    <Typography
                      className="localisation"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Z:
                    </Typography>{" "}
                    <TextField
                      disabled={study._type === "Evaluation"}
                      onChange={(e) => handleTextFieldChangleLocation(e, "z")}
                      inputProps={{
                        style: { fontSize: "2.1vh", marginLeft: "1vh" },
                      }}
                      variant="standard"
                      inputRef={zText}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                lg={12}
              >
                <Grid item sm={0.5} lg={1.25} xs={1} />

                <Grid item xs={2.5} sm={2} lg={2.5}>
                  <Tooltip title="Précedent">
                    <Button
                      className="svg_icons"
                      style={{
                        position: "relative",
                        marginLeft: isSmallScreen ? "0" : "auto",
                        marginRight: isSmallScreen ? "0" : "auto",
                        backgroundColor: study.listSeq ? "gray" : "#66A4D5",
                        color: "white",
                      }}
                      size={isSmallScreen || isMediumScreen ? "small" : "large"}
                      disabled={study.listSeq}
                      onClick={() => {
                        targetsListRef.current.decrement();
                      }}
                    >
                      <FaCaretLeft />
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid item xs={1.5} sm={1.5} lg={1} />

                <Grid item xs={2.5} sm={2} lg={2.5}>
                  <Tooltip title="Enregistrer">
                    <div>
                      <Button
                        className="svg_icons"
                        disabled={disableSaveButton}
                        onClick={saveLocations}
                        variant="contained"
                        style={{
                          position: "relative",
                          marginLeft: isSmallScreen ? "0" : "auto",
                          marginRight: isSmallScreen ? "0" : "auto",
                          backgroundColor: disableSaveButton ? "gray" : "#66A4D5",
                          color: "white",
                        }}
                        size={isSmallScreen || isMediumScreen ? "small" : "large"}
                      >
                        <FaSave />
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>

                <Grid item xs={1} sm={1.5} lg={1} />

                <Grid item sm={1.5} lg={2.5} xs={2.5}>
                  <Tooltip title="Suivant">
                    <Button
                      className="svg_icons"
                      style={{
                        position: "relative",
                        marginRight: isSmallScreen ? "0" : "auto",
                        marginLeft: "0",
                        backgroundColor: "#66A4D5",
                        color: "white",
                      }}
                      size={isSmallScreen || isMediumScreen ? "small" : "large"}
                      disabled={nextPreviousDisable}
                      onClick={() => {
                        const loc = studyRef.current.target.localization;
                        if (study._type === "Evaluation") {
                          if (study.listSeq) {
                            if (targetNote !== 0) {
                              targetsListRef.current.increment({
                                localization: [loc.x, loc.y, loc.z],
                                target_name: studyRef.current.target.name,
                              });
                            }
                            else {
                              setInformationMessage("Veuillez donner une note!")
                              setOpenSnackBar2(true)

                            }
                          }

                        }
                        else {
                          targetsListRef.current.increment({
                            localization: [loc.x, loc.y, loc.z],
                            target_name: studyRef.current.target.name,
                          });
                        }

                      }}
                      variant={isSmallScreen ? "text" : "contained"}
                    >
                      <FaCaretRight />
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid item xs={1} sm={2} lg={1.25} />
              </Grid>
              <Grid
                style={{
                  position: "relative",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                item
                container
                xs={12}
              >
                <Grid item xs={1} md={1} xl={2} sm={1} lg={2} />
                <Grid item container xs={11} md={11} xl={8}></Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container xs={0.2} sx={{ height: "2vh", maxHeight: "2vh" }} justifyContent="center"
          >


            <Tooltip title="Aide">
              <HelpViewer type={study._type}/>
            </Tooltip>
            <Tooltip title="Déconnexion">
              <IconButton
                onClick={() => {
                  setUser({});
                  navigate("/");
                }}
                sx={{ color: "#66A4D5" }}
                size="medium"
              >
                <ExitToApp
                  className="svg_icons"
                  color="#66A4D5"
                  fontSize="medium"
                />
              </IconButton>
            </Tooltip>
            <div>
              <Dialog
                display="flex"
                maxWidth="lg"
                open={openHelp}
                keepMounted
                onClose={() => {
                  setOpenHelp(false);
                }}
              >
                <DialogTitle>{<Typography style={{ fontFamily: 'Kanit', fontSize: "3vh", fontWeight: "bold" }}>Instructions d'utilisation</Typography>}</DialogTitle>
                <DialogContent style={{ width: "400" }}>
                  <Help textColor="#85B2D9" inViewer="true" />
                </DialogContent>
              </Dialog>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  study: state.study.currentStudy,
  patients: state.patients.patients,
  targets: state.targets.currentTargets,
  patient: state.patient.currentPatient,
});

const mapDispatchToProps = (dispatch) => ({
  setStudy: (study) => dispatch(setStudy(study)),
  setPatients: (assoc) => dispatch(setPatients(assoc)),
  setTargets: (targets) => dispatch(setTargets(targets)),
  setPatient: (patient) => dispatch(setPatient(patient)),
  setUser: (user) => dispatch(setUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(VolumeViewer);
