import studyTypes from "./study-types";
import setStudy from "./study-utils";

const INTIAL_STATE = {
    currentStudy:null,
};

const studyReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case studyTypes.SET_STUDY:
            return{
                ...state,
                currentStudy: action.payload
            };
        case studyTypes.SET_VALUES:
                return{
                    ...state,
                    currentStudy: setStudy(state.currentStudy, action.payload)
                }
        case studyTypes.CLEAR_STUDY:
            return{
                currentStudy: null
            };
        default:
            return state;
    }
};

export default studyReducer;