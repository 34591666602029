import loadingTypes from "./loading-types";


const INTIAL_STATE = {
    loading:false, //section name
};

const loadingReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case loadingTypes.SET_LOADING:
            return{
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
};

export default loadingReducer;