import * as React from 'react';
import {connect} from 'react-redux';
import {Grid, Typography, Box, IconButton, Alert, TextField, Button, Select, MenuItem} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { ArrowBack} from '@mui/icons-material/';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';



function NewPatient({user, hideLoader, showLoader, setPatient}) {
    const [lastUid, setLastUid] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const defaultIRM = "/Patients/"
    const [studies, setStudies] = React.useState([]);
    const [studyName, setStudyName] = React.useState([]);
    const [selectedFolder, setSelectedFolder] = React.useState({});
    const [folders, setFolders] = React.useState([]);
    const navigate = useNavigate();
    const [newpatient, setnewPatient] = React.useState({
      irm: defaultIRM,
      identifiant: "000",
      studies: [],
    });

   



    const handleChangeMultiple = (event) => {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      setStudyName(value);
      setnewPatient({...newpatient, studies: value});
  
    };

    //Vérification des valeurs 

    const validation = () => {
      if (newpatient.identifiant) {
        return true;
      }
      else {
        setErrorMessage('Veuillez renseigner toutes les informations nécessaires!')
        return false;
      }
    }
    function isNumeric(value) {
      return /^\d+$/.test(value);
  }
  
      React.useEffect( () => {
        showLoader()
        const {access, is_staff} = user || {};
        !access&&!is_staff?navigate("/admin/login"):void(0);


        //style de scroll bar
        $('.scroll').mCustomScrollbar({
          scrollButtons:{enable:true},
          keyboard:{scrollType:"stepless"},
          mouseWheel:{scrollAmount:150},
          theme:"rounded-dark",
          autoExpandScrollbar:true,
        });
        async function fetchData(){
          //Récuperation des chemins des IRMs
          await axios.get(process.env.REACT_APP_API +'/api/listDirectories/',
          {
            headers: {
              Authorization: `Bearer ${access}`
            }
          },
          ).then(
            (response) => {
                setFolders(response.data.sort((a,b) => a - b))
            }
        ).catch((error)=>{console.log(error);})

        //Toutes les études
        await axios.get(process.env.REACT_APP_API +'/api/all-studies/',
        {
          headers: {
            Authorization: `Bearer ${access}`
          }
        }, ).then(
              (response) => {
                  setStudies(response.data)
              }
          ).catch((error)=>{console.log(error);})

          //Récuperation de l uid de dernier patient dans la base données
          await axios.get(process.env.REACT_APP_API +'/api/last-uid/', 
          {
            headers: {
              Authorization: `Bearer ${access}`
            }
          },).then(
              (response) => {
                var index = (parseInt(response.data));
                ++index;
                const i = index < 10 ? "00"+index : index < 100 ? "0"+index : index;
                setLastUid(i)
                setnewPatient({...newpatient,identifiant:i, irm: defaultIRM+i});
              }
          ).catch((error)=>{console.log(error);})

        }

        fetchData();
        hideLoader();
        }, []);
  
        
      const delay = ms => new Promise(res => setTimeout(res, ms));

      const submitHandle = async () =>{
        const {access} = user || {};
        if(validation())
        {
          // Ajouter le patient dans la base de données
        await axios.post(process.env.REACT_APP_API +'/api/new_patient/', newpatient, 
        {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        },).then(
              (response) => {
                  var i = isNumeric(newpatient.identifiant) ?parseInt(newpatient.identifiant): lastUid;
                  if(isNumeric(newpatient.identifiant)) ++i;
                  const identifiant = i < 10 ? "00" + i : i<100? "0"+i : i;
                  setLastUid(i)
                  setSelectedFolder(0);
                  setnewPatient({...newpatient, identifiant: identifiant,

                  });
                  setSuccessMessage("Patient ajouté avec succes!")
                  setErrorMessage('')
              }
          ).catch((error)=>{
            console.log(error);
            setErrorMessage("Un patient avec le même identifiant existe déja");})
          await delay(3000);
          setSuccessMessage("")
        }
  
      }


  
      const handleChange = (event) => {
          const {name, value} = event.target;
          setnewPatient({...newpatient, [name]: value});
      }


    return (
      <div style={{backgroundColor: "#0e2852", height:"100vh" }}>
     <Navbar isConnected/>
     <Grid container xs={12} style={{marginTop:"10%"}}>
       <Grid item xs={1.25}/>
         <Grid item xs={2} style={{marginTop:"20px", paddingRight:"20px",  paddingLeft:"20px"}}>
        <Box style={{marginLeft:"40%"}}>
        <IconButton sx={{ color: '#FFFF'}} onClick={()=>navigate("/admin/patients")}  >
        <ArrowBack fontSize="large"/>
        </IconButton>
        </Box>
        </Grid>
        <Grid item xs={5}>
        <Box style={{overflowY: "scroll",/*"hidden",*/ backgroundColor:"white", borderRadius:"15px",
     paddingTop:"50px", paddingLeft:"55px", paddingBottom:"50px", maxHeight:"400px"}} /*className='scroll'*/ >
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Identifiant:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField size='small'  style={{width:'50vh'}} value={newpatient.identifiant}
          name="identifiant" onChange={(e) => handleChange(e) }/>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Chemin IRM:</Typography>
          </Grid>
          <Grid item xs={8}>
          <Select
          label="Patients"
          size='small' style={{width:'50vh'}} 
          value={selectedFolder?? ""}
          name="patients" 
          onChange={(e) => {
            const index = e.target.value;
            const folder = folders[index]
            setSelectedFolder(index);
            setnewPatient({...newpatient, irm:defaultIRM+folder})
          }}
        >
          {folders?folders.map((folder,i) => (
          <MenuItem key={i} value={i}>Patients/{folder}/</MenuItem>
          )) : null}
        </Select>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Études:</Typography>
          </Grid>
          <Grid item xs={8} style={{
            paddingRight:"15px"
          }}>
        <Select
          multiple
          native
          value={studyName}
          fullWidth
          // @ts-ignore Typings are not considering `native`
          onChange={handleChangeMultiple}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
          {studies?studies.map((study) => (
            <option key={study.name} value={study.name}>
              {study.name}
            </option>
          )) : null}
        </Select>
          </Grid>
        </Grid>
        { errorMessage ?
          (<Alert severity="error" className="alert">{errorMessage}</Alert>) : null}
        { successMessage ?
          (<Alert severity="success" className="alert">{successMessage}</Alert>) : null} 
        <Button style={{float: "right", marginRight:"15px"}} variant="contained" onClick={()=>submitHandle()}>Ajouter</Button>
    </Box>
        </Grid>
        
      <Grid item xs={1}></Grid>
       </Grid>
      </div>
                 
            )
    
}


const mapStateToProps = state => ({
  user: state.user,
});


export default connect(mapStateToProps, null)(NewPatient);