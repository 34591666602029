import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Restore } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { connect } from 'react-redux';
import $ from 'jquery';
import axios from 'axios';

const ResetStudy = ({ study_uid, user }) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedArray, setSelectedArray] = useState([{}]);
  const [experts, setExperts] = useState([])

  const handleCheckboxChange = (index) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [index]: !prevOptions[index],
    }));
   
  };

  const areAnySelected = Object.values(selectedOptions).some(selected => selected);

  const handleConfirm = () => {
    // Example logic to generate the confirmation message
    setShowConfirmationDialog(true)
    setSelectedArray(Object.keys(selectedOptions)
    .filter(selectedIndex => selectedOptions[selectedIndex])
    .map(selectedIndex => experts[selectedIndex]))
  };
  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOptions({});
    setShowConfirmationDialog(false)
  };

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }
  const getExperts = async () => {

    const {access} = user
    await axios.post(process.env.REACT_APP_API +"/api/experts-study/",{study: study_uid},
        {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        }, ).then( (response) => {
            console.log(response.data);
            setExperts(response.data)
        }    
        ).catch((error)=>{
            console.log(error);

        }

        )
  }

  const handleConfirmation = async () => {
    console.log(selectedArray);
    handleClose()
    handleConfirmationDialogClose()
    const {access} = user
    await axios.post(process.env.REACT_APP_API +"/api/reset-evaluation-study/",{study_uid: study_uid, users_uids: selectedArray.map((expert)=>expert.uid)},
        {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        }, ).then( (response) => {
            console.log(response.data);
        }    
        ).catch((error)=>{
            console.log(error);

        }

        )

   


  }
  return (
    <>
     <IconButton onClick={() => {getExperts(); setOpen(true)}}>
                    <Restore/>
                </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Réinstaller l'étude</DialogTitle>
        <DialogContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <DialogContentText>
           Sélectionnez les participants dont vous voulez réinstaller l'étude:
          </DialogContentText>
          {experts.sort((a,b)=> a.user.first_name.localeCompare(b.user.first_name)).map((item, index) => (
            <FormControlLabel
            sx={{width:"100%"}}
              key={index}
              label={`${item.user.first_name} ${item.user.last_name}`}
              control={
                <Checkbox
                  checked={selectedOptions[index] || false}
                  onChange={() => handleCheckboxChange(index)}
                />
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button disabled={ !areAnySelected} onClick={handleConfirm} variant="contained" sx={{backgroundColor:"#0e2852"}}>
            Confirmer
          </Button>
          <Button onClick={handleClose}  color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Display your confirmation message here */}
              Êtes-vous sûr de vouloir réinstaller les données de l'étude pour les participants sélectionnés?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmation} color="primary">
            Oui
          </Button>
          <Button onClick={handleConfirmationDialogClose} color="primary">
            Non
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};



  

const mapStateToProps = state => ({
user: state.user,
})
export default connect(mapStateToProps)(ResetStudy);