import * as React from 'react';
import { Grid, Typography, Button, Box, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import Help from './Help';
import HelpIcon from "@mui/icons-material/Help";
import Navbar from '../Components/Navbar';





const Home = ({ user, hideLoader, }) => {
  const scrollToRef = React.useRef(null)

  const [logged, setLogged] = React.useState(null);
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  //  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  let navigate = useNavigate();

  React.useEffect(
    () => {
      const { access, is_staff } = user;
      access && !is_staff ? setLogged(true) : setLogged(false);


      hideLoader();
    }, []);


  return (
    <div >
      <Grid style={{ backgroundColor: "#85B2D9", }} container sx={{ height: "100vh" }} >
        <Navbar isConnected={logged} isExpert />
        <Grid item container>
          <Grid item container md={1} sm={1} lg={1} />
          <Grid item container xs={10} md={10} sm={10} lg={10} style={{ marginBottom: '20vh' }} direction="column">
            <Grid >
              <Box
                component="img"
                sx={{
                  height: isMediumScreen ? "65vh" : "120%",
                  margin: 'auto',
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "15px",
                  filter: "brightness(50%)"
                }}
                alt="comet web."
                src={process.env.REACT_APP_STATIC + "images/comet.png"}
              >
              </Box>
              <Typography style={{
                fontSize: "6vh",
                color: "white",
                textAlign: "center",
                position: "absolute",
                fontFamily: "Kanit",
                top: "35%",
                width: "85vh",
                margin: "0 auto",
                left: "28%",
                lineHeight: "120%",
                textShadow: "black 0px 0px 20px"
              }}>
                L'outil des experts pour le pointage de cibles cérébrales
              </Typography>
              <Button variant="contained" style={{
                color: "#1583EA",
                position: "absolute",
                top: "65%",
                width: "39vh",
                margin: "0 auto",
                left: "39%"
              }}
                onClick={() => { navigate('/expert/login');
                console.log(scrollToRef.current); }} >
                <Typography style={{
                  color: "white", fontSize: "3vh", fontFamily: 'Kanit'
                }}>
                  Connexion
                </Typography>
              </Button>
              <IconButton

                onClick={() => {
                  scrollToRef.current.scrollIntoView();
                }}
                sx={{
                  color: "#1583EA",
                  position: "absolute",
                  top: "65%",
                  margin: "0 auto",
                  left: "59%"
                }}
                size="medium"
              >
                <HelpIcon
                  className="svg_icons"
                  fontSize="large"
                  htmlColor="white"
                />
              </IconButton>
            </Grid>
            <Grid>
              <a class="mouse_scroll"
                href='#aide'>

                <div class="mouse">
                  <div class="wheel"></div>
                </div>
                <div>
                  <span class="m_scroll_arrows unu"></span>
                  <span class="m_scroll_arrows doi"></span>
                  <span class="m_scroll_arrows trei"></span>
                </div>
              </a>

            </Grid>


          </Grid>
          <Grid item container sm={1} md={1} />
        </Grid>
      </Grid>
      <section id="aide" ref={scrollToRef}>
        <Grid style={{ backgroundColor: "#e5e5e5", paddingLeft: '1.5vh' }} container sx={{ height: "115vh" }}>
          <Typography variant='h4' style={{ fontSize: "8vh", fontFamily: 'Kanit', color: "#303233", fontWeight: 'bold' }}>
            Instructions d'utilisation
          </Typography>
          <Help textColor="#85B2D9" />
        </Grid>
      </section>

      <Grid item container sx={{ height: "50vh", backgroundColor: "#e5e5e5" }} direction='column'>
        <Grid item xs={11}>

        </Grid>
        <Grid xs={1} item container>
          <Grid xs={2} item container>
            <Typography style={{ marginLeft: "2vh", fontFamily: 'Kanit' }}>
              @2023 SYNEIKA
            </Typography>
          </Grid>
          <Grid xs={3} item container></Grid>
          <Grid xs={7} item container>
            <Typography style={{ fontFamily: 'Kanit' }}>
              Pour toute question ou demande d’information, veuillez nous contacter à: {" "}
              <a href="mailto:contact@syneika.com">contact@syneika.com</a>
              {" "}   ou  {" "}
              <a href="tel:+33222665590"> 02 22 66 55 90 </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sx={{ height: "2vh", backgroundColor: "#e5e5e5" }} direction='column'>
      </Grid>

    </div>

  );
}


const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Home);

