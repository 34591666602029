import * as React from 'react';
import {Grid, Typography, IconButton, Box} from '@mui/material';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { Add, ArrowBack} from '@mui/icons-material/';
import ListComponent from '../Components/ListComponent';
import {setExpert} from '../redux/expert/expert-action';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';


function ExpertsAdmin({hideLoader, setExpert, user}) {
  const [experts, setExperts] = React.useState([]);
  const navigate = useNavigate()

    React.useEffect(() => {
      const {access, is_staff} = user || {};
      !access&&!is_staff?navigate("/admin/login"):void(0);

      //pour initialiser le style de scroll bar
      $('.scroll').mCustomScrollbar({
        scrollButtons:{enable:true},
        keyboard:{scrollType:"stepless"},
        mouseWheel:{scrollAmount:150},
        theme:"rounded-dark",
        autoExpandScrollbar:true,
      });

      //récuperer la liste de tous les experts
      axios.get(process.env.REACT_APP_API +'/api/experts/',
      {
        headers: {
          Authorization: `Bearer ${access}`
        }
      },
       )
      .then((response) => {
        hideLoader();
        console.log(response.data);
        const sortedExperts = response.data.sort((a,b) => a.user.first_name.localeCompare(b.user.first_name));
        console.log(response.data);
        setExperts(sortedExperts);
  
      },  (error) => {
        console.log(error );
       // navigate("/");
      })
      hideLoader();
      }, [hideLoader]);



      //Supprimer un expert de la base de données
      const deleteDB = (username) => {
        const {access} = user || {};
        axios.post(process.env.REACT_APP_API +'/api/delete-expert/',
        {username: username},
        {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        },
       )
      .then((response) => {
        setExperts(experts.filter(expert => expert.user.username !== username));
  
      },  (error) => {
        console.log(error );
       // navigate("/");
      })
      }

  return (
      
    <div style={{backgroundColor: "#0e2852", height:"100vh" }}>
       <Navbar isConnected />
      <Grid container xs={12} style={{marginTop:"10%"}}>
         <Grid item xs={1.25} />
          <Grid item xs={2} >
        <IconButton sx={{ color: '#FFFF', marginLeft:"40%"}} onClick={() => {navigate('/admin')}}>
        <ArrowBack fontSize="large"/>
        </IconButton>
        </Grid>   
        <Grid container item xs={5}>
        <ListComponent height="45vh" onDelete={(value)=>{deleteDB(value)}}  search={true} elements={experts} 
    onSelection={(value) => {setExpert(value); navigate("/admin/update-expert")}} >
        <Box sx={{ width:'50vh',display: 'flex',  marginLeft:'auto', marginRight:'auto' }}>
           <IconButton sx={{marginRight:'20px', color:'#0e2852'}} onClick={() => navigate('/admin/new-expert')}>
           <Typography style={{ textAlign:'center',fonFamily: 'Zen Antique Soft',
             fontSize: '18px' }}>Nouvel expert</Typography>
            <Add/>
           </IconButton>
        </Box>
    </ListComponent>
        </Grid>
        
      <Grid item xs={1}></Grid>
      </Grid>
      </div>
     
  );
}

  const mapDispatchToProps = dispatch => ({
    setExpert:  (target) => dispatch(setExpert(target)),

  });
  const mapStateToProps = state => ({
    user: state.user,
  });
 export default connect(mapStateToProps, mapDispatchToProps)(ExpertsAdmin);
