
import * as React from 'react';
import {
  Typography, ListItemButton,
  ListItemText, List, Box, Grid,
  Select, MenuItem, Button, OutlinedInput
} from '@mui/material/';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { connect } from 'react-redux';
import { setStudy } from '../redux/study/study-action';
import { setUser } from '../redux/user/user-action';
import { setTargets } from '../redux/targets/targets-action';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Axios from 'axios';
import '../App.css'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { setPatient } from '../redux/patient/patient-action';
import './style.css'
import { getCookie } from '../Utils';
require('jquery-mousewheel');


const { forwardRef, useImperativeHandle } = React;
const ListViewer = forwardRef((props, ref) => {
  const [studies, setStudies] = React.useState([])
  const scrollableListRef = React.createRef()
  const [studySelected, setStudySelected] = React.useState(props.study ? props.study.studySelected : "");
  const context = React.useRef(null)
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 400,
    },
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const patientlistRef = React.useRef(null);
  const patientIsClicked = React.useRef(null)

  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    async increment() {
      const targetIndex = props.patient.targetIndex;
      const patientIndex = props.patient.currentPatientIndex;
      if (props.targets) {
        if (targetIndex < props.targets.length - 1) {
          let i = targetIndex;
          ++i;
          const target = props.targets[i];
          selectLocation(target, i)
          context.current.target = target;
        }
        else {
          if (patientIndex < props.patients.length - 1) {
            let i = patientIndex;
            i++;
            const patient = props.patients[i];
            const firstTarget = props.targets[0];
            selectLocation(firstTarget, i)
            if (props.targets[targetIndex].status === 'IN PROGRESS') {
              patientIsClicked.current = patient;
              setOpen(true);
            }
            else {
              props.onPatientSelect(patient, props.study.uid)
            }


            context.current.patient = props.patients[i].identifiant;
            context.current.target = firstTarget;



          }
        }
      }
    },
    async decrement() {
      if (props.targets) {

        const targetIndex = props.patient.targetIndex;
        const patientIndex = props.patient.currentPatientIndex;

        if (targetIndex > 0) {

          let i = targetIndex;
          i--;
          const target = props.targets[i]
          selectLocation(target, i)
          context.current.target = props.targets[i];
        }
        else {
          if (patientIndex > 0) {
            const targetIndex = props.patient.targetIndex;
            let i = patientIndex;
            i--;
            const firstTarget = props.targets[0];
            const patient = props.patients[i];

            if (props.targets[targetIndex].status === 'IN PROGRESS') {
              patientIsClicked.current = patient;
              setOpen(true);
            }
            else {
              await props.onPatientSelect(patient, props.study.uid);
              props.onLocationSelect(firstTarget);

            }
            //props.setPatient({...props.study, targetIndex:0});
            context.current.patient = props.patients[i].identifiant;
            context.current.target = firstTarget;
          }
        }
      }
    }

  }));


  React.useEffect(() => {
    //setLocationIndex(props.currentPatientIndex);
    //setPatientIndex(props.targetIndex);

    const { access } = props.user || {}
    const { name } = props.study;
    !name ? navigate('/studies') : void (0)

    $('.scroll_patients').mCustomScrollbar({
      scrollButtons: { enable: true },
      keyboard: { scrollType: "stepless" },
      theme: "rounded-dark",
      autoExpandScrollbar: true,
    });
    $('.scroll_targets').mCustomScrollbar({
      scrollButtons: { enable: true },
      keyboard: { scrollType: "stepless" },
      mouseWheel: { scrollAmount: 0 },
      theme: "rounded-dark",
      autoExpandScrollbar: true,
    });
    context.current = { i: props.study.currentTargetIndex }
    async function fetchData() {
      //await Axios.post('https://'+window.location.hostname+'/api/studies/',{
      await Axios.post(process.env.REACT_APP_API + '/api/studies/', {
        username: props.user.username
      }, {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie('csrftoken')
        }
      })
        .then(async (response) => {
          const filteredStudies = response.data.map((study) => ({ ...studies, ...study.studyId }));
          setStudies(filteredStudies);
        });


    }

    fetchData()
    // const currentIndex = props.patient?props.patient.currentPatientIndex:0

    //placeSelectedItemInTheMiddle(currentIndex+1);



    return () => {
      if (context.current !== null) {
        context.current = null;
      }
    };

  }, [props.study]);



  const selectLocation = (target, i) => {
    const targetIndex = props.patient.targetIndex;
    const patientIndex = props.patient.currentPatientIndex;
    if (targetIndex !== i && props.targets[targetIndex].status === 'IN PROGRESS') {
      context.current.i = i;

      setOpen(true);
      context.current.nextTarget = target;
    }
    else {
      if (i < props.targets.length) {
        props.setPatient({ ...props.patient, targetIndex: i, currentPatientIndex: patientIndex });
        props.onLocationSelect(target);
      }

    }

  }


  return (
    <Grid id="listviewer" style={{ height: "100vh" }} item container>
      <Grid sx={{ width: '99%', bgcolor: 'background.paper', borderRadius: '4%', paddingTop: '10px' }}>
        <Grid item xs={12} style={{ marginBottom: "2vh" }}>
          <h2 className='listTitles'><img alt="icon" src={process.env.REACT_APP_STATIC + 'images/study2.png'} style={{ width: "3vh", position: "relative", top: "0.6vh" }} /> Étude:
            <Select
              style={{ width: "90%", height: "3vh", marginLeft: "1vh" }}
              input={<OutlinedInput sx={{ fontSize: '1.6vh', fontFamily: "Kanit" }} label="Tag" />}
              value={studySelected}
              label="studies"
              size="medium"
              onChange={async (event) => {
                const i = event.target.value;
                const _study = studies[i];
                setStudySelected(i);
                props.setStudy({ ..._study, studySelected: i });
                await props.onStudySelect(_study.uid, _study._type, _study.listSeq);
              }}
            >
              {studies ? studies.sort((a, b) => a.uid - b.uid).map((study, i) =>
                <MenuItem style={{ fontSize: "15px" }} key={i} value={i}><Typography
                  sx={{ fontSize: "1.6vh", fontFamily: "Kanit" }} >
                  {study.name + " (" + study._type + ")"}
                </Typography></MenuItem>
              ) : null}

            </Select>
          </h2>
        </Grid>
        <h2 className='listTitles' style={{ marginBottom: "1vh" }}> <img alt="icon" src={process.env.REACT_APP_STATIC + 'images/patient2.png'} style={{ width: "3vh", position: "relative", top: "0.6vh" }} /> Patients ({props.patient ? props.patient.currentPatientIndex + 1 : 0}/{props.patients ? props.patients.length : 0})</h2>
        <Box sx={{ border: 1 }} style={{
          overflowY: "hidden",
          height: "35vh", borderColor: "black"
        }} className='scroll_patients'>
          <List component="nav" ref={scrollableListRef} >
            {props.patients ? (props.patients.map((patient, i) => (
              <div key={i} ref={patientlistRef} id={"patient" + i} >
                <ListItemButton
                  disabled={props.disable}
                  selected={props.patient ? props.patient.currentPatientIndex === i : 0}
                  onClick={async (event) => {
                    const targetIndex = props.patient.targetIndex;

                    if (props.targets[targetIndex].status === 'IN PROGRESS') {
                      patientIsClicked.current = patient;
                      setOpen(true);
                    }
                    else {
                      await props.onPatientSelect(patient, props.study.uid);
                    }

                  }}>
                  <ListItemText style={{
                    color: props.study._type === "Evaluation" ?
                      patient ? patient.note === "DONE" ? "blue" : "black" : null : patient.status === 'DONE' ? "green" : "red"
                  }} primary={<Typography className='listPatients' style={{
                    fonFamily: 'Zen Antique Soft', fontStyle: "normal",
                    fontWeight: 800, lineHeight: '1'
                  }}> {patient.index+2 } </Typography>
                  } />
                </ListItemButton>
              </div>
            ))) : null}
          </List>
        </Box>
      </Grid>
      <Grid sx={{ width: '99%', bgcolor: 'background.paper', borderRadius: '4%' }}>
        <h2 className='listTitles' style={{ marginBottom: "1vh" }}><img alt="icon" src={process.env.REACT_APP_STATIC + 'images/target.png'} style={{ width: "3vh", position: "relative", top: "0.6vh" }} /> Cibles ({props.targets ? (props.patient ? props.patient.targetIndex + 1 : 0) + "/" + (props.targets ? props.targets.length : 0) : null})</h2>

        <Box sx={{ border: 1 }} style={{
          overflowY: "hidden",
          height: isSmallScreen ? "35vh" : isLargeScreen ? "40vh" : "45vh"
        }} className='scroll_targets' >
          <List component="nav" >
            {props.targets ? (props.targets.map((target, i) => (
              <div key={i} id={"target" + i}
              >
                <CustomWidthTooltip title={<Typography>{target ? target.name + ": " + target.description : ""}</Typography>}>

                  <ListItemButton
                    disabled={props.disable}
                    selected={props.patient ? props.patient.targetIndex === i : 0}
                    onClick={() => selectLocation(target, i)}>
                    <ListItemText style={{
                      color:
                        props.study._type === "Evaluation" ?
                          target ? target.note  ? "blue" : "black" : null
                          :
                          target ? target.status === 'undone' ? "red" :
                            target.status === 'DONE' ? "green" : "orange" : null
                    }} primary={<Typography className='listCibles' style={{
                      fonFamily: 'Zen Antique Soft',
                      fontWeight: 800, lineHeight: '0.85'
                    }}> {target ? target.name.length < 10 ? target.name : target.name.substring(0, 10) + " ..." : null}<br /><span className='list_cbile_description' style={{ letterSpacing: "0.1px" }}> {target ? target.description.length < 25 ? target.description : target.description.substring(0, 25) + " ..." : null}</span> </Typography>} />
                  </ListItemButton>
                </CustomWidthTooltip>
              </div>
            ))) : null}
          </List>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => { }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle>
              <Typography style={{ fontSize: '2.5vh', fontFamily: 'Kanit' }}>
                Le pointage de la cible actuelle va être perdu!
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography style={{ fontSize: '2vh', fontFamily: 'Kanit' }}>
                  Vous avez oublier d'enregistrer votre pointage, êtes-vous sûr de vouloir passer à la prochaine cible sans enregistrer la cible actuelle?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => { setOpen(false) }}>
                <Typography style={{ fontSize: '2vh', fontFamily: 'Kanit' }}>
                  Non
                </Typography>
              </Button>
              <Button onClick={async () => {
                if (patientIsClicked.current) {
                  const patient = patientIsClicked.current
                  await props.onPatientSelect(patient, props.study.uid);
                  patientIsClicked.current = null;
                  setOpen(false)
                }
                else {
                  const patientIndex = props.patient.currentPatientIndex
                  props.setPatient({ ...props.study, targetIndex: context.current.i, currentPatientIndex: patientIndex });
                  context.current.target = context.current.nextTarget;
                  await props.onLocationSelect(context.current.target);
                  setOpen(false)
                }

              }} autoFocus>
                <Typography style={{ fontSize: '2vh', fontFamily: 'Kanit' }}>
                  Oui
                </Typography>

              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );

});

const mapStateToProps = state => ({
  expert: state.expert.currentExpert,
  user: state.user,
  study: state.study.currentStudy,
  localizations: state.localizations.currentLoc,
  targets: state.targets.currentTargets,
  patients: state.patients.patients,
  patient: state.patient.currentPatient

});

const mapDispatchToProps = dispatch => ({
  setStudy: (study) => dispatch(setStudy(study)),
  setUser: (user) => dispatch(setUser(user)),
  setTargets: (targets) => dispatch((setTargets(targets))),
  setPatient: (patient) => dispatch((setPatient(patient)))

});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListViewer);



