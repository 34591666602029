import expertTypes from "./expert-types";
import setExpert from "./expert-utils";

const INTIAL_STATE = {
    currentExpert:null,
};

const expertReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case expertTypes.SET_EXPERT:
            return{
                ...state,
                currentExpert: action.payload
            };
        case expertTypes.SET_VALUES:
                return{
                    ...state,
                    currentExpert: setExpert(state.currentExpert, action.payload)
                }
        case expertTypes.CLEAR_EXPERT:
            return{
                currentExpert: null
            };
        default:
            return state;
    }
};

export default expertReducer;