import userTypes from './user-types';

export const setUser = (user) => ({
    type: userTypes.SET_USER,
    payload: user,
});

export const clearUser = () => ({
    type: userTypes.CLEAR_USER,
});

export const chargeUser = (user) => ({
    type: userTypes.CHARGE_USER,
    payload: user,
});

