import * as React from 'react';
import {connect} from 'react-redux';
import {Grid, Typography, Box, IconButton, Alert, TextField, Button, Select} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import { ArrowBack} from '@mui/icons-material/';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';







function UpdatePatient({user, hideLoader, patient}) {

    const [errorMessage, setErrorMessage] = React.useState("");
    const defaultIRM = {folder: "/Patients/", file: "/MR-T1.vti", mask: "/mask.vti"}
    const [studies, setStudies] = React.useState([]);
    const [studyName, setStudyName] = React.useState([]);
    const [finalstudies, setFinalstudies] =  React.useState([]);
    const navigate = useNavigate();
    const [newpatient, setnewPatient] = React.useState({
      defaultIRM: defaultIRM,
      ...patient
    });




    const handleChangeMultiple = (event) => {
      
      const { options } = event.target;
      const value = [];

      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);

        }
      }
      
      setStudyName(value);
     // setnewPatient({...newpatient, studies: value})
  
    };

    const validation = () => {
      if (newpatient.identifiant ) {
        return true;
      }
      else {
        setErrorMessage('Veuillez renseigner toutes les informations nécessaires!')
        return false;
      }
    }
    /*function isNumeric(value) {
      return /^\d+$/.test(value);
  }*/
  
      React.useEffect( () => {
        const {access, is_staff} = user || {};
        !access&&!is_staff?navigate("/admin/login"):void(0);
    
        $('.scroll').mCustomScrollbar({
          scrollButtons:{enable:true},
          keyboard:{scrollType:"stepless"},
          mouseWheel:{scrollAmount:150},
          theme:"rounded-dark",
          autoExpandScrollbar:true,
        });

        async function fetchData() {
         
        await axios.get(process.env.REACT_APP_API +"/api/all-studies/",{
          headers: {
            Authorization: `Bearer ${access}`
          }
        },).then(
              (response) => {
                  setStudies(response.data)
              }
          ).catch((error)=>{console.log(error);})

          await axios.post(process.env.REACT_APP_API +"/api/patient-studies/",
          {patient: patient.uid}
,
          {
            headers: {
              Authorization: `Bearer ${access}`,
              'X-CSRFToken': getCookie( 'csrftoken')
            },
          },
          ).then(
                (response) => {
                    const studies_selected = response.data.map((study) => study.name+"")
                    setStudyName(studies_selected)
                    setFinalstudies(studies_selected);

                }
            ).catch((error)=>{console.log(error);})
        }
        fetchData()
        hideLoader();
        }, []);
        const delay = ms => new Promise(res => setTimeout(res, ms));

        
      //.....................................................................................................const delay = ms => new Promise(res => setTimeout(res, ms));

      const submitHandle = async () =>{
        if(validation())
        {//setSelectedPatient(0);
          var added = []
          var removed = []
          finalstudies.forEach(preObj => {
            const found =  studyName.find(newObj => newObj === preObj);
            if(!found) {
                //removeCount++;
                removed.push(preObj);
                console.log(`Removed`, preObj);
            }
        });  
  
        //chuf wach dakchi li drty lih selection, wach wach déja kan fdakchi li mselectioni
        studyName.forEach(newObj => {
          const found = finalstudies.find(prevObj => prevObj === newObj);
          if(!found) {
              added.push(newObj)
              console.log(`added`, newObj);
          }
        });
        setFinalstudies(studyName)
        const patientDB = {...newpatient, added: added, removed: removed}
        const {access,} = user || {};
        console.log(patientDB);
       await axios.post(process.env.REACT_APP_API +'/api/update-patient/', patientDB,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            'X-CSRFToken': getCookie( 'csrftoken')
          }
        },).then(
              (response) => {
               

               // setSuccessMessage("Expert modifié avec succes!")
                setErrorMessage('')
              }
          ).catch((error)=>{
            setErrorMessage("Un expert avec le même username existe déja");
          })
          await delay(3000);
         //   setSuccessMessage("")
        }

  
      }

  
      const handleChange = (event) => {
          const {name, value} = event.target;
          setnewPatient({...newpatient, [name]: value});
      }


    return (
      <div style={{backgroundColor: "#0e2852", height:"100vh" }}>
      <Navbar isConnected/>
     <Grid container xs={12} style={{marginTop:"10%"}}> 
       <Grid item xs={1.25}/>
         <Grid item xs={2} style={{marginTop:"20px", paddingRight:"20px",  paddingLeft:"20px"}}>
        <Box style={{marginLeft:"40%"}}>
        <IconButton sx={{ color: '#FFFF'}} onClick={()=>navigate("/admin/patients")}  >
        <ArrowBack fontSize="large"/>
        </IconButton>
        </Box>
        </Grid>
        <Grid item xs={5}>
        <Box style={{overflowY: "scroll",/*"hidden",*/ backgroundColor:"white", borderRadius:"15px",
     paddingTop:"50px", paddingLeft:"55px", paddingBottom:"50px", maxHeight:"400px"}} /*className='scroll'*/ >


      
        
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Identifiant:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField disabled={!false} size='small'  style={{width:'35vh'}} value={newpatient.identifiant}
          name="identifiant" onChange={(e) => {handleChange(e)} }/>
          </Grid>
        </Grid>
        
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Chemin IRM:</Typography>
          </Grid>
          <Grid item xs={8}>
          <TextField  size='small'  style={{width:'35vh'}} value={newpatient.irm !== "" ? newpatient.irm||"" : ""}
          name="irm" onChange={(e) => {handleChange(e)} }/>
          </Grid>
        </Grid>
        <Grid className="formLine" item container xs={12} >
          <Grid item xs={4}>
           <Typography className="formText">Études:</Typography>
          </Grid>
          <Grid item xs={8} style={{
            paddingRight:"15px"
          }}>
        <Select
          multiple
          native
          value={studyName}
          style={{width:'35vh'}}
          // @ts-ignore Typings are not considering `native`
          onChange={handleChangeMultiple}
          label="Native"
          inputProps={{
            id: 'select-multiple-native',
          }}
        >
          {studies?studies.map((study) => (
            <option key={study.name} value={study.name}>
              {study.name}
            </option>
          )) : null}
        </Select>
          </Grid>
        </Grid>
        { errorMessage ?
          (<Alert severity="error" className="alert">{errorMessage}</Alert>) : null}
        { false ?
          (<Alert severity="success" className="alert">{false}</Alert>) : null} 
        <Button style={{float: "right", marginRight:"15px"}} variant="contained" onClick={()=>{submitHandle()}}>Modifier</Button>
    </Box>
        </Grid>
        
      <Grid item xs={1}></Grid>
      </Grid>
      </div>
                 
            )
    
}


const mapStateToProps = state => ({
  user: state.user,
  patient: state.patient.currentPatient
});


export default connect(mapStateToProps, null)(UpdatePatient);