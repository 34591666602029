import React from 'react';
import HelpOutline from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import {Grid, Box, Typography, Button, IconButton} from '@mui/material/';
import LogoutIcon from '@mui/icons-material/Logout';
import {connect} from 'react-redux';



const Background = ({user, color, logOut, children}) => {
    const {first_name, last_name} = user || {};
    const navigate = useNavigate();

    
    return (
        <div style={{backgroundColor: color || "#1583EA", height: "100vh"}}>
            <Grid container>
             <Grid item container>
                <Grid item xs={2}>
                    <Box>
                        <img  alt="logo" src={process.env.REACT_APP_STATIC+"logo.png"} style={{width:'180px'}}/>
                    </Box>
                </Grid>
                <Grid item xs={8.5}/>
                <Grid item container xs={1.5}>
                {(user ? (
                    <>
                    <Grid item xs={8.5}>
                    <Typography sx={{fontSize:'20px', color:'white', marginTop:"15px"}}>
                    {first_name + ' ' + last_name}</Typography>
                    </Grid>
                    {logOut ? (<Grid item xs={12}> <Button onClick={logOut} startIcon={<LogoutIcon/>} sx={{ color:'white'}}>
                    <Typography sx={{fontSize:'17px', color:'white', textTransform: 'none'}}>
                        Se déconnecter
                        </Typography>
                    </Button></Grid>)
                    : null}
                    </>):null)}
                </Grid>

             </Grid>
             <Grid item container xs={12}>
                
                {children}

             </Grid>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                <Box sx={{bottom:'0px', position: 'fixed', marginLeft:'20px', marginBottom:'20px'}}>
                    <IconButton>
                    <HomeIcon fontSize='large' sx={{color:'#FFF'}} onClick={()=>navigate("/admin")}/>
                    </IconButton>
                </Box>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                <Box sx={{bottom:'0px',right:'0px', position: 'fixed', marginRight:'20px', marginBottom:'20px'}}>
                <HelpOutline fontSize="large" sx={{color:"#FFF"}}/>
                 </Box>  
                </Grid>
             </Grid>
        </Grid>
        </div>
    );
};
const mapStateToProps = state => ({userSelected: state.user})
export default connect(mapStateToProps)(Background);
