import studyTypes from './study-types';

export const setStudy = (study) => ({
    type: studyTypes.SET_STUDY,
    payload: study,
});

export const modifyStudy = study => ({
    type: studyTypes.SET_VALUES,
    payload: study
});

export const clearStudy = () => ({
    type: studyTypes.CLEAR_STUDY,
});

