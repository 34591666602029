import * as React from 'react';
import { Grid, Typography, IconButton, Box, Button } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PersonIcon from '@mui/icons-material/Person';
import Navbar from '../Components/Navbar';

function AdminDashboard({ hideLoader, showLoader, user }) {
  const navigate = useNavigate();
  React.useEffect(() => {
    const { access, is_staff } = user;
    !access ? navigate('/admin/login') : void (0);
    !is_staff ? navigate('/admin/login') : void (0)
    hideLoader();
  }, [hideLoader, showLoader]);





  return (

    <div style={{ backgroundColor: "#0e2852", height: "100vh" }}>
      <Navbar isConnected />
      <Grid style={{ paddingTop: '75px', paddingLeft: "20%" }} item container xs={10}>
        <Grid item xs={4}>
          <IconButton onClick={() => { navigate("/admin/experts") }} sx={{ color: '#FFFF' }} >
            <WorkIcon fontSize="large" />
            <Typography sx={{ marginLeft: '15px', color: '#FFFF' }}>Gestion des experts</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <IconButton onClick={() => { navigate("/admin/studies") }} sx={{ color: '#FFFF' }} >
            <LibraryBooksIcon fontSize="large" />
            <Typography sx={{ marginLeft: '15px', color: '#FFFF' }}>Gestion des études</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <IconButton onClick={() => { navigate("/admin/patients") }} sx={{ color: '#FFFF' }} >
            <PersonIcon fontSize="large" />
            <Typography sx={{ marginLeft: '15px', color: '#FFFF' }}>Gestion des patients</Typography>
          </IconButton>
        </Grid>
        <Grid item style={{ marginTop: "60px" }} xs={4}>
          <IconButton onClick={() => { navigate("/admin/export-studies") }} sx={{ color: '#FFFF' }} >
            <PersonIcon fontSize="large" />
            <Typography sx={{ marginLeft: '15px', color: '#FFFF' }}>Export des études</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={1}/>
    </div>

  );
}
const mapStateToProps = state => ({
  user: state.user
});


export default connect(mapStateToProps, null)(AdminDashboard);
