import locTypes from "./localizations-types";
import setLoc from "./localizations-utils";

const INTIAL_STATE = {
    currentLoc:null,
};

const locReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case locTypes.SET_LOC:
            return{
                ...state,
                currentLoc: action.payload
            };
        case locTypes.SET_VALUES:
                return{
                    ...state,
                    currentLoc: setLoc(state.currentLoc, action.payload)
                }
        case locTypes.CLEAR_LOC:
            return{
                currentLoc: null
            };
        default:
            return state;
    }
};

export default locReducer;