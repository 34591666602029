import * as React from 'react';
import { Grid, Typography, IconButton, Box, TextField, Button, Alert } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowBack } from '@mui/icons-material/';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css';
import Navbar from '../Components/Navbar';
import { getCookie } from '../Utils';


function UpdateExpert({ expert, user }) {
  const navigate = useNavigate()
  const [newexpert, setnewExpert] = React.useState({ ...expert.user, ...expert });
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");


  const delay = ms => new Promise(res => setTimeout(res, ms));

  //Mise à jour de l'expert dans la base de données
  const submitHandle = async () => {

    var added = []
    var removed = []


    const expertDB = { ...newexpert, added: added, removed: removed }
    const { access } = user || {};
    await axios.post(process.env.REACT_APP_API + '/api/update-expert/', expertDB,
      {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie('csrftoken')
        }
      },).then(
        (response) => {
          setSuccessMessage("Expert modifié avec succes!")
          setErrorMessage('')
        }
      ).catch((error) => {
        setErrorMessage("Un expert avec le même username existe déja");
      })
    await delay(3000);
    setSuccessMessage("")



  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setnewExpert({ ...newexpert, [name]: value });
  }

  return (

    <div style={{ backgroundColor: "#0e2852", height: "100vh" }}>
      <Navbar isConnected />
      <Grid container xs={12} style={{ marginTop: "5%" }}>
        <Grid item xs={1.25} />
        <Grid item xs={2} style={{ paddingRight: "20px", paddingLeft: "20px" }}>
          <Box style={{ marginLeft: "40%" }}>
            <IconButton sx={{ color: '#FFFF' }} onClick={() => navigate("/admin/experts")}  >
              <ArrowBack fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box style={{
            overflowY: "hidden", backgroundColor: "white", borderRadius: "15px",
            paddingTop: "8%", paddingLeft: "10vh", paddingBottom: "15px"
          }} className='scroll'>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Prénom:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.first_name || ''}
                  name="first_name" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Nom:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.last_name || ''}
                  name="last_name" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">ID*:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert._id || ''}
                  name="_id" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} >
              <Grid item xs={4}>
                <Typography className="formText">Organization:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.organization || ''}
                  name="organization" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={4}>
                <Typography className="formText">Email:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField size='small' style={{ width: '35vh' }} value={newexpert.email || ''}
                  name="email" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }} >
              <Grid item xs={4}>
                <Typography className="formText">Mot d'utilisateur:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField disabled size='small' style={{ width: '35vh' }} value={newexpert.username || ''}
                  name="username" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>
            <Grid className="formLine" item container xs={12} style={{ marginBottom: "25px" }} >
              <Grid item xs={4}>
                <Typography className="formText">Mot de passe:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField type='password' size='small' style={{ width: '35vh' }} value={'**********'}
                  name="password" onChange={(e) => { handleChange(e) }} />
              </Grid>
            </Grid>

            {errorMessage ?
              (<Alert severity="error" className="alert">{errorMessage}</Alert>) : null}
            {successMessage ?
              (<Alert severity="success" className="alert">{successMessage}</Alert>) : null}
            <Button style={{ float: "right", marginRight: "35px" }} variant="contained" onClick={() => { submitHandle() }}>Modifier</Button>
          </Box>
        </Grid>

        <Grid item xs={1}/>
      </Grid>
    </div>

  );
}
const mapStateToProps = state => ({
  user: state.user,
  expert: state.expert.currentExpert
});
/* const mapDispatchToProps = dispatch => ({
   
 });*/
export default connect(mapStateToProps, null)(UpdateExpert);
