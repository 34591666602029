import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux'
import {store, persistor} from './redux/store'
import {PersistGate} from 'redux-persist/integration/react';
import Patients from './admin/Patients';
import VolumeViewer from './expert/VolumeViewer';
import LoginExpert from './expert/LoginExpert';
import Studies from './expert/Studies';
import AdminDashboard from './admin/AdminDashboard';
import ExpertsAdmin from './admin/ExpertsAdmin';
import NewExpert from './admin/NewExpert';
import UpdateExpert from './admin/UpdateExpert';
import NewStudy from './admin/NewStudy';
import UpdatePatient from './admin/UpdatePatient';
import StudiesAdmin from './admin/StudiesAdmin';
import UpdateStudy from './admin/UpdateStudy';
import LoginAdmin from './admin/LoginAdmin';
import ExportStudies from './admin/ExportStudies';
import Home from './expert/Home';
import NewPatient from './admin/NewPatient';

const loader = document.querySelector('.loader-page');
const showLoader = () => loader.classList.remove('loader--hide');
const hideLoader = () => loader.classList.add('loader--hide');
showLoader()


ReactDOM.render(
  <Provider store={store}>
  <PersistGate persistor={persistor}>
  <Router>
  <Routes>
  <Route exact path="/"  element={<Home 
  hideLoader={hideLoader}
  showLoader={showLoader} />}/>
  <Route path="/admin/new-patient/" element={<NewPatient
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>

 <Route path="/viewer/" element={<VolumeViewer
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
  <Route exact path="/expert/login" element={<LoginExpert
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 <Route path="/studies" element={<Studies
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 <Route exact path="/admin/" element={<AdminDashboard
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
  <Route path="/admin/experts/" element={<ExpertsAdmin
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
   <Route path="/admin/new-expert/" element={<NewExpert
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 <Route path="/admin/update-expert/" element={<UpdateExpert
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 <Route path="/admin/new-study/" element={<NewStudy
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>

  <Route exact path="/admin/patients" element={<Patients
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 <Route exact path="/admin/update-patient" element={<UpdatePatient
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 <Route exact path="/admin/studies" element={<StudiesAdmin
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
  <Route exact path="/admin/update-study" element={<UpdateStudy
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
   <Route exact path="/admin/login" element={<LoginAdmin
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
  <Route exact path="/admin/export-studies" element={<ExportStudies
  hideLoader={hideLoader}
  showLoader={showLoader}
 />}/>
 
  </Routes>
  </Router>
  </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
