import * as React from 'react';
import { Grid, Typography, Box, IconButton, Alert } from '@mui/material/';
import { Add, ArrowBack } from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';
import { setStudy } from '../redux/study/study-action';
import { connect } from 'react-redux';
import axios from 'axios';
import ListComponent from '../Components/ListComponent';
import { getCookie } from '../Utils';
import Navbar from '../Components/Navbar';





function StudiesAdmin({ setStudy, user, hideLoader, showLoader }) {

  const { access, is_staff } = user || {};
  const navigate = useNavigate();
  const [studies, setStudies] = React.useState([]);


  React.useEffect(() => {
    //Récuperer la liste des études da la base de données
    !access && !is_staff ? navigate("/admin") : void (0);
    axios.get(process.env.REACT_APP_API + '/api/all-studies/', {
      headers: {
        Authorization: `Bearer ${access}`
      }
    },)
      .then(response => {
        setStudies(response.data.sort((a, b) => a.name.localeCompare(b.name)));
        console.log(response.data);
        hideLoader();
      })
      .catch(error => {
        hideLoader();
        console.log(error);
      });

  }, [hideLoader, showLoader]);


// Supprimer l'étude de la base de données
  const deleteDB = (study) => {
    const { name } = study;
    axios.post(process.env.REACT_APP_API + '/api/delete-study/',
      { name: study.name },
      {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie('csrftoken')
        }
      },
    )
      .then((response) => {
        setStudies(studies.filter(study => study.name !== name));

      }, (error) => {
        console.log(error);
        // navigate("/");
      })
  }

  const resetStudy = (study) => {
    axios.post(process.env.REACT_APP_API + '/api/reset-evaluation-study/',
      {
        study_uid: study.uid,
        user_uid: user.uid
      },
      {
        headers: {
          Authorization: `Bearer ${access}`,
          'X-CSRFToken': getCookie('csrftoken')
        }
      },
    )
      .then((response) => {
        console.log(response);

      }, (error) => {
        console.log(error);
        // navigate("/");
      })
  }




  return (
    <div style={{ backgroundColor: "#0e2852", height: "100vh" }}>
      <Navbar isConnected />
      <Grid container style={{ marginTop: "10%" }}>
        <Grid item xs={1.25} />
        <Grid item xs={2} >
          <IconButton sx={{ color: '#FFFF', marginLeft: "40%" }} onClick={() => { navigate('/admin') }}>
            <ArrowBack fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={5}>
          {true ? (
            <ListComponent height="45vh" onDelete={(value) => { deleteDB(value) }} onReset={(value) => { resetStudy(value) }} search={true} elements={studies}
              onSelection={(value) => { setStudy(value); navigate("/admin/update-study") }} >
              <Box sx={{ width: '500px', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
                <IconButton sx={{ marginRight: '20px', color: '#0e2852' }} onClick={() => navigate('/admin/new-study')}>
                  <Typography style={{
                    textAlign: 'center', fonFamily: 'Zen Antique Soft',
                    fontSize: '18px'
                  }}>Nouvelle étude</Typography>
                  <Add />
                </IconButton>
              </Box>
            </ListComponent>) : <Alert severity="error" style={{
              fonFamily: 'Zen Antique Soft',
              fontSize: '12px', marginTop: '20px'
            }}>Vous n'avez pas les droits nécessaires pour afficher ces informations</Alert>}
        </Grid>
        <Grid item xs={2}>

        </Grid>
      </Grid>
    </div>

  );
}


const mapDispatchToProps = dispatch => ({
  setStudy: (target) => dispatch(setStudy(target)),

});



const mapStateToProps = state => ({
  user: state.user,
})
export default connect(mapStateToProps, mapDispatchToProps)(StudiesAdmin);

