import studiesTypes from "./studies-types";

const INTIAL_STATE = {
    currentStudies:[],
};

const studiesReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case studiesTypes.SET_STUDIES:
            return{
                ...state,
                currentStudies: action.payload
            };
        default:
            return state;
    }
};

export default studiesReducer;