import targetsTypes from './targets-types';

export const setTargets = (targets) => ({
    type: targetsTypes.SET_TARGETS,
    payload: targets,
});

export const modifyTargets = targets => ({
    type: targetsTypes.SET_VALUES,
    payload: targets
});

export const clearTargets = () => ({
    type: targetsTypes.CLEAR_TARGETS,
});

